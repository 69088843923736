import { ButtonBase, Collapse, SvgIcon, Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { mdiChevronDown } from '@mdi/js';
import clsx from 'clsx';
import React, { ReactElement, useMemo, useState } from 'react';
import {formatDistance} from "date-fns";

import P3 from '../../../../components/typography/p3';
import P5 from '../../../../components/typography/p5';
import { SYSTEM_COLORS } from '../../../../core/config/colors';
import useFormatServerOrders from '../../../../core/hooks/useFormatServerOrders';
import { Package, Product } from '../../../../core/stores/cart/action';
import { TeamStoreOrderFragment } from '../../../../generated/graphql';
import Detail from '../detail';

interface Props {
	filterOptions: string[];
	order: TeamStoreOrderFragment;
}

const OrderDetails = (props: Props): ReactElement | null => {
	const { order } = props;
	const { classes } = useStyles();
	const [expanded, setExpanded] = useState(false);

	const formattedOrders = useFormatServerOrders(order);

	const orderDetailList = useMemo(() => {
		if (!formattedOrders) {
			return [];
		}
		if (formattedOrders.length === 0) {
			return [];
		}

		if (props.filterOptions.length === 0) {
			return formattedOrders;
		}

		return formattedOrders.filter((orderDetail: Package | Product, i: number): boolean => {
			if (orderDetail.type === 'package') {
				return (
					orderDetail.products.filter((p) => {
						return props.filterOptions.includes(p.id);
					}).length > 0
				);
			} else if (orderDetail.type === 'product') {
				if (orderDetail._sortProductId) {
					return props.filterOptions.includes(orderDetail._sortProductId);
				}
			}
			return false;
		});
	}, [formattedOrders, props.filterOptions]);

	if (orderDetailList.length === 0) {
		return null;
	}

	return (
		<div className={classes.order}>
			<ButtonBase className={classes.expandHeader} onClick={(): void => setExpanded(!expanded)}>
				<div>
					<Typography variant="body2">{order.customer_name}</Typography>
					<Typography variant="h6" className={classes.time}>
						Updated {formatDistance(new Date(order.updated_at), new Date())}
					</Typography>
				</div>
				<div>
					<SvgIcon className={clsx(classes.chevron, expanded ? classes.chevronFlip : null)}>
						<path d={mdiChevronDown} />
					</SvgIcon>
				</div>
			</ButtonBase>
			<Collapse in={expanded} timeout="auto" unmountOnExit>
				<div className={classes.expandedContainer}>
					{orderDetailList.map((orderDetail: Product | Package, i: number): ReactElement => {
						return (
							<div key={orderDetail.id} className={classes.itemContainer}>
								{orderDetail.image && (
									<img src={orderDetail.image} className={classes.detailImage} alt={orderDetail.label} />
								)}
								<div>
									<P5>
										<strong>{orderDetail.label}</strong>
									</P5>
									{orderDetail.order_for && orderDetail.order_for !== '' && (
										<P3>
											Ordered For: <strong>{orderDetail.order_for}</strong>
										</P3>
									)}
									<P3 component="div">
										<Detail orderItem={orderDetail} />
									</P3>
								</div>
							</div>
						);
					})}
				</div>
			</Collapse>
		</div>
	);
};

const useStyles = makeStyles()((theme: Theme) => {
	return {
		time: {
			marginTop: 5,
			color: SYSTEM_COLORS.GRAY_LIGHT
		},
		order: {
			marginBottom: 15,
			paddingBottom: 10,
			borderBottom: `1px solid ${SYSTEM_COLORS.DIVIDER}`,
			'&:last-of-type': {
				borderBottom: 'none'
			}
		},

		expandedContainer: {
			marginTop: 5,
			borderRadius: 8,
			padding: 15,
			border: `1px solid ${SYSTEM_COLORS.GRAY_LIGHT}`
		},
		detailImage: {
			width: 100,
			height: 100,
			marginRight: 40
		},
		itemContainer: {
			display: 'flex',
			alignItems: 'center',
			marginBottom: 20,
			'&:last-of-type': {
				marginBottom: 0
			}
		},
		expandHeader: {
			flex: 1,
			textAlign: 'inherit',
			width: '100%',
			justifyContent: 'space-between',
			alignItems: 'center'
		},
		chevron: {
			color: SYSTEM_COLORS.PRIMARY,
			transition: 'all .3s'
		},
		chevronFlip: {
			'-webkit-transform': 'rotate(180deg)',
			'-moz-transform': 'rotate(180deg)',
			'-ms-transform': 'rotate(180deg)',
			'-o-transform': 'rotate(180deg)',
			transform: 'rotate(180deg)'
		}
	};
});

export default OrderDetails;
