import { mdiChevronLeftCircle } from '@mdi/js';
import { SvgIcon, Theme } from '@mui/material';
import React, { ReactElement } from 'react';
import { makeStyles } from 'tss-react/mui';

interface Props {
	groupId: string;
	showcase?: any;
}

const ShowcaseMiniHeader = ({ groupId, showcase }: Props): ReactElement => {
	const { classes } = useStyles();

	// const { data } = useQuery(GetShowcasesByGroupIdDocument, {
	// 	variables: { storeId: groupId }
	// });

	// useEffect(() => {
	// 	console.log('data', data);
	// }, [data]);

	return (
		<div className={classes.container}>
			{window.history.state ? (
				<div className={classes.back} onClick={() => window.history.back()}>
					<SvgIcon>
						<path d={mdiChevronLeftCircle} />
					</SvgIcon>
				</div>
			) : null}
			{showcase.image_url ? (
				<div className={classes.logo}>
					<img
						width={'100%'}
						src={showcase.image_url}
						alt={showcase.label || showcase.teamStoreByStoreShowcasingId.name}
					/>
				</div>
			) : null}
			<div>
				<h4>{showcase.label || showcase.teamStoreByStoreShowcasingId.name}</h4>
			</div>
		</div>
	);
};

const useStyles = makeStyles()((theme: Theme) => {
	return {
		container: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			'& h4': {
				margin: 0
			}
		},
		back: {
			color: theme.palette.primary.dark,
			cursor: 'pointer',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			marginRight: 5
		},
		logo: {
			width: 23,
			height: 23,
			borderRadius: 16,
			overflow: 'hidden',
			marginRight: 7,
			cursor: 'pointer'
		}
	};
});

export default ShowcaseMiniHeader;
