import React, { ReactElement, ReactNode } from 'react';

import { Action } from '../../stores/common/action';
import { UIAction } from '../../stores/ui/action';
import { UIDefaultState, UIModel, UIModelReducer } from '../../stores/ui/reducer';

interface Props {
	children: ReactNode;
}

const StoreContext = React.createContext<UIModel>({ ...UIDefaultState });
const DispatchContext = React.createContext<React.Dispatch<Action<UIAction, any>>>(() => {});

export const UIProvider = (options: Props): ReactElement => {
	const [store, dispatch] = React.useReducer(UIModelReducer, {
		...UIDefaultState
	});

	return (
		<DispatchContext.Provider value={dispatch}>
			<StoreContext.Provider value={store}>{options.children}</StoreContext.Provider>
		</DispatchContext.Provider>
	);
};

export const useUIStore = (): UIModel => {
	return React.useContext(StoreContext);
};
export const useUIStoreDispatch = (): React.Dispatch<Action<UIAction, any>> => {
	return React.useContext(DispatchContext);
};

export default UIProvider;
