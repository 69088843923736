import { useQuery } from '@apollo/client';
import { Theme } from '@mui/material';
import React, { ReactElement, useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { GetShowcaseProductsByGroupIdDocument, Store_Product_Bool_Exp, Uuid_Comparison_Exp } from '../../../generated/graphql';
import ProductList from '../../store/components/productList';
import SearchBox, { SearchSet } from './searchBox';

interface Props {
	groupId: string;
	showcase?: any;
	index?: string;
}

const GroupProducts = ({ groupId, showcase, index }: Props): ReactElement => {
	const { classes } = useStyles();
	const [search, setSeach] = useState<SearchSet | undefined>(undefined);
	const [products, setProducts] = useState<any[]>([]);
	const [where, setWhere] = useState<Store_Product_Bool_Exp>({ _and: [] });
	const [showcaseWhere, setShowcaseWhere] = useState<Uuid_Comparison_Exp>({});

	const { data } = useQuery(GetShowcaseProductsByGroupIdDocument, {
		skip: where._and?.length === 0 && showcaseWhere._eq === undefined,
		variables: {
			storeId: groupId,
			where: where,
			showcaseWhere: showcaseWhere
		}
	});

	useEffect(() => {
		if (showcase) {
			setShowcaseWhere({ _eq: showcase.id });
			if (index) {
				setSeach(showcase.search_sets[parseInt(index)]);
			}
		} else {
			setShowcaseWhere({});
		}
	}, [showcase, index]);

	useEffect(() => {
		const listOfItems = data?.showcase.map((sitem) => {
			return sitem.teamStoreByStoreShowcasingId.store_products || [];
		});
		setProducts((listOfItems || [])?.flat());
	}, [data]);

	useEffect(() => {
		if (search) {
			const t = search.terms.map((t) => ({ label: { _ilike: `%${t}%` } }));
			if (search.filterType === 'and') {
				setWhere({ _and: t });
			} else {
				setWhere({ _or: t });
			}
		} else {
			setWhere({ _and: [] });
		}
	}, [search]);

	const handleSearchChange = (searchSet: SearchSet) => {
		setSeach(searchSet);
	};

	return (
		<div>
			<div className={classes.searchBox}>
				<SearchBox onChange={handleSearchChange} searchSet={search} />
			</div>
			<div className={classes.productList}>
				<ProductList products={products} storeId={groupId} />
			</div>
		</div>
	);
};

const useStyles = makeStyles()((theme: Theme) => {
	return {
		searchBox: {
			paddingTop: 10,
			paddingBottom: 20,
			[theme.breakpoints.down('sm')]: {
				display: 'none'
			}
		},
		productList: {
			display: 'grid',
			gridTemplateColumns: 'repeat(3, 3fr)',
			gap: '25px',
			[theme.breakpoints.down('md')]: {
				gridTemplateColumns: 'repeat(2, 2fr)',
				gap: '20px'
			},
			[theme.breakpoints.down('sm')]: {
				gridTemplateColumns: 'repeat(1, 1fr)',
				gap: '15px'
			}
		}
	};
});

export default GroupProducts;
