import { SYSTEM_COLORS } from './colors';
import { createTheme } from "@mui/material";

const BASE_FONT_SIZE = 16;
export const getRemFromPx = (fontSizePx: number): string => `${(fontSizePx / BASE_FONT_SIZE).toFixed(4)}rem`;

const theme = createTheme({
	palette: {
		primary: {
			main: SYSTEM_COLORS.PRIMARY
		},
		secondary: {
			main: SYSTEM_COLORS.SECONDARY
		},
		background: {
			default: '#F7F8FC'
		}
	},
	typography: {
		fontFamily: ['Mulish', 'sans-serif'].join(','),
		h1: {
			fontSize: getRemFromPx(40),
			lineHeight: getRemFromPx(50),
			fontWeight: 600
		},
		h2: {
			fontSize: getRemFromPx(24),
			lineHeight: getRemFromPx(30),
			fontWeight: 600
		},
		h3: {
			fontSize: getRemFromPx(19),
			lineHeight: getRemFromPx(24),
			fontWeight: 600
		},
		h4: {
			fontSize: getRemFromPx(16),
			lineHeight: getRemFromPx(22),
			fontWeight: 600
		},
		h5: {
			fontSize: getRemFromPx(14),
			lineHeight: getRemFromPx(18),
			fontWeight: 600
		},
		h6: {
			fontSize: getRemFromPx(12),
			lineHeight: getRemFromPx(18),
			fontWeight: 600
		},
		body1: {
			// P1
			fontSize: getRemFromPx(18),
			lineHeight: getRemFromPx(27)
		},
		body2: {
			// P2
			fontSize: getRemFromPx(16),
			lineHeight: getRemFromPx(24)
		},
		subtitle1: {
			// Smalltext
			fontSize: getRemFromPx(10),
			lineHeight: getRemFromPx(15),
			fontWeight: 600
		},
		button: {
			color: 'backgroundLight',
			textTransform: 'none',
			fontSize: getRemFromPx(16),
			lineHeight: getRemFromPx(24),
			fontWeight: 600
		}
	},
	components: {
		MuiAppBar: {
			styleOverrides: {
				root: {
					boxShadow: 'none'
				},
				colorPrimary: {
					backgroundColor: '#F7F8FC'
				}
			}
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					background: '#FFFFFF'
				},
				elevation1: {
					boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.03)'
				},
				rounded: {
					border: '1px solid #DFE0EB',
					borderRadius: 16
				}
			},
		},
		MuiButton: {
			styleOverrides: {
				text: {},
				root: {
					borderRadius: 8
				},
				contained: {
					boxShadow: 'none'
				},
				outlined: {
					height: 40,
					boxShadow: 'none'
				},
				sizeSmall: {
					fontSize: getRemFromPx(12),
					lineHeight: getRemFromPx(18),
					fontWeight: 600,
					padding: '11px 20px'
				},
				sizeLarge: {
					fontSize: getRemFromPx(16),
					lineHeight: getRemFromPx(24),
					fontWeight: 600,
					padding: '15px 40px'
				}
			}
		}
	}
});

export default theme;
