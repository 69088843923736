import { useQuery } from '@apollo/client';
import { mdiChevronDown } from '@mdi/js';
import { Popover, SvgIcon, Theme } from '@mui/material';
import clsx from 'clsx';
import React, { ReactElement, useMemo, useRef, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import ButtonBase from '../../../../components/buttons/buttonBase';
import PopoverMenuButton from '../../../../components/buttons/popoverMenuButton';
import { SYSTEM_COLORS } from '../../../../core/config/colors';
import { useFriendlyKeyHelper } from '../../../../core/hooks/useFriendlyKeyHelper';
import { GetStoreContentsDocument, StoreProductFragment } from '../../../../generated/graphql';

interface Props {
	storeId: string;
	filterOptions: string[];
	setFilterOptions: (options: string[]) => void;
}

const Filter = (props: Props): ReactElement | null => {
	const { filterOptions, setFilterOptions } = props;
	const { classes } = useStyles();
	const divRef = useRef<any>();
	const variables = useFriendlyKeyHelper(props.storeId);
	const { data } = useQuery(GetStoreContentsDocument, {
		variables
	});
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
		setAnchorEl(divRef.current);
	};

	const handleClose = (): void => {
		setAnchorEl(null);
	};

	const uniqueProductList = useMemo(() => {
		if (!data?.team_store?.[0] || data?.team_store?.[0].store_products.length === 0) {
			return undefined;
		}

		const store = data.team_store[0];

		const filteredItems = store.store_products.reduce((cur, acc: StoreProductFragment): StoreProductFragment[] => {
			if (!acc) {
				return cur;
			}

			if (!cur.some((existProduct) => existProduct.id === acc.id)) {
				return [...cur, acc];
			}
			return cur;
		}, [] as StoreProductFragment[]);

		return filteredItems.sort((a, b) => (a?.label || '').localeCompare(b?.label || ''));
	}, [data?.team_store]);

	if (!uniqueProductList) {
		return null;
	}

	return (
		<div ref={divRef} className={classes.container}>
			<PopoverMenuButton
				onClick={handleClick}
				variant="contained"
				endIcon={
					<SvgIcon className={clsx(classes.icon, Boolean(anchorEl) ? classes.chevronFlip : null)}>
						<path d={mdiChevronDown} />
					</SvgIcon>
				}
			>
				{props.filterOptions.length === 0 ? 'Filter By' : `Filtering on: ${props.filterOptions.length} items`}
			</PopoverMenuButton>
			<Popover
				onClose={handleClose}
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				classes={{
					paper: classes.filterPopOver
				}}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left'
				}}
			>
				{uniqueProductList.map((product) => (
					<ButtonBase
						onClick={(): void => {
							if (filterOptions.includes(product.id)) {
								// removes element from array
								setFilterOptions(filterOptions.filter((item) => item !== product.id));
							} else {
								setFilterOptions([...filterOptions, product.id]);
							}
						}}
						buttonSizeOverride="small"
						variant={filterOptions.includes(product.id) ? 'contained' : 'outlined'}
						color={filterOptions.includes(product.id) ? 'primary' : 'inherit'}
						key={product.id}
						className={classes.item}
						fullWidth
					>
						{product.label}
					</ButtonBase>
				))}
			</Popover>
		</div>
	);
};

const useStyles = makeStyles()((theme: Theme) => {
	return {
		container: {},
		icon: {
			color: SYSTEM_COLORS.PRIMARY,
			transition: 'all .3s'
		},
		chevronFlip: {
			'-webkit-transform': 'rotate(180deg)',
			'-moz-transform': 'rotate(180deg)',
			'-ms-transform': 'rotate(180deg)',
			'-o-transform': 'rotate(180deg)',
			transform: 'rotate(180deg)'
		},
		item: {
			margin: '5px 0',
			height: 40
		},
		filterPopOver: {
			width: 280,
			padding: 15,
			borderRadius: 8,
			[theme.breakpoints.down('md')]: {
				width: '100%'
			}
		}
	};
});

export default Filter;
