import { useQuery } from "@apollo/client";
import { DialogContent, Theme, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React, { ReactElement } from "react";

import DialogActionBar from "../../../../components/block/dialogActionBar";
import DialogBase from "../../../../components/block/dialogBase";
import DialogTitleHeader from "../../../../components/block/dialogTitleHeader";
import P3 from "../../../../components/typography/p3";
import { formatCurrencyValueFromServer } from "../../../../core/utilites/currency";
import { GetCustomLinkOrdersDocument } from "../../../../generated/graphql";
import { formatDistance } from "date-fns";

interface Props {
  open: boolean;

  handleClose(): void;

  productId: string;
}

const DialogRecentPurchases = (props: Props): ReactElement => {
  const { classes } = useStyles();
  const { data } = useQuery(GetCustomLinkOrdersDocument, {
    pollInterval: 1000,
    variables: {
      _eq: props.productId
    }
  });

  return (
    <DialogBase open={props.open} closeDialog={props.handleClose}>
      <DialogTitleHeader closeDialog={props.handleClose} title="Recent Purchases" />
      <DialogContent>
        {(!data || (data && data.custom_link_purchase.length === 0)) && (
          <div style={{ textAlign: "center" }}>
            <Typography variant="h3">
              You currently have no orders - this page will auto-check for new orders
            </Typography>
          </div>
        )}
        {data && data.custom_link_purchase.length > 0 && (
          <div className={classes.paymentContainer}>
            {data.custom_link_purchase.map((purchase) => {
              return (
                <div className={classes.payment}>
                  <div>
                    <Typography variant="h5">{purchase.customer_name}</Typography>
                    <P3>{purchase.customer_email}</P3>
                  </div>
                  <div className={classes.info}>
                    <Typography variant="h5">
                      {formatCurrencyValueFromServer(purchase.purchase_price)}
                    </Typography>
                    <P3>{formatDistance(purchase.created_at, new Date())}</P3>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </DialogContent>
      <DialogActionBar cancelLabel="Close" cancelAction={props.handleClose} />
    </DialogBase>
  );
};

const useStyles = makeStyles()((theme: Theme) => {
  return {
    paymentContainer: {
      display: "grid",
      gridTemplateColumns: "repeat(1, 1fr)",
      gap: "15px"
    },
    payment: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    info: {
      textAlign: "right"
    }
  };
});

export default DialogRecentPurchases;
