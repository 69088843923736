import React, { ReactElement } from "react";
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import { Button, Dialog, DialogContent, Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { Product } from "../../core/stores/cart/action";
import { useNavigate, useParams } from "react-router-dom";

interface Props {
  product?: Product;
  onClose: ()=>void;
}

const AddProductDialog = ({product, onClose}: Props): ReactElement => {
  const { classes } = useStyles();
  const { storeId, groupId } = useParams();
  const navigate = useNavigate();

  const handleContinue = () => {
    onClose();
    if(window.history.state){
      window.history.back();
    }else{
      if(storeId){
        navigate(`/store/${storeId}`);
      }
      if(groupId){
        navigate(`/group/${groupId}`);
      }
    }
  }

  const handleGoToCart = () => {
    if(storeId){
      navigate(`/store/${storeId}/cart`);
    }
    if(groupId){
      navigate(`/group/${groupId}/cart`);
    }
  }

  return (
    <Dialog open={product!==undefined} onClose={onClose} className={classes.dialogContainer}>
      <DialogContent>
        {product?
          <div className={classes.dialogBody}>
            <div className={classes.image}>
              <img src={product.image} alt={product.label} />
            </div>
            <div className={classes.main}>
                <h4>Added to Cart</h4>
                <div>
                  {product.quantity>1?
                    <strong>{product.quantity} x </strong>
                    :null}
                    <span>{product.label}</span>
                </div>
            </div>
            <div className={classes.actions}>
              <Button
                variant={"contained"}
                fullWidth={true}
                style={{marginBottom: 10, whiteSpace: 'nowrap'}}
                autoFocus={true}
                onClick={handleGoToCart}
              >
                Proceed to Cart
              </Button>
              <Button
                variant={"outlined"}
                fullWidth={true}
                style={{whiteSpace: 'nowrap'}}
                onClick={handleContinue}
              >
                Continue Shopping
              </Button>
            </div>
          </div>
          :null}
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles()((theme: Theme) => {
  return {
    dialogContainer: {
      minWidth: 100,
      minHeight: 100
    },
    dialogBody:{
      display: "flex",
      [theme.breakpoints.down('sm')]: {
        flexWrap: "wrap",
        justifyContent: "center"
      },
    },
    image:{
      marginRight: 20,
      img:{
        maxWidth: 100,
        maxHeight: 80
      },
      [theme.breakpoints.down('sm')]: {
        marginRight: 0,
        img:{
          maxWidth: 150,
          maxHeight: 150
        },
      }
    },
    main:{
      flex: 1,
      minWidth: 250,
      display: "flex",
      flexDirection: "column",
      justifyItems: "center",
      h4:{
        margin: 0
      }
    },
    actions:{
      width: 180,
      [theme.breakpoints.down('sm')]: {
        width: "100%",
        marginTop: 15
      }
    }
  };
});

export default AddProductDialog;
