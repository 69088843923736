import { Button, ButtonProps, useMediaQuery, useTheme } from '@mui/material';
import React, { ReactElement } from 'react';

export interface ExtendedBaseProps extends ButtonProps {
	// Can't figure out how to extend the proper
	// override instance from ButtonProps.. Adding these in for
	// a stop gap.
	component?: any;
	to?: any;
	target?: any;
	buttonSizeOverride?: 'large' | 'small';
}

const ButtonBase = React.forwardRef((props: ExtendedBaseProps, ref: any): ReactElement => {
	const { children, buttonSizeOverride, ...rest } = props;
	const theme = useTheme();
	const isFullscreen = useMediaQuery(theme.breakpoints.down('sm'));
	const buttonSize = buttonSizeOverride ? buttonSizeOverride : isFullscreen ? 'large' : 'small';
	return (
		<Button {...rest} size={buttonSize} ref={ref}>
			{children}
		</Button>
	);
});

export default ButtonBase;
