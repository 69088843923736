import { useQuery } from '@apollo/client';
import { Paper, Theme } from '@mui/material';
import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import ErrorContainer from '../../components/block/errorContainer';
import { useFriendlyKeyHelper } from '../../core/hooks/useFriendlyKeyHelper';
import { GetShowcaseByIdDocument } from '../../generated/graphql';
import GroupProducts from './components/groupProducts';
import ShowcaseMiniHeader from './components/showcaseMiniHeader';
import ShowcasesSidebar from './components/showcasesSidebar';

const Showcase = (): ReactElement => {
	const { groupId, showcaseId, searchIndexId } = useParams();
	const { classes } = useStyles();
	const [searchIndex, setSearchIndex] = useState(searchIndexId);
	const variables = useFriendlyKeyHelper(showcaseId!);
	const { data, error, loading } = useQuery(GetShowcaseByIdDocument, { variables });

	useEffect(() => {
		setSearchIndex(searchIndexId);
	}, [searchIndexId]);

	if (error || (!data && !loading)) {
		console.error(error);
		return (
			<ErrorContainer
				title="Problem loading store."
				description="Please check the link again or contact us at	support@breakmark.com"
			/>
		);
	}

	if (data && !data.showcase?.[0]) {
		return (
			<ErrorContainer
				title="Incorrect Showcase ID"
				description="It looks like the store ID you are using is incorrect. Please check the link again or contact us at
					support@breakmark.com"
			/>
		);
	}

	if (data && groupId) {
		const showcaseItem = data.showcase[0];
		return (
			<div className={classes.content}>
				<Paper className={classes.navBar}>
					<ShowcasesSidebar groupId={showcaseItem.team_store.id} showcase={showcaseItem} />
				</Paper>
				<Paper className={classes.main}>
					{showcaseItem.banner_url ? (
						<div className={classes.banner}>
							<img src={showcaseItem.banner_url} alt={'Showcase Banner'} width={'100%'} />
						</div>
					) : null}
					<div className={classes.miniHeader}>
						<ShowcaseMiniHeader groupId={groupId} showcase={showcaseItem} />
					</div>
					<GroupProducts groupId={showcaseItem.team_store.id} showcase={data.showcase[0]} index={searchIndex} />
				</Paper>
			</div>
		);
	} else {
		return <></>;
	}
};

const useStyles = makeStyles()((theme: Theme) => {
	return {
		container: {},
		content: {
			flex: 1,
			display: 'flex'
		},
		main: {
			padding: 15,
			overflow: 'hidden',
			flex: 1
		},
		navBar: {
			width: 240,
			marginRight: 15,
			[theme.breakpoints.down('sm')]: {
				display: 'none'
			}
		},
		title: {
			width: '100%',
			padding: 10,
			textAlign: 'center'
		},
		miniHeader: {
			display: 'none',
			[theme.breakpoints.down('sm')]: {
				display: 'flex'
			}
		},
		banner: {
			margin: -15,
			marginBottom: 5
			// [theme.breakpoints.down('sm')]: {
			// 	display: 'none'
			// }
		}
		//
	};
});

export default Showcase;
