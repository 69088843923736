import React, { ReactElement } from 'react';

import ButtonBase, { ExtendedBaseProps } from '../buttonBase/buttonBase';

const PrimaryButton = React.forwardRef((props: ExtendedBaseProps, ref: any): ReactElement => {
	const { children, ...rest } = props;

	return (
		<ButtonBase variant="contained" color="primary" {...rest} ref={ref}>
			{children}
		</ButtonBase>
	);
});

export default PrimaryButton;
