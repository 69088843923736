import { useQuery } from '@apollo/client';
import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { ReactElement } from 'react';

import DialogBase from '../../../../components/block/dialogBase';
import { useFormattedStoreProduct } from '../../../../core/hooks/useFormattedStoreProduct';
import { Package } from '../../../../core/stores/cart/action';
import { GetPackageProductsByIdDocument, StorePackageFragment } from '../../../../generated/graphql';
import PackageForm from '../packageForm';

interface Props {
	editProductIndex?: number;
	editProduct?: Package;
	storeId: string;
	pkg: StorePackageFragment;
	open: boolean;
	handleClose(): void;
	discountType?: 'percent' | 'dollar';
	discountRate?: number;
}

const sortByObject = (data: string[]) => {
	return data.reduce((obj: any, item: string, index: number) => {
		return {
			...obj,
			[item]: index
		};
	}, {});
};

const PackageDialog = (props: Props): ReactElement => {
	const { classes } = useStyles();

	const { pkg } = props;

	const customSort = sortByObject(pkg.product_ids);

	const { data, loading } = useQuery(GetPackageProductsByIdDocument, {
		variables: {
			_in: pkg.product_ids
		}
	});

	const formattedProducts = useFormattedStoreProduct(data?.store_product);
	formattedProducts.sort((a, b) => customSort[a.id] - customSort[b.id]);

	return (
		<DialogBase open={props.open} containerClass={classes.dialogContainer}>
			{loading && <div>Loading... one moment</div>}
			{data?.store_product && (
				<PackageForm
					editProduct={props.editProduct}
					editProductIndex={props.editProductIndex}
					discountRate={props.discountRate}
					discountType={props.discountType}
					handleClose={props.handleClose}
					products={formattedProducts}
					storeId={props.storeId}
					packageId={props.pkg.id}
					packagePrice={props.pkg.custom_price || 0}
					packageLabel={props.pkg.label || ''}
					packageImage={props.pkg.photo_url || ''}
				/>
			)}
		</DialogBase>
	);
};

const useStyles = makeStyles()((theme: Theme) => {
	return {
		dialogContainer: {
			[theme.breakpoints.up('md')]: {
				minWidth: 800,
				maxWidth: 800
			}
		}
	};
});

export default PackageDialog;
