import { Theme } from '@mui/material';
import clsx from 'clsx';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import PrimaryButton from '../../../../../../components/buttons/primaryButton';
import SecondadryButton from '../../../../../../components/buttons/secondaryButton/secondaryButton';
import P5 from '../../../../../../components/typography/p5';
import { SYSTEM_COLORS } from '../../../../../../core/config/colors';
import { getProductOptionLabel } from '../../../../../../core/utilites/currency';
import { getShortSizeDescriptor } from '../../../../../../utilities/sizeHelpers';
import { getTestDataAttr } from '../../../../../../utilities/testHelpers';
import { ProductOption, ProductOptionList } from '../../../productList/productDef';

interface Props {
	selectedOptionId?: string;
	productOptionList: ProductOptionList;
	handleSelectedOption(option: ProductOption): void;
	ignoreNegativeValue?: boolean;
	discountType?: 'percent' | 'dollar';
	discountRate?: number;
	removedValues?: string[];
	testId?: string;
}

const SelectOption = (props: Props): ReactElement => {
	const { classes } = useStyles();
	const [selectedOption, setSelectedOption] = useState<undefined | ProductOption>(undefined);

	const { productOptionList } = props;

	const disabledOptions = useMemo(() => {
		if (!props.removedValues) {
			return undefined;
		}

		const removedValueStrings = props.removedValues;

		const removedValueKeys = props.productOptionList.options
			.map((o): string | undefined => {
				const shortLabel = getShortSizeDescriptor(props.productOptionList.label, o.label);
				const foundItem = removedValueStrings.find((comp) => comp === shortLabel);

				if (foundItem) {
					return o.id;
				}
				return undefined;
			})
			.filter((a) => a);

		return removedValueKeys;
	}, [props.removedValues, props.productOptionList]);

	useEffect(() => {
		if (props.selectedOptionId) {
			const foundOption = productOptionList.options.find((productOption) => productOption.id === props.selectedOptionId);
			if (foundOption) {
				setSelectedOption(foundOption);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (selectedOption) {
			props.handleSelectedOption(selectedOption);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedOption]);

	return (
		<div {...getTestDataAttr(props.testId)}>
			<P5>
				{productOptionList.label}:{' '}
				<strong>
					{selectedOption
						? `${getProductOptionLabel({
								name: selectedOption.label,
								discountRate: props.discountRate,
								discountType: props.discountType,
								ignorePriceModifier: props.ignoreNegativeValue,
								price: `${selectedOption.priceModifier || 0}`
						  })} `
						: 'Select an option'}
				</strong>
			</P5>
			<div className={classes.imageSelector}>
				{productOptionList.options.map((option: ProductOption, i: number) => {
					const ButtonComponent = option.id === selectedOption?.id ? PrimaryButton : SecondadryButton;
					const descriptor = getShortSizeDescriptor(productOptionList.label, option.label);
					const isDisabled = disabledOptions && disabledOptions.includes(option.id);

					return (
						<div key={option.id} className={clsx(classes.itemContainer)}>
							<ButtonComponent
								disabled={isDisabled}
								className={classes.selectButton}
								aria-label={option.label}
								buttonSizeOverride="small"
								onClick={(): void => {
									setSelectedOption(option);
								}}
							>
								{descriptor}
							</ButtonComponent>
						</div>
					);
				})}
			</div>
		</div>
	);
};

const useStyles = makeStyles()((theme: Theme) => {
	return {
		imageSelector: {
			display: 'flex',
			flexWrap: 'wrap'
		},
		imageThumbnails: {
			width: 75,
			height: 75
		},
		itemContainer: {
			padding: 5,
			border: '2px solid transparent',
			'&:first-of-type': {
				paddingLeft: 0
			}
		},
		itemSelected: {
			borderColor: SYSTEM_COLORS.PRIMARY
		},
		selectButton: {
			minWidth: 50,
			padding: 10
		}
	};
});

export default SelectOption;
