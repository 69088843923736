import { Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { ReactElement } from 'react';
import { Link, useParams } from "react-router-dom";

import PrimaryButton from '../../../../components/buttons/primaryButton';
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { TeamStoreFragment } from '../../../../generated/graphql';
import ClosedStore from '../../components/closedStore';
import PackageList from '../../components/packageList';
import ProductList from '../../components/productList';
import StagingStore from '../../components/stagingStore';
import remarkGfm from "remark-gfm";

interface Props {
	storeData: TeamStoreFragment;
	discountRate: number | undefined;
	discountType: 'dollar' | 'percent' | undefined;
}

const Store = (props: Props): ReactElement => {
	const storeData = props.storeData;
	const { classes } = useStyles();
	const { storeId } = useParams();

	if (storeData.status === 'staging') {
		return <StagingStore storeName={storeData.name} />;
	}

	if (storeData.status === 'closed') {
		return <ClosedStore storeName={storeData.name} />;
	}

	return (
		<div className={classes.container}>
			{storeData.banner_url ?
				<div className={classes.banner}>
					<img src={storeData.banner_url} alt={"Showcase Banner"} width={"100%"} />
				</div>
				:null}
			<Typography className={classes.title} variant="h2">
				{storeData.name}
			</Typography>
			{storeData.welcome_message?
				<ReactMarkdown remarkPlugins={[remarkGfm]} className={classes.welcome}>
					{storeData.welcome_message}
				</ReactMarkdown>
				:null}
			<div className={classes.productContainer}>
				{storeData.store_packages && (
					<PackageList
						packages={storeData.store_packages}
						storeId={storeData.id}
						discountRate={props.discountRate}
						discountType={props.discountType}
					/>
				)}
				<ProductList
					products={storeData.store_products}
					storeId={storeData.id}
					discountRate={props.discountRate}
					discountType={props.discountType}
				/>
			</div>
			<div className={classes.backToOrders}>
				<PrimaryButton component={Link} to={`/store/${storeId}/orders`}>
					View Completed Orders
				</PrimaryButton>
			</div>
		</div>
	);
};

const useStyles = makeStyles()((theme: Theme) => {
	return {
		app: {},
		avatar: {
			marginLeft: 'auto',
			marginRight: 'auto',
			backgroundColor: '#199cdb',
			width: 80,
			height: 80,
			'& svg': {
				width: 60,
				height: 60
			}
		},
		container: {
			maxWidth: 1100,
			marginLeft: 'auto',
			marginRight: 'auto',
			textAlign: 'center',
			marginBottom: '40px'
		},
		banner: {
			margin: 0,
			[theme.breakpoints.down('sm')]: {
				display: "none"
			}
		},
		welcome: {

		},
		root: {
			flexGrow: 1
		},
		title: {
			marginTop: '30px',
			marginBottom: '30px'
		},
		productContainer: {
			display: 'grid',
			gridTemplateColumns: 'repeat(4, 1fr)',
			gap: '15px',
			[theme.breakpoints.down('md')]: {
				gridTemplateColumns: 'repeat(3, 1fr)'
			},
			[theme.breakpoints.down('sm')]: {
				gridTemplateColumns: 'repeat(1, 1fr)'
			},
			[theme.breakpoints.down('xs')]: {
				gridTemplateColumns: 'repeat(1, 1fr)'
			}
		},
		backToOrders: {
			marginTop: 20,
			textAlign: 'center',
			width: '100%'
		}
	};
});

export default Store;
