import { FormControl, FormHelperText, InputLabel, MenuItem, Select, Theme, Typography } from '@mui/material';
import { Formik } from 'formik';
import React, { ReactElement } from 'react';
import { makeStyles } from 'tss-react/mui';
import * as Yup from 'yup';

import PrimaryButton from '../../../../../../components/buttons/primaryButton';
import InputField from '../../../../../../components/inputs/inputField';
import { SYSTEM_COLORS } from '../../../../../../core/config/colors';
import { useUIStore } from '../../../../../../core/providers/uiProvider/uiProvider';
import { formatCurrencyValueFromServer } from '../../../../../../core/utilites/currency';

export interface CheckoutOptions {
	paymentType: 'card' | 'check';
	name: string;
	email: string;
	attributionSelected?: string;
}

interface Props {
	itemCount: number;
	cartTotal: number;
	loading: boolean;
	disabled: boolean;
	handleCheckout(checkoutOptions: CheckoutOptions): void;
	attributionList?: string[];
}

const Checkout = (props: Props): ReactElement => {
	const { classes } = useStyles();
	const { loading, handleCheckout, disabled } = props;
	const uiOptions = useUIStore();

	const NameSchema = Yup.object().shape({
		name: Yup.string().required('Required'),
		email: Yup.string().email('Invalid email').required('Required'),
		...(props.attributionList &&
			props.attributionList.length > 0 && { attributionSelected: Yup.string().required('Required') })
	});

	return (
		<Formik
			initialValues={{ name: undefined, email: undefined, paymentType: 'card', attributionSelected: undefined }}
			validationSchema={NameSchema}
			onSubmit={(values) => {
				handleCheckout({
					email: values.email || '',
					name: values.name || '',
					paymentType: values.paymentType === 'check' ? 'check' : 'card',
					attributionSelected: values?.attributionSelected
				});
			}}
		>
			{({ handleChange, handleBlur, errors, isValid, dirty, handleSubmit, setFieldValue, values }) => {
				return (
					<div className={classes.container}>
						<Typography variant="h3">Order Summary</Typography>
						<div className={classes.totals}>
							<Typography variant="h5">Total ({props.itemCount})</Typography>
							<Typography variant="h5">
								<strong>{formatCurrencyValueFromServer(props.cartTotal)}</strong>
							</Typography>
						</div>

						<div className={classes.paymentOptions}>
							<InputField
								required
								fullWidth
								variant="outlined"
								label={'Name'}
								name="name"
								onBlur={handleBlur}
								onChange={handleChange}
								error={Boolean(errors.name)}
							/>
							<InputField
								required
								fullWidth
								variant="outlined"
								label={'Email Address'}
								name="email"
								onBlur={handleBlur}
								onChange={handleChange}
								error={Boolean(errors.email)}
							/>
							{props.attributionList && props.attributionList.length > 0 && (
								<>
									<FormControl fullWidth>
										<InputLabel className={classes.inputLabel}>Direct Proceeds to *</InputLabel>
										<Select
											label="Direct Proceeds to"
											className={classes.selectField}
											fullWidth
											value={values.attributionSelected}
											onChange={(evt) => setFieldValue('attributionSelected', evt.target.value)}
											size={'small'}
											required
										>
											{props.attributionList.map((attribution, index) => {
												return (
													<MenuItem key={`qty-${index}`} value={`${attribution}`}>
														{attribution}
													</MenuItem>
												);
											})}
										</Select>{' '}
										<FormHelperText>
											A portion of the sales will be directed to the selected group above!
										</FormHelperText>
									</FormControl>
								</>
							)}
						</div>
						<div className={classes.paymentOptions}>
							{uiOptions.hasCheckOption && (
								<PrimaryButton
									fullWidth
									name="type"
									value={'check'}
									onClick={(): void => {
										setFieldValue('paymentType', 'check');
										handleSubmit();
									}}
									variant="contained"
									disabled={loading || disabled || Boolean(dirty === false || isValid === false)}
								>
									Pay with Check
								</PrimaryButton>
							)}
							{uiOptions.hasCreditCardOption && (
								<PrimaryButton
									fullWidth
									name="type"
									value={'card'}
									onClick={(): void => {
										setFieldValue('paymentType', 'card');
										handleSubmit();
									}}
									variant="contained"
									disabled={loading || disabled || Boolean(dirty === false || isValid === false)}
								>
									Pay with Card
								</PrimaryButton>
							)}
						</div>
					</div>
				);
			}}
		</Formik>
	);
};

const useStyles = makeStyles()((theme: Theme) => {
	return {
		container: {
			display: 'grid',
			gridTemplateColumns: 'repeat(1, 1fr)',
			gap: 25,
			borderRadius: 8,
			padding: '15px',
			backgroundColor: SYSTEM_COLORS.GRAYSCALE_GRAY_LIGHTEST,
			marginLeft: 10,
			width: 300,
			[theme.breakpoints.down('sm')]: {
				marginLeft: 0,
				width: '100%'
			}
		},
		containerRoot: {
			flexDirection: 'column',
			borderTop: `1px solid ${theme.palette.divider}`,
			padding: 10,
			display: 'block',
			'& :not(:first-child)': {
				marginLeft: 0
			}
		},
		button: {
			[theme.breakpoints.down('sm')]: {
				width: '100%'
			}
		},
		paymentOptions: {
			display: 'grid',
			gridTemplateColumns: 'repeat(1, 1fr)',
			gap: 15
		},
		actionContainer: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between'
		},
		buttons: {
			'& :not(:first-child)': {
				marginLeft: 8
			}
		},
		totals: {
			width: '100%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between'
		},
		inputContainer: {
			marginBottom: 8,
			display: 'grid',
			gridTemplateColumns: 'repeat(2, 2fr)',
			gap: '8px'
		},
		selectField: {
			backgroundColor: '#FFF'
		},
		inputLabel: {
			top: -7,
			'&.MuiInputLabel-shrink': {
				top: 0
			}
		}
	};
});
export default Checkout;
