import { Card, CardActionArea, CardContent, CardMedia, Theme, Typography } from '@mui/material';
import React, { ReactElement, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import P5 from '../../../../components/typography/p5';
import { useFormattedStoreProduct } from '../../../../core/hooks/useFormattedStoreProduct';
import { formatCurrencyValueFromServer, handleStoreDiscount } from '../../../../core/utilites/currency';
import { StoreProductFragment } from '../../../../generated/graphql';
import { getTestDataAttr } from '../../../../utilities/testHelpers';
import Ribbon from './ribbon';

interface Props {
	storeId: string;
	products: StoreProductFragment[];
	discountType?: 'percent' | 'dollar';
	discountRate?: number;
}

const ProductList = (props: Props): ReactElement => {
	const { classes } = useStyles();
	const navigate = useNavigate();
	const formattedProducts = useFormattedStoreProduct(props.products);

	const orderedProducts = useMemo(() => {
		return formattedProducts.sort((a, b) => a.order - b.order);
	}, [formattedProducts]);

	return (
		<>
			{orderedProducts.map((product, index): ReactElement | null => {
				if (!product.product.base_price || !!product.bundle_only) {
					return null;
				}

				const isDisabled = product.enforce_limit_qty && product.limit_order_qty - product.qty_purchased_calc <= 0;

				// let alternateImage = undefined;
				// if (product?.additional_image_list) {
				// 	alternateImage = [...product.additional_image_list].sort((a, b) => {
				// 		return a.index - b.index;
				// 	});
				// }

				return (
					<Card key={product.id}>
						<CardActionArea
							classes={{
								root: classes.rootCardAction
							}}
							{...getTestDataAttr(`product-card-${index}`)}
							onClick={(): void => {
								navigate(`product/${product.friendly_url_key || product.id}`);
							}}
						>
							{product.enforce_limit_qty && product.limit_order_qty - product.qty_purchased_calc <= 4 && (
								<Ribbon
									color={isDisabled ? 'red' : 'green'}
									label={
										isDisabled ? 'Sold Out' : `${product.limit_order_qty - product.qty_purchased_calc} Left!`
									}
								/>
							)}

							{isDisabled && <div className={classes.disabledOverlay} />}
							{product.display_photo && (
								<CardMedia
									// image={alternateImage?.[1] ? alternateImage?.[1].url : product.display_photo}
									image={product.display_photo}
									className={classes.media}
								/>
							)}
							<CardContent>
								<Typography gutterBottom variant="h5" component="h2">
									{product.label}
								</Typography>
								<P5>
									{' '}
									{formatCurrencyValueFromServer(
										handleStoreDiscount({
											discountRate: props.discountRate,
											type: props.discountType,
											incomingValue: product.product.base_price
										})
									)}
								</P5>
								{props.discountRate && (
									<P5 className={classes.strikeOut}>
										Originally <span>{formatCurrencyValueFromServer(product.product.base_price)}</span>
									</P5>
								)}
							</CardContent>
						</CardActionArea>
					</Card>
				);
			})}
		</>
	);
};

const useStyles = makeStyles()((theme: Theme) => {
	return {
		media: {
			height: 260
		},
		rootCardAction: {
			'& .MuiCardMedia-root': {
				backgroundSize: 'contain'
			}
		},
		strikeOut: {
			'& span': {
				textDecoration: 'line-through'
			}
		},
		disabledOverlay: {
			position: 'absolute',
			top: 0,
			left: 0,
			bottom: 0,
			right: 0,
			backgroundColor: 'rgba(0,0,0,.50)',
			zIndex: 1200
		}
	};
});

export default ProductList;
