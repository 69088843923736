import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { ReactElement, ReactNode } from 'react';
import Header from './components/header';
import { useParams } from "react-router-dom";

interface Props {
	children: ReactNode;
}

const MainContainer = (props: Props): ReactElement => {
	const { classes } = useStyles();
	const { storeId, groupId } = useParams();

	return (
			<div className={classes.container}>
				<Header storeId={storeId} groupId={groupId} />
				<main className={classes.main}>{props.children}</main>
			</div>
	);
};

const useStyles = makeStyles()((theme: Theme) => {
	return {
		main: {
			padding: 30,
			width: '100%',
			maxWidth: 1100,
			margin: 'auto',
			[theme.breakpoints.down('md')]: {
				padding: 10
			}
		},
		container: {
			flex: 1,
			height: '100%',
			width: '100%'
		}
	};
});

export default MainContainer;
