import React, { ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';

import AppContainer from './core/AppContainer';
import { AuthProvider } from './core/providers/authProvider/authProvider';
import CustomPaymentLink from './page/customPaymentLink';
import Group from './page/group/group';
import Home from './page/home';
import PackagingSlip from './page/packagingSlip';
import Store from './page/store';

interface Props {
	c?: any;
}

const App = (props: Props): ReactElement => {
	let authToken = localStorage.getItem('authToken');

	return (
		<AuthProvider authToken={authToken}>
			<AppContainer>
				<Routes>
					<Route path={'/store/:storeId/*'} element={<Store />} />
					<Route path={'/group/:groupId/*'} element={<Group />} />
					<Route path={'/packaging-slip/:id'} element={<PackagingSlip />} />
					<Route path={'/generate-link/:id'} element={<CustomPaymentLink />} />
					<Route path={'/callback/:id/:payment_id/:status'} element={<Store />} />
					<Route path={'/login/:token/'} element={<Home />} />
					<Route path={'/'} element={<Home />} />
				</Routes>
			</AppContainer>
		</AuthProvider>
	);
};

export default App;
