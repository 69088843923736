import { useMutation } from '@apollo/client';
import { mdiAccountCircle, mdiApple, mdiClipboardTextOutline, mdiEmail, mdiLogout, mdiMenuDown } from '@mdi/js';
import { Avatar, Button, Fade, ListItemAvatar, ListItemIcon, ListItemText, Menu, MenuItem, SvgIcon, Theme } from '@mui/material';
import { GoogleLogin } from '@react-oauth/google';
import { useSnackbar } from 'notistack';
import React, { ReactElement, useState } from 'react';
import AppleLogin from 'react-apple-login';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { AuthLoginDocument } from '../../../generated/graphql';
import { useAuth } from '../../providers/authProvider/authProvider';
import EmailDialog from './emailDialog';

interface Props {
	c?: any;
}

const ProfileMenu = (props: Props): ReactElement => {
	const { classes } = useStyles();
	const { token } = useParams();
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const [emailDialogOpen, setEmailDialogOpen] = useState(false);
	const [authLogin] = useMutation(AuthLoginDocument);
	const { user, setToken } = useAuth();
	const [betaFeatures] = useState(localStorage.getItem('BETA_FEATURES'));

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleLogin = async (loginType: string, credentialResponse: any) => {
		// console.log("handleLogin", loginType, credentialResponse);
		if (credentialResponse.error) {
			// console.error("apple login error", credentialResponse);
		} else {
			const result = await authLogin({
				variables: {
					loginType: loginType,
					credentialResponse: JSON.stringify(credentialResponse)
				}
			});
			// console.log("handleLogin", result.data?.authLogin);
			if (result.data!.authLogin && !result.data!.authLogin.error && result.data!.authLogin.token) {
				setToken(result.data!.authLogin.token);
				handleClose();
			} else {
				console.error('Login Failed', result.data!.authLogin);
				enqueueSnackbar('Login Failed', { variant: 'error' });
			}
		}
	};

	const handleLogout = () => {
		setToken(null);
		handleClose();
	};

	if (token) {
		// alert(token);
		handleLogin('email', { token: token }).then(() => {
			// console.log('email login handled');
			navigate('/');
		});
	}

	if (betaFeatures) {
		if (!user) {
			return (
				<div className={classes.container}>
					<Button
						id="fade-button"
						aria-controls={open ? 'fade-menu' : undefined}
						aria-haspopup="true"
						aria-expanded={open ? 'true' : undefined}
						onClick={handleClick}
						className={classes.button}
						endIcon={
							<SvgIcon>
								<path d={mdiMenuDown} />
							</SvgIcon>
						}
					>
						Sign in
					</Button>
					<Menu
						id="fade-menu"
						MenuListProps={{
							'aria-labelledby': 'fade-button'
						}}
						anchorEl={anchorEl}
						open={open}
						onClose={handleClose}
						TransitionComponent={Fade}
						className={classes.menu}
					>
						<MenuItem onClick={handleClose}>
							<div style={{ width: 205, height: 40 }}>
								<GoogleLogin
									onSuccess={(credentialResponse) => handleLogin('google', credentialResponse)}
									onError={() => {
										// console.log("Login Failed");
									}}
									width={'205px'}
								/>
							</div>
						</MenuItem>
						<MenuItem onClick={handleClose}>
							<AppleLogin
								clientId={'com.teamupwith.www'}
								redirectURI={'https://testupwith.com/auth/apple'}
								usePopup={true}
								responseMode={'query'}
								callback={(credentialResponse) => handleLogin('apple', credentialResponse)}
								render={(props: any) => (
									<Button
										{...props}
										variant={'outlined'}
										startIcon={
											<SvgIcon style={{ margin: 0, marginLeft: -15 }}>
												<path d={mdiApple} />
											</SvgIcon>
										}
										style={{
											width: 205,
											height: 40,
											borderRadius: 4,
											borderColor: '#d2e3fc',
											color: '#222',
											fontSize: 15,
											letterSpacing: 0.25
										}}
									>
										Sign in with Apple
									</Button>
								)}
							/>
						</MenuItem>
						<MenuItem>
							<Button
								{...props}
								variant={'outlined'}
								startIcon={
									<SvgIcon style={{ margin: 0, marginLeft: -12 }}>
										<path d={mdiEmail} />
									</SvgIcon>
								}
								style={{
									width: 205,
									height: 40,
									borderRadius: 4,
									borderColor: '#d2e3fc',
									color: '#222',
									fontSize: 15,
									letterSpacing: 0.25
								}}
								onClick={() => setEmailDialogOpen(true)}
							>
								Send a sign-in Link
							</Button>
						</MenuItem>
					</Menu>
					<EmailDialog
						open={emailDialogOpen}
						onClose={() => {
							setEmailDialogOpen(false);
							handleClose();
						}}
					/>
				</div>
			);
		} else {
			return (
				<div className={classes.container}>
					<Button
						id="fade-button"
						aria-controls={open ? 'fade-menu' : undefined}
						aria-haspopup="true"
						aria-expanded={open ? 'true' : undefined}
						onClick={handleClick}
						className={classes.button}
						startIcon={
							user.picture ? (
								<Avatar sx={{ width: 24, height: 24, border: '1px solid white' }}>
									<img src={user.picture} width={24} height={24} alt={'User Acount Menu'} />
								</Avatar>
							) : (
								<SvgIcon style={{ width: 24, height: 24 }}>
									<path d={mdiAccountCircle} />
								</SvgIcon>
							)
						}
						endIcon={
							<SvgIcon>
								<path d={mdiMenuDown} />
							</SvgIcon>
						}
					></Button>
					<Menu
						id="fade-menu"
						MenuListProps={{
							'aria-labelledby': 'fade-button'
						}}
						anchorEl={anchorEl}
						open={open}
						onClose={handleClose}
						TransitionComponent={Fade}
						className={classes.menu}
					>
						<MenuItem onClick={handleClose}>
							<ListItemAvatar>
								<Avatar>
									{user.picture ? (
										<img src={user.picture} width={48} height={48} alt={'User Settings Item'} />
									) : (
										<SvgIcon style={{ margin: 0, width: 48, height: 48 }}>
											<path d={mdiAccountCircle} />
										</SvgIcon>
									)}
								</Avatar>
							</ListItemAvatar>
							<ListItemText primary={'Account settings'} secondary={user.name || user.email}>
								Account settings
							</ListItemText>
						</MenuItem>
						<MenuItem onClick={handleClose}>
							<ListItemIcon>
								<SvgIcon>
									<path d={mdiClipboardTextOutline} />
								</SvgIcon>
							</ListItemIcon>
							<ListItemText>Your orders</ListItemText>
						</MenuItem>
						<MenuItem onClick={handleLogout}>
							<ListItemIcon>
								<SvgIcon>
									<path d={mdiLogout} />
								</SvgIcon>
							</ListItemIcon>
							<ListItemText>Sign out</ListItemText>
						</MenuItem>
					</Menu>
				</div>
			);
		}
	} else {
		return <></>;
	}
};

const useStyles = makeStyles()((theme: Theme) => {
	return {
		container: {
			marginRight: 20
		},
		menu: {
			'& .MuiPaper-root': {
				borderRadius: 6
			},
			'& .MuiMenu-list': {
				padding: '6px 0'
			},
			'& .MuiMenuItem-root': {
				padding: '3px 10px',
				'& .MuiSvgIcon-root': {
					marginLeft: 8,
					marginRight: 25,
					marginTop: 5,
					marginBottom: 5
				},
				'&:active': {}
			}
		},
		button: {
			color: 'white'
		}
	};
});

export default ProfileMenu;
