import { useQuery } from '@apollo/client';
import { mdiChevronLeft } from '@mdi/js';
import { mdiArrowTopLeftBoldOutline } from '@mdi/js';
import { Button, MenuItem, Select, SvgIcon, Theme } from '@mui/material';
import React, { ReactElement, useEffect, useState } from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { useNavigate, useParams } from 'react-router-dom';
import remarkGfm from 'remark-gfm';
import { makeStyles } from 'tss-react/mui';

import { useFriendlyKeyHelper } from '../../core/hooks/useFriendlyKeyHelper';
import { Product as CartProduct } from '../../core/stores/cart/action';
import { GetProductByIdDocument, ProductByIdFragment } from '../../generated/graphql';
import CustomNameNumber from './components/customNameNumber';
import { CustomizationModel } from './customizationModel';
import { OptionModel } from './optionModel';
import ProductImages from './productImages';
import ProductOptions from './productOptions';
import ProductPrice from './productPrice';

interface Props {
	actionName?: string;
	action: (product: CartProduct) => void;
}

const ProductPanel = ({ actionName, action }: Props): ReactElement => {
	const { classes } = useStyles();
	const { productId, groupId, storeId } = useParams();
	const navigate = useNavigate();
	const [product, setProduct] = useState<undefined | ProductByIdFragment>();
	const [quantity, setQuantity] = useState('1');
	const [price, setPrice] = useState(0);
	const [customization, setCustomization] = useState<CustomizationModel>({ number: '', name: '' });
	const [selectedOptions, setSelectedOptions] = useState<OptionModel[]>([]);

	const variables = useFriendlyKeyHelper(productId!);
	const { data } = useQuery(GetProductByIdDocument, { variables });

	useEffect(() => {
		if (data?.store_product?.[0]) {
			setProduct(data.store_product[0]);
		}
		setSelectedOptions([]);
	}, [data]);

	const handleOptionsChange = (options: OptionModel[]) => {
		setSelectedOptions(options);
	};

	const allOptionsSelected = () => {
		let isValid = true;

		const availableOptionIds = product?.option_values.map((option) => option.available_option_id);
		const optionSet = [...new Set(availableOptionIds)];

		optionSet.forEach((opt: any) => {
			const matched = selectedOptions.filter((sel) => sel.availableOptionId === opt);
			if (matched.length === 0) {
				isValid = false;
			}
		});
		return isValid;
	};

	const handleActionClick = () => {
		let options: any = {};
		let image = product?.display_photo || '';
		selectedOptions.forEach((opt) => {
			if (opt.image_url && opt.image_url.length > 0) {
				image = opt.image_url;
			}
			options[opt.availableOptionValueId] = {
				availableOptionId: opt.availableOptionId,
				availableOptionValueId: opt.availableOptionValueId,
				priceModifiedAs: opt.priceModifiedAs,
				value: opt.value,
				label: opt.label,
				imageUrl: opt.image_url
			};
		});
		const cartProduct: CartProduct = {
			type: 'product',
			label: product?.label || '',
			id: product?.id || '',
			image: image,
			custom_name: customization.name,
			custom_number: customization.number,
			options: options,
			quantity: parseInt(quantity),
			price: price
		};
		action(cartProduct);
	};

	if (product) {
		const isDisabled = product.enforce_limit_qty && product.limit_order_qty - product.qty_purchased_calc <= 0;

		return (
			<div className={classes.panel}>
				<div className={classes.left}>
					{window.history.state ? (
						<div className={classes.back} onClick={() => window.history.back()}>
							<SvgIcon>
								<path d={mdiChevronLeft} />
							</SvgIcon>
							<span>Back</span>
						</div>
					) : (
						<div className={classes.left}>
							{groupId ? (
								<div className={classes.back} onClick={() => navigate(`/group/${groupId}`)}>
									<SvgIcon>
										<path d={mdiArrowTopLeftBoldOutline} />
									</SvgIcon>
									<span>Home</span>
								</div>
							) : null}
							{storeId ? (
								<div className={classes.back} onClick={() => navigate(`/store/${storeId}`)}>
									<SvgIcon>
										<path d={mdiArrowTopLeftBoldOutline} />
									</SvgIcon>
									<span>Home</span>
								</div>
							) : null}
						</div>
					)}
					<ProductImages product={product} options={selectedOptions} />
				</div>
				<div className={classes.right}>
					<ProductPrice
						customization={customization}
						style={{ float: 'right' }}
						product={product}
						options={selectedOptions}
						discountRate={product.team_store.store_discount_rate || 0}
						discountType={
							product.team_store.store_discount_type === 'dollar' ||
							product.team_store.store_discount_type === 'percent'
								? product.team_store.store_discount_type
								: undefined
						}
						onChange={(p) => setPrice(p)}
					/>
					<h3>{product.label}</h3>
					{product.description && (
						<>
							<ReactMarkdown remarkPlugins={[remarkGfm]} className={classes.description}>
								{product.description}
							</ReactMarkdown>
						</>
					)}
					<div className={classes.optionsBox}>
						<CustomNameNumber product={product} setCustomization={setCustomization} customization={customization} />
						<ProductOptions
							product={product}
							options={selectedOptions}
							onChange={handleOptionsChange}
							discountRate={product.team_store.store_discount_rate || 0}
							discountType={
								product.team_store.store_discount_type === 'dollar' ||
								product.team_store.store_discount_type === 'percent'
									? product.team_store.store_discount_type
									: undefined
							}
						/>
						{!product.limit_order_qty && <h6>Quantity</h6>}
						<div className={classes.row}>
							{!product.limit_order_qty && (
								<Select
									value={quantity}
									onChange={(evt) => setQuantity(evt.target.value)}
									className={classes.textBox}
									style={{ width: 120 }}
									size={'small'}
								>
									{Array.from({ length: 80 }).map((_, index) => {
										return (
											<MenuItem key={`qty-${index}`} value={`${index + 1}`}>
												{index + 1}
											</MenuItem>
										);
									})}
								</Select>
							)}
							{isDisabled ? (
								<Button
									className={classes.addButton}
									color={'primary'}
									variant={'contained'}
									fullWidth={true}
									disabled={true}
								>
									SOLD OUT
								</Button>
							) : (
								<Button
									className={classes.addButton}
									color={'primary'}
									variant={'contained'}
									fullWidth={true}
									disabled={!allOptionsSelected()}
									onClick={handleActionClick}
								>
									{actionName ? actionName : 'Add to Cart'}
								</Button>
							)}
						</div>
					</div>
					{product.additional_description && (
						<>
							{/* <h6>Description</h6> */}
							<ReactMarkdown remarkPlugins={[remarkGfm]} className={classes.description}>
								{product.additional_description}
							</ReactMarkdown>
						</>
					)}
				</div>
			</div>
		);
	} else {
		return <div></div>;
	}
};

const useStyles = makeStyles()((theme: Theme) => {
	return {
		panel: {
			display: 'flex',
			h6: {
				marginTop: 0,
				marginBottom: 2
			},
			[theme.breakpoints.down('sm')]: {
				display: 'block'
			}
		},
		left: {
			flex: 1,
			position: 'relative',
			paddingTop: 20
		},
		right: {
			flex: 1
		},
		back: {
			position: 'absolute',
			top: 0,
			left: 0,
			display: 'flex',
			alignItems: 'center',
			color: theme.palette.primary.dark,
			cursor: 'pointer',
			zIndex: 99
		},
		description: {
			marginBottom: 15
		},
		optionsBox: {
			backgroundColor: theme.palette.grey['100'],
			padding: 15,
			h6: {
				marginTop: 0,
				marginBottom: 2
			}
		},
		row: {
			display: 'flex',
			marginBottom: 10
		},
		addButton: {
			backgroundColor: theme.palette.primary.dark,
			color: theme.palette.background.paper,
			borderColor: theme.palette.primary.light,
			borderWidth: 2,
			fontWeight: 'bold',
			maxWidth: 250
		},
		textBox: {
			backgroundColor: theme.palette.background.paper,
			borderColor: theme.palette.primary.dark,
			marginRight: 10
		}
	};
});

export default ProductPanel;
