import React, { ReactElement, ReactNode } from 'react';
import { DialogActions, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import PrimaryButton from '../../buttons/primaryButton';

interface Props {
	saveLabel?: string;
	saveAddtLabel?: string;
	cancelAction?(): void;
	saveAction?(): void;
	saveAddtAction?(): void;
	cancelLabel?: string;
	disableSave?: boolean;
	children?: ReactNode;
}

const DialogActionBar = (props: Props): ReactElement => {
	const { classes } = useStyles();

	return (
		<DialogActions
			classes={{
				root: classes.containerRoot
			}}
		>
			{props.children}
			{props.cancelAction && (
				<PrimaryButton variant="outlined" onClick={props.cancelAction} className={classes.cancel}>
					{props.cancelLabel ? props.cancelLabel : 'Cancel'}
				</PrimaryButton>
			)}
			{props.saveAddtAction && (
				<PrimaryButton
					variant="contained"
					onClick={props.saveAddtAction}
					className={classes.button}
					disabled={props.disableSave}
				>
					{props.saveAddtLabel ? props.saveAddtLabel : 'Save Extra'}
				</PrimaryButton>
			)}
			{props.saveAction && (
				<PrimaryButton
					variant="contained"
					onClick={props.saveAction}
					className={classes.button}
					disabled={props.disableSave}
				>
					{props.saveLabel ? props.saveLabel : 'Save'}
				</PrimaryButton>
			)}
		</DialogActions>
	);
};

const useStyles = makeStyles()((theme: Theme) => {
	return {
		containerRoot: {
			borderTop: `1px solid ${theme.palette.divider}`,
			padding: '21px 24px'
		},
		button: {
			[theme.breakpoints.down('sm')]: {
				width: '100%'
			}
		},
		cancel: {
			[theme.breakpoints.down('sm')]: {
				display: 'none'
			}
		}
	};
});

export default DialogActionBar;
