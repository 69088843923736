import React, { ReactElement, useEffect, useState } from "react";
import { Theme, SvgIcon, Select, MenuItem } from "@mui/material";
import { makeStyles } from 'tss-react/mui';
import { MuiChipsInput } from 'mui-chips-input'
import { mdiMagnify } from "@mdi/js";

export interface SearchSet {
  label?: string,
  terms: string[],
  filterType: string
}

interface Props {
  searchSet?: SearchSet;
  onChange?: (searchSet: SearchSet) => void;
}

const SearchBox = ({ searchSet, onChange }: Props): ReactElement => {
  const { classes } = useStyles();
  const [terms, setTerms] = useState<any[]>([]);
  const [filterType, setFilterType] = useState("and");

  useEffect(()=>{
    if(searchSet){
      setFilterType(searchSet.filterType);
      setTerms(searchSet.terms);
    }
  },[searchSet])


  const handleChange = (newChips: string[]) => {
    setTerms(newChips);
    if(onChange){
      onChange({terms: newChips, filterType: filterType});
    }
  }

  // const handleAdd = (chip: any) =>{
  //   const clonedTerms = [...terms];
  //   clonedTerms.push(chip);
  //   setTerms(clonedTerms);
  //   if(onChange){
  //     onChange({terms: clonedTerms, filterType: filterType});
  //   }
  // }
  //
  // const handleDelete = (chip: any, index: number) => {
  //   const clonedTerms = [...terms];
  //   clonedTerms.splice(terms.indexOf(chip), 1);
  //   setTerms(clonedTerms);
  //   if(onChange){
  //     onChange({terms: clonedTerms, filterType: filterType});
  //   }
  // }

  const handleFilterTypeChange = (e: any) => {
    setFilterType(e.target.value);
    if(onChange){
      onChange({terms: terms, filterType: e.target.value});
    }
  }

  return (
    <div className={classes.container}>
      <SvgIcon className={classes.icon}><path d={mdiMagnify} /></SvgIcon>
      <MuiChipsInput
        className={classes.chipInput}
        value={terms}
        onChange={handleChange}
        fullWidth={true}
        variant={"standard"}
        placeholder={"Search for products..."}
        margin={"dense"}
        InputProps={{
           disableUnderline: true
        }}
      />
      <Select
        className={classes.filterSelect}
        disableUnderline={true}
        value={filterType}
        onChange={handleFilterTypeChange}
        variant={"standard"}
      >
        <MenuItem value={"or"}><span className={classes.expandedText}>Match </span>Any</MenuItem>
        <MenuItem value={"and"}><span className={classes.expandedText}>Match </span>All</MenuItem>
      </Select>
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => {
  return {
    container: {
      display: "flex",
      alignItems: "center",
      borderStyle: "solid",
      borderWidth: 1,
      borderRadius: 16,
      borderColor: theme.palette.secondary.light
    },
    icon: {
      marginLeft: 10,
      marginRight: 10
    },
    filterSelect:{
      fontSize: 12,
      color: theme.palette.secondary.dark,
      marginRight: 10
    },
    chipInput:{
      margin: 0,
      padding: 0,
      [theme.breakpoints.down('sm')]: {
        width: 235
      }
    },
    expandedText: {
      [theme.breakpoints.down('sm')]: {
        display: "none"
      }
    }
  };
});

export default SearchBox;
