interface TestId {
	'data-testid'?: string;
	id?: string;
}

export const getTestDataAttr = (id?: string): TestId | undefined => {
	if (!id) {
		return undefined;
	}
	// if ('prod' === process.env.REACT_APP_ENVIRONMENT) return {};
	return {
		'data-testid': id
	};
};
