import { useQuery } from '@apollo/client';
import React, { ReactElement, useEffect } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';

import ErrorContainer from '../../components/block/errorContainer';
import { useFriendlyKeyHelper } from '../../core/hooks/useFriendlyKeyHelper';
import MainContainer from '../../core/MainContainer';
import { useCartStoreDispatch } from '../../core/providers/storeProvider/storeProvider';
import { useUIStoreDispatch } from '../../core/providers/uiProvider/uiProvider';
import { setStoreId } from '../../core/stores/cart/action';
import { setCheckOption, setCreditCardOption } from '../../core/stores/ui/action';
import { GetGroupByIdDocument } from '../../generated/graphql';
import OrderSuccess from '../orderSuccess';
import ClosedStore from '../store/components/closedStore';
import GroupCart from './groupCart';
import GroupCatalog from './groupCatalog';
import Product from './product';
import Showcase from './showcase';

const Group = (): ReactElement => {
	const { groupId } = useParams();
	const uiDispatch = useUIStoreDispatch();
	const dispatch = useCartStoreDispatch();
	const variables = useFriendlyKeyHelper(groupId!);
	const { data, error, called, loading } = useQuery(GetGroupByIdDocument, { variables });

	useEffect(() => {
		if (data?.team_store?.[0]) {
			uiDispatch(setCreditCardOption(data.team_store[0].enable_credit_card_payment));
			uiDispatch(setCheckOption(data.team_store[0].enable_check_payment));
		}
	}, [data, uiDispatch]);

	useEffect(() => {
		if (data && called && !error) {
			dispatch(setStoreId(data.team_store[0].id));
		}
	}, [data, called, error, dispatch]);

	const teamStore = data?.team_store?.[0];

	if (error || (!loading && !teamStore)) {
		return (
			<ErrorContainer
				title="Incorrect Group ID"
				description="It looks like the group ID you are using is incorrect. Please check the link again or contact us at
					support@breakmark.com"
			/>
		);
	}

	if (teamStore?.status === 'closed') {
		return <ClosedStore ignoreCompletedOrders storeName={teamStore?.name} />;
	}

	if (groupId && data && teamStore) {
		return (
			<MainContainer>
				<Routes>
					<Route path={'success'} element={<OrderSuccess storeId={groupId} />} />
					<Route path={'success/:orderId'} element={<OrderSuccess storeId={groupId} />} />
					<Route path={'cart'} element={<GroupCart storeData={teamStore} />} />
					<Route path={'showcase/:showcaseId'} element={<Showcase />} />
					<Route
						path={'showcase/:showcaseId/product/:productId'}
						element={<Product cartId={data.team_store[0].id} />}
					/>
					<Route path={'showcase/:showcaseId/:searchIndexId'} element={<Showcase />} />
					<Route path={'product/:productId'} element={<Product cartId={data.team_store[0].id} />} />
					<Route path={'/'} element={<GroupCatalog storeData={teamStore} />} />
				</Routes>
			</MainContainer>
		);
	} else {
		return <></>;
	}
};

export default Group;
