import { useCartStore } from '../providers/storeProvider/storeProvider';
import { Package, Product } from '../stores/cart/action';

export interface CartItems {
	storeId?: string;
	products?: (Product | Package)[];
}

export const useCartItemsForStore = (): CartItems => {
	const cartItems = useCartStore();

	if (!cartItems.currentStoreId) {
		return { storeId: undefined, products: undefined };
	}

	const products = cartItems?.storeData[cartItems.currentStoreId] ? cartItems.storeData[cartItems.currentStoreId] : [];

	return {
		storeId: cartItems.currentStoreId,
		products: products.filter((p) => p) // ended up with a product of [null], this will filter that out.
	};
};
