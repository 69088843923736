import { Action } from '../common/action';
export enum CartAction {
	SET_STORE,
	CLEAR_STORE_CART,
	ADD_NEW_PRODUCT,
	EDIT_PRODUCT,
	REMOVE_PRODUCT_FROM_CART,
	ADD_LABEL
}

export interface ProductBase {
	label: string;
	id: string;
	price: number;
	image: string;
	order_for?: string;
}

export interface OptionValue {
	availableOptionId: string; // abd-asdc-asdc
	availableOptionValueId: string; // abd-asdc-asdc
	priceModifiedAs: number; // 0
	value: string; // Medium
	label: string; // Size
	imageUrl?: string; // https://www.gogle.com
}

export interface Product extends ProductBase {
	type: 'product';
	_sortProductId?: string;
	custom_name?: string;
	custom_number?: string;
	options: {
		[key: string]: OptionValue;
	};
	quantity: number;
}

export interface Package extends ProductBase {
	type: 'package';
	products: Product[];
}

export interface ProductReducer {
	item: Package | Product;
	storeId: string;
}

export const setAddNewProduct = (storeId: string, item: Package | Product): Action<CartAction, ProductReducer> => {
	return {
		data: {
			item,
			storeId
		},
		type: CartAction.ADD_NEW_PRODUCT
	};
};

export interface EditCartProduct extends ProductReducer {
	removeIndex: number;
	item: Package | Product;
	storeId: string;
}

export const editCartProduct = (
	storeId: string,
	item: Package | Product,
	removeIndex: number
): Action<CartAction, EditCartProduct> => {
	return {
		data: {
			removeIndex,
			item,
			storeId
		},
		type: CartAction.EDIT_PRODUCT
	};
};

export interface LabelCartItem {
	storeId: string;
	labelIndex: number;
	orderFor: string;
}

export const labelCartItem = (storeId: string, labelIndex: number, orderFor: string): Action<CartAction, LabelCartItem> => {
	return {
		data: {
			storeId,
			labelIndex,
			orderFor
		},
		type: CartAction.ADD_LABEL
	};
};

export interface RemoveProductReducer {
	storeId: string;
	productIndex: number;
}

export const removeProductFromList = (storeId: string, productIndex: number): Action<CartAction, RemoveProductReducer> => {
	return {
		data: {
			productIndex,
			storeId
		},
		type: CartAction.REMOVE_PRODUCT_FROM_CART
	};
};

export const setStoreId = (storeId: string): Action<CartAction, string> => {
	return {
		data: storeId,
		type: CartAction.SET_STORE
	};
};

export const clearStoreCart = (storeId: string): Action<CartAction, string> => {
	return {
		data: storeId,
		type: CartAction.CLEAR_STORE_CART
	};
};
