import { Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';
import React, { ElementType, ReactElement } from 'react';

import { getRemFromPx } from '../../../core/config/theme';

interface Props {
	className?: string;
	children: any;
	component?: ElementType;
}

const P3 = (props: Props): ReactElement => {
	const { classes } = useStyles();

	return (
		<Typography
			component={props.component ? props.component : 'p'}
			className={clsx(classes.p3, props.className ? props.className : null)}
		>
			{props.children}
		</Typography>
	);
};

const useStyles = makeStyles()((theme: Theme) => {
	return {
		p3: {
			textShadow: 'none',
			fontSize: getRemFromPx(14),
			lineHeight: getRemFromPx(19),
			[theme.breakpoints.up('md')]: {
				fontSize: getRemFromPx(14),
				lineHeight: getRemFromPx(18)
			}
		}
	};
});

export default P3;
