import { Avatar, SvgIcon, Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { mdiHammerWrench } from '@mdi/js';
import React, { ReactElement } from 'react';

import P4 from '../../../../components/typography/p4';

interface Props {
	storeName: string;
}

const StagingStore = (props: Props): ReactElement => {
	const { classes } = useStyles();

	return (
		<div className={classes.container}>
			<Typography className={classes.title} variant="h2">
				Hang Tight!
			</Typography>
			<Avatar className={classes.avatar}>
				<SvgIcon>
					<path d={mdiHammerWrench} />
				</SvgIcon>
			</Avatar>
			<P4 className={classes.title}>
				We're building {props.storeName} out right now. Check back in a bit and we'll have the store live for you!
			</P4>
		</div>
	);
};

const useStyles = makeStyles()((theme: Theme) => {
	return {
		container: {
			maxWidth: 1100,
			marginLeft: 'auto',
			marginRight: 'auto',
			textAlign: 'center',
			marginBottom: '40px'
		},
		avatar: {
			marginLeft: 'auto',
			marginRight: 'auto',
			backgroundColor: '#199cdb',
			width: 80,
			height: 80,
			'& svg': {
				width: 60,
				height: 60
			}
		},
		title: {
			marginTop: '30px',
			marginBottom: '30px'
		},
		backToOrders: {
			marginTop: 20,
			textAlign: 'center',
			width: '100%'
		}
	};
});

export default StagingStore;
