import { useQuery } from '@apollo/client';
import { Theme, Tooltip } from '@mui/material';
import React, { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { GetShowcasesByGroupIdDocument } from '../../../generated/graphql';

interface Props {
	groupId: string;
	showcases: any;
}

const ShowcaseLogos = ({ groupId, showcases }: Props): ReactElement => {
	const { classes } = useStyles();

	const { data } = useQuery(GetShowcasesByGroupIdDocument, {
		variables: { storeId: groupId }
	});

	const getMonogram = (showcase: any) => {
		const title = showcase.label || showcase.teamStoreByStoreShowcasingId.name;
		const words = title.split(' ');
		if (words.length > 1) {
			return (words[0].substring(0, 1) + words[1].substring(0, 1)).toUpperCase();
		} else {
			return title.substring(0, 2).toUpperCase();
		}
	};

	return (
		<div className={classes.container}>
			{data?.showcase?.map((si) => {
				return (
					<Tooltip key={si.id} title={si.label || si.teamStoreByStoreShowcasingId.name}>
						<NavLink
							className={classes.logo}
							to={`/group/${si.team_store.friendly_url_key || si.team_store.id}/showcase/${
								si.friendly_url_key || si.id
							}`}
						>
							{si.image_url ? (
								<img width={'100%'} src={si.image_url} alt={si.label || si.teamStoreByStoreShowcasingId.name} />
							) : (
								<div className={classes.monogram}>{getMonogram(si)}</div>
							)}
						</NavLink>
					</Tooltip>
				);
			})}
		</div>
	);
};

const useStyles = makeStyles()((theme: Theme) => {
	return {
		container: {
			display: 'flex',
			flexWrap: 'wrap',
			alignItems: 'center',
			justifyContent: 'center',
			margin: 15
		},
		logo: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			width: 100,
			height: 100,
			borderRadius: 50,
			backgroundColor: theme.palette.primary.dark,
			overflow: 'hidden',
			margin: 10,
			cursor: 'pointer',
			[theme.breakpoints.down('sm')]: {
				width: 48,
				height: 48,
				borderRadius: 24
			}
		},
		monogram: {
			color: theme.palette.primary.contrastText,
			textDecoration: 'none',
			fontWeight: 'bold'
		}
	};
});

export default ShowcaseLogos;
