import { TextField, TextFieldProps, Theme } from '@mui/material';
import React, { ReactElement } from 'react';
import { makeStyles } from 'tss-react/mui';

import { SYSTEM_COLORS } from '../../../core/config/colors';

const InputField = (props: TextFieldProps): ReactElement => {
	const { inputProps, ...rest } = props;
	const { classes } = useStyles();

	return (
		<TextField
			autoComplete="off"
			classes={{
				root: classes.root
			}}
			{...rest}
			inputProps={{
				autoCorrect: 'off',
				autoCapitalize: 'none',
				className: classes.inputClass,
				...inputProps
			}}
		/>
	);
};

export default InputField;

const useStyles = makeStyles()((theme: Theme) => {
	return {
		root: {
			borderRadius: 8,
			backgroundColor: '#fff',
			'&:hover': {
				backgroundColor: '#fff'
			},
			'& .MuiInputBase-input': {
				borderRadius: 8
			},
			'& .MuiOutlinedInput-root': {
				borderRadius: 8
			},
			'& .MuiOutlinedInput-multiline': {
				padding: 0
			},
			'& .MuiOutlinedInput-multiline textarea': {
				padding: '16px 14px'
			},
			'& .Mui-disabled': {
				color: SYSTEM_COLORS.GRAY_SHADE
			}
		},
		inputClass: {
			fontSize: 16,
			borderRadius: 8
		}
	};
});
