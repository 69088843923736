import { useMutation } from '@apollo/client';
import { CardActionArea, DialogContent, InputAdornment, Link, Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { ReactElement, useState } from 'react';
import QRCode from 'react-qr-code';

import DialogActionBar from '../../../../components/block/dialogActionBar';
import DialogBase from '../../../../components/block/dialogBase';
import DialogTitleHeader from '../../../../components/block/dialogTitleHeader';
import NumberFormatCustom from '../../../../components/block/numberFormatCustom';
import InputField from '../../../../components/inputs/inputField';
import { SYSTEM_COLORS } from '../../../../core/config/colors';
import { cleanNumberFormat } from '../../../../core/utilites/currency';
import { CreateCustomLinkDocument } from '../../../../generated/graphql';

interface Props {
	open: boolean;
	handleClose(): void;
	productId: string;
}

const DialogCreateLink = (props: Props): ReactElement => {
	const { classes } = useStyles();
	const [price, setPrice] = useState('');
	const [saveOption, { data, loading }] = useMutation(CreateCustomLinkDocument, {
		variables: {
			productId: props.productId,
			productPrice: Math.round(parseFloat(cleanNumberFormat(price || '0')) * 100)
		}
	});

	return (
		<DialogBase open={props.open} closeDialog={props.handleClose}>
			<DialogTitleHeader closeDialog={props.handleClose} title="Create a Link" />
			<DialogContent>
				{!data?.createCustomLink?.url && (
					<div className={classes.priceContainer}>
						<InputField
							autoFocus
							variant="outlined"
							fullWidth
							label="Price"
							onChange={(e): void => {
								setPrice(e.target.value);
							}}
							name="base_price"
							value={price}
							InputProps={{
								inputComponent: NumberFormatCustom,
								startAdornment: (
									<InputAdornment position="start" className={classes.adornment}>
										<strong>$</strong>
									</InputAdornment>
								)
							}}
						/>
					</div>
				)}
				{data?.createCustomLink?.url && (
					<div className={classes.QRCodeContainer}>
						<CardActionArea component={Link} href={data?.createCustomLink?.url} target="_blank">
							<Typography variant="h4" className={classes.urlInput}>
								{data?.createCustomLink?.url}
							</Typography>
						</CardActionArea>
						<QRCode value={data?.createCustomLink?.url} size={250} />
					</div>
				)}
			</DialogContent>
			{!data?.createCustomLink?.url && (
				<DialogActionBar
					disableSave={loading || parseInt(price, 10) <= 0}
					saveAction={saveOption}
					saveLabel="Create"
					cancelAction={props.handleClose}
				/>
			)}
			{data?.createCustomLink?.url && <DialogActionBar cancelLabel="Close" cancelAction={props.handleClose} />}
		</DialogBase>
	);
};

const useStyles = makeStyles()((theme: Theme) => {
	return {
		adornment: {
			color: SYSTEM_COLORS.PRIMARY
		},
		priceContainer: {
			textAlign: 'center',
			padding: 40
		},
		QRCodeContainer: {
			textAlign: 'center',
			marginLeft: 'auto',
			marginRight: 'auto'
		},
		urlInput: {
			marginBottom: 15
		}
	};
});

export default DialogCreateLink;
