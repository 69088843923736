import { mdiChevronDown } from '@mdi/js';
import {
	Collapse,
	DialogActions,
	DialogContent,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	SvgIcon,
	Theme,
	Typography
} from '@mui/material';
import clsx from 'clsx';
import React, { ReactElement, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import DialogTitleHeader from '../../../../components/block/dialogTitleHeader';
import PrimaryButton from '../../../../components/buttons/primaryButton';
import P3 from '../../../../components/typography/p3';
import { SYSTEM_COLORS } from '../../../../core/config/colors';
import { useCartStoreDispatch } from '../../../../core/providers/storeProvider/storeProvider';
import { Package, Product as StoreProduct, editCartProduct, setAddNewProduct } from '../../../../core/stores/cart/action';
import { formatCurrencyValueFromServer } from '../../../../core/utilites/currency';
import { getTestDataAttr } from '../../../../utilities/testHelpers';
import Product from '../product';
import { PriceModifier } from '../product/product';
import { FormattedProduct } from '../productList/productDef';
import { getDefaultPackages, getDefaultPriceModifiers, useCalculateTotalPrice } from './packageFormUtil';

interface Props {
	editProductIndex?: number;
	editProduct?: Package;
	storeId: string;
	products: FormattedProduct[];
	packageLabel: string;
	packageId: string;
	packagePrice: number;
	packageImage: string;
	handleClose(): void;
	discountType?: 'percent' | 'dollar';
	discountRate?: number;
}

interface PackageProductState {
	[key: string]: StoreProduct;
}

const PackageForm = (props: Props): ReactElement => {
	const { classes } = useStyles();
	const { products, packagePrice } = props;
	const [activeStep, setActiveStep] = React.useState(0);
	const [mobileMenu, setMobileMenu] = useState(false);
	const dispatch = useCartStoreDispatch();

	// If we are editing a product, lets prime state
	const defaultPriceModifiers = getDefaultPriceModifiers(props.editProduct);
	const defaultProductPackages = getDefaultPackages(props.editProduct);
	// end

	const [packageProducts, setPackageProducts] = useState<PackageProductState>(defaultProductPackages);
	const [priceModifiers, setPriceModifiers] = useState<PriceModifier>(defaultPriceModifiers);
	const totalPrice = useCalculateTotalPrice(packagePrice, priceModifiers);

	const handleNext = (): void => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = (): void => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const activeStepLabel = products[activeStep] ? products[activeStep].label : 'Review';

	return (
		<>
			<DialogTitleHeader title={props.packageLabel} subLabel={`Price: ${formatCurrencyValueFromServer(totalPrice)}`} />
			<DialogContent className={classes.content}>
				<div className={classes.menu}>
					<div className={classes.mobileList}>
						<ListItem
							className={classes.toggleMenuButton}
							role={'button'}
							onClick={(): void => {
								setMobileMenu(!mobileMenu);
							}}
						>
							<ListItemText>{activeStepLabel}</ListItemText>
							<ListItemIcon>
								<SvgIcon className={clsx(classes.chevron, mobileMenu ? classes.chevronFlip : null)}>
									<path d={mdiChevronDown} />
								</SvgIcon>
							</ListItemIcon>
						</ListItem>

						<Collapse in={mobileMenu}>
							<List className={classes.list}>
								{products.map((product, index) => {
									return (
										<ListItem
											selected={index === activeStep}
											button
											key={index}
											disabled={activeStep < index && !Boolean(packageProducts[product.id])}
											onClick={(): void => {
												setMobileMenu(false);
												setActiveStep(index);
											}}
										>
											<ListItemText>{product.label}</ListItemText>
										</ListItem>
									);
								})}
								<ListItem
									onClick={(): void => {
										setMobileMenu(false);
										setActiveStep(products.length);
									}}
									button
									disabled={activeStep < products.length}
									selected={activeStep === products.length}
								>
									<ListItemText>Review</ListItemText>
								</ListItem>
							</List>
						</Collapse>
					</div>

					<List className={clsx(classes.list, classes.desktopOnly)}>
						{products.map((product, index) => {
							return (
								<ListItem
									selected={index === activeStep}
									button
									key={index}
									disabled={activeStep < index && !Boolean(packageProducts[product.id])}
									onClick={(): void => {
										setActiveStep(index);
									}}
								>
									<ListItemText>{product.label}</ListItemText>
								</ListItem>
							);
						})}
						<ListItem
							onClick={(): void => {
								setActiveStep(products.length);
							}}
							button
							disabled={activeStep < products.length}
							selected={activeStep === products.length}
						>
							<ListItemText>Review</ListItemText>
						</ListItem>
					</List>
				</div>
				{products.map((product, index) => {
					return (
						<Product
							disableQuantityField
							productLabel={product.label || ''}
							editProduct={props.editProduct?.products.find((prod) => prod.id === product.id)}
							discountType={props.discountType}
							discountRate={props.discountRate}
							ignoreNegativeValue
							key={index}
							setPriceModifiers={(modifiers: PriceModifier) => {
								setPriceModifiers({
									...priceModifiers,
									...modifiers
								});
							}}
							hide={activeStep !== index}
							previousDisabled={activeStep === 0}
							handlePrevious={handleBack}
							successLabel="Next"
							handleCancel={props.handleClose}
							product={product}
							setProduct={(p: StoreProduct) => {
								setPackageProducts({
									...packageProducts,
									[p.id]: p
								});
								handleNext();
							}}
						/>
					);
				})}

				{activeStep === products.length && (
					<>
						<div className={classes.review}>
							{Object.values(packageProducts).map((pkg) => {
								const { label, image, id, options } = pkg;
								const { custom_name, custom_number, ...rest } = options;

								return (
									<div className={classes.item} key={id}>
										<img src={image} alt={`${label}`} className={classes.reviewImage} />
										<Typography variant="body1">{label}</Typography>
										<div>
											{Object.values(rest).map((item) => {
												return (
													<P3 key={item.availableOptionId}>
														<strong>{item.label}</strong>: {item.value ? item.value : ' '}
													</P3>
												);
											})}
											{custom_name && (
												<P3>
													<strong>Custom Name</strong>: {custom_name ? custom_name : ' '}
												</P3>
											)}
											{custom_number !== undefined && (
												<P3>
													<strong>Custom Number</strong>: {custom_number ? custom_number : ' '}
												</P3>
											)}
										</div>
									</div>
								);
							})}
						</div>
					</>
				)}
			</DialogContent>
			{activeStep === products.length && (
				<DialogActions className={classes.addAction}>
					<PrimaryButton buttonSizeOverride="small" variant="outlined" onClick={props.handleClose}>
						Cancel
					</PrimaryButton>
					<PrimaryButton
						{...getTestDataAttr('add-to-cart')}
						buttonSizeOverride="small"
						variant="contained"
						onClick={(): void => {
							if (props.editProduct && props.editProductIndex !== undefined) {
								dispatch(
									editCartProduct(
										props.storeId,
										{
											type: 'package',
											id: props.packageId,
											label: props.packageLabel,
											image: props.packageImage,
											price: totalPrice,
											products: Object.values(packageProducts)
										},
										props.editProductIndex
									)
								);
							} else {
								dispatch(
									setAddNewProduct(props.storeId, {
										type: 'package',
										id: props.packageId,
										label: props.packageLabel,
										image: props.packageImage,
										price: totalPrice,
										products: Object.values(packageProducts)
									})
								);
							}
							props.handleClose();
						}}
					>
						{props.editProduct ? 'Update Cart' : 'Add to Cart'}
					</PrimaryButton>
				</DialogActions>
			)}
		</>
	);
};

const useStyles = makeStyles()((theme: Theme) => {
	return {
		content: {
			flex: 1,
			padding: '8px 24px 8px 0',
			display: 'flex',
			[theme.breakpoints.down('sm')]: {
				flexDirection: 'column',
				paddingLeft: 15,
				paddingRight: 15,
				paddingTop: 0,
				paddingBottom: 0
			}
		},
		menu: {
			maxWidth: 200,
			borderRight: `1px solid ${SYSTEM_COLORS.DIVIDER}`,
			marginRight: 15,
			[theme.breakpoints.down('sm')]: {
				maxWidth: 'unset',
				marginBottom: 15,
				width: '100%',
				marginRight: 0,
				borderRight: 'none',
				borderBottom: `1px solid ${SYSTEM_COLORS.DIVIDER}`
			}
		},
		productContainer: {
			flex: 1,
			display: 'flex'
		},
		list: {
			paddingTop: 0
		},
		actions: {
			justifyContent: 'space-between',
			marginLeft: 230,
			[theme.breakpoints.down('sm')]: {
				marginLeft: 0,
				padding: 20,
				borderTop: `1px solid ${SYSTEM_COLORS.DIVIDER}`
			}
		},
		navActions: {
			'& :not(:first-child)': {
				marginLeft: 8
			}
		},
		flex: {
			display: 'flex',
			flex: 1
		},
		review: {
			flex: 1,
			textAlign: 'center',
			display: 'grid',
			gridTemplateColumns: 'repeat(2, 1fr)'
		},
		reviewImage: {
			width: 150,
			height: 150
		},
		item: {
			padding: 15
		},
		addAction: {
			padding: '16px 24px',
			[theme.breakpoints.down('md')]: {
				display: 'flex',
				justifyContent: 'space-between',
				padding: 15,
				borderTop: `1px solid ${SYSTEM_COLORS.DIVIDER}`
			}
		},
		mobileList: {
			display: 'none',
			[theme.breakpoints.down('sm')]: {
				display: 'block'
			}
		},
		desktopOnly: {
			[theme.breakpoints.down('sm')]: {
				display: 'none'
			}
		},
		toggleMenuButton: {
			cursor: 'pointer'
		},
		chevron: {
			color: SYSTEM_COLORS.PRIMARY,
			transition: 'all .3s'
		},
		chevronFlip: {
			'-webkit-transform': 'rotate(180deg)',
			'-moz-transform': 'rotate(180deg)',
			'-ms-transform': 'rotate(180deg)',
			'-o-transform': 'rotate(180deg)',
			transform: 'rotate(180deg)'
		}
	};
});

export default PackageForm;
