import { Theme } from '@mui/material';
import React, { ReactElement, useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { formatCurrencyValueFromServer, handleStoreDiscount } from '../../core/utilites/currency';
import { ProductByIdFragment } from '../../generated/graphql';
import { CustomizationModel } from './customizationModel';
import { OptionModel } from './optionModel';

interface Props {
	style?: React.CSSProperties;
	product: ProductByIdFragment;
	options: OptionModel[];
	discountRate: number;
	discountType?: 'dollar' | 'percent';
	onChange: (price: number) => void;
	customization: CustomizationModel;
}

interface BasePriceOptions {
	basePrice: number;
	customPrice?: number;
}
const getBasePrice = ({ basePrice, customPrice }: BasePriceOptions) => {
	return customPrice && customPrice > 0 ? customPrice : basePrice || 0;
};

const ProductPrice = ({ style, product, options, discountRate, discountType, onChange, customization }: Props): ReactElement => {
	const { classes } = useStyles();
	const [price, setPrice] = useState(0);

	useEffect(() => {
		const p = getBasePrice({
			basePrice: product.product.base_price || 0,
			customPrice: product.custom_price_override || 0
		});
		setPrice(p);
	}, [product, options]);

	useEffect(() => {
		const p = getBasePrice({
			basePrice: product.product.base_price || 0,
			customPrice: product.custom_price_override || 0
		});
		const baselinePrice = handleStoreDiscount({
			discountRate: discountRate,
			type: discountType,
			incomingValue: p
		});
		let modifyBy = 0;
		options.forEach((opt) => {
			if (opt.priceModifiedAs && opt.priceModifiedAs !== 0) {
				const modifiedPrice = handleStoreDiscount({
					allowNegativeValues: true,
					discountRate: discountRate,
					type: discountType,
					incomingValue: opt.priceModifiedAs
				});
				modifyBy = modifyBy + modifiedPrice;
			}
		});

		if (product.custom_name_price && customization.name.length > 0) {
			const modifiedPrice = handleStoreDiscount({
				allowNegativeValues: true,
				discountRate: discountRate,
				type: discountType,
				incomingValue: product.custom_name_price
			});
			modifyBy = modifyBy + modifiedPrice;
		}
		if (product.custom_number_price && customization.number.length > 0) {
			const modifiedPrice = handleStoreDiscount({
				allowNegativeValues: true,
				discountRate: discountRate,
				type: discountType,
				incomingValue: product.custom_number_price
			});
			modifyBy = modifyBy + modifiedPrice;
		}

		setPrice(baselinePrice + modifyBy);
	}, [product, options, setPrice, discountRate, discountType, customization]);

	useEffect(() => {
		onChange(price);
	}, [price, onChange]);

	return (
		<div className={classes.price} style={style}>
			<h2>{formatCurrencyValueFromServer(price)}</h2>
		</div>
	);
};

const useStyles = makeStyles()((theme: Theme) => {
	return {
		price: {
			backgroundColor: theme.palette.grey['100'],
			paddingLeft: 10,
			paddingRight: 15,
			borderRadius: 4,
			marginTop: 5
		}
	};
});

export default ProductPrice;
