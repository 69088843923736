import React, { ReactElement, useState } from 'react';

import { StorePackageFragment } from '../../../../generated/graphql';
import PackageDialog from '../packageDialog';
import PackageItem from '../packageItem';

interface Props {
	discountType?: 'percent' | 'dollar';
	discountRate?: number;
	storeId: string;
	packages: StorePackageFragment[];
}

const PackageList = (props: Props): ReactElement => {
	// line below simply enables us to toggle type of modal design we want
	const [cardState, setCardState] = useState<StorePackageFragment | undefined>();

	return (
		<>
			{props.packages.map((pkg, index): ReactElement | null => {
				if (!pkg.custom_price) {
					return null;
				}
				return <PackageItem setCardState={setCardState} key={pkg.id} index={index} pkg={pkg} />;
			})}
			{cardState && (
				<PackageDialog
					discountRate={props.discountRate}
					discountType={props.discountType}
					handleClose={(): void => setCardState(undefined)}
					open={Boolean(cardState)}
					pkg={cardState}
					storeId={props.storeId}
				/>
			)}
		</>
	);
};
export default PackageList;
