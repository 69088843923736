import { useQuery } from '@apollo/client';
import { Theme } from '@mui/material';
import { format, parseISO } from 'date-fns';
import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { formatCurrencyValueFromServer } from '../../core/utilites/currency';
import { GetOrderByPkDocument } from '../../generated/graphql';

const convertItemForMailingReceipt = (orderItem: any) => {
	const desc = orderItem.order_item_options.map((item: any) => {
		return `${item.available_option.name}: ${item.value ? item.value : ''}`;
	});
	if (orderItem.custom_name) {
		desc.push(`Custom Name: ${orderItem.custom_name ? orderItem.custom_name : ''}`);
	}
	if (orderItem.custom_number) {
		desc.push(`Custom Number: ${orderItem.custom_number ? orderItem.custom_number : ''}`);
	}
	return desc.filter((a: any) => a).join(', ');
};

export const getOrderDetails = (details: any[], storeId: string): any[] => {
	return details.map((item, index) => {
		const border = index < details.length - 1 ? 'true' : '';

		const addtImage = item.store_product.additional_image_list;
		console.log('addtImage', addtImage);

		return {
			imageUrl: addtImage.length > 0 ? addtImage[0].url : item.image_url,
			label: item.store_product.label,
			description: convertItemForMailingReceipt(item),
			price: `${formatCurrencyValueFromServer(item.total_price || 0)}`,
			quantity: item.quantity,
			border
		};
	});
};

export const getOrderPackageDetails = (packages: any[], storeId: string): any[] => {
	const flatPackageMap = packages
		.map((item) => {
			const { order_package } = item;

			return order_package.order_items.map((orderItem: any, index: any) => {
				return {
					quantity: 1,
					imageUrl: orderItem.image_url || order_package.image_url,
					label: `${order_package.store_package.label} - ${orderItem.store_product.label}`,
					description: convertItemForMailingReceipt(orderItem),
					price: index === 0 ? `${formatCurrencyValueFromServer(order_package.total_price || 0)}` : '--'
				};
			});
		})
		.flat();

	return flatPackageMap;
};

interface ParsedOrderOptions {
	products?: any[];
	packages?: any[];
}

export const parseOrders = (orders: ParsedOrderOptions, storeId: string): any[] => {
	const packageDetails = orders.packages ? getOrderPackageDetails(orders.packages, storeId) : [];
	const productDetails = orders.products ? getOrderDetails(orders.products, storeId) : [];

	const combined = [...packageDetails, ...productDetails];
	return combined.map((item, index) => {
		const border = index < combined.length - 1 ? 'true' : '';
		return {
			...item,
			border
		};
	});
};

const PackagingSlip = (): ReactElement => {
	const { id } = useParams();
	const { data } = useQuery(GetOrderByPkDocument, {
		variables: { id }
	});

	const { classes } = useStyles();
	if (!data) {
		return <div>Error - no order found</div>;
	}

	const orderData = parseOrders(
		{
			packages: data?.packages?.order_items,
			products: data?.products?.order_items
		},
		id!
	);
	const date = parseISO(data?.details?.created_at);

	// Example of formatting to a readable string like 'December 12, 2023, 3:09 AM'
	const readableDate = format(date, 'MMMM d, yyyy, h:mm a');

	return (
		<div className={classes.container}>
			<div className={classes.header}>
				<div>
					<strong>Order: #{data?.details?.order_readable_column}</strong>
				</div>
				<div>{data?.details?.customer_name}</div>
				<div>Date: {readableDate}</div>
			</div>

			<h2>Order Summary</h2>

			{orderData.map((order, k) => {
				return (
					<div key={k} className={classes.itemContainer}>
						<img src={order.imageUrl} alt="Order Item" className={classes.image} />

						<div className={classes.orderInfo}>
							<div className={classes.item}>
								<span className={classes.label}>{order.label}</span>
							</div>
							<div className={classes.item}>Qty: {order.quantity}</div>
							<div className={classes.item}>{order.description}</div>
						</div>
					</div>
				);
			})}
		</div>
	);
};

const useStyles = makeStyles()((theme: Theme) => {
	return {
		container: {
			fontFamily: 'Arial, sans-serif',
			maxWidth: '600px',
			margin: 'auto',
			padding: '20px',
			textAlign: 'center'
		},
		header: {
			borderBottom: '2px solid #000',
			paddingBottom: '10px',
			marginBottom: '20px'
		},
		image: {
			width: '150px',
			height: '150px',
			objectFit: 'cover',
			margin: '0 auto 20px',
			borderRadius: 15,
			border: '1px solid #41414141'
		},
		orderInfo: {
			marginLeft: 25,
			flex: 1,
			textAlign: 'left',
			marginBottom: '20px'
		},
		item: {
			textAlign: 'left',
			padding: '10px 0',
			borderBottom: '1px solid #ccc'
		},
		label: {
			fontWeight: 'bold'
		},
		itemContainer: {
			display: 'flex',
			alignItems: 'center'
		}
	};
});

export default PackagingSlip;
