import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';

import { Theme } from '@mui/material';
import clsx from 'clsx';
import React, { ReactElement, useEffect, useState } from 'react';
import InnerImageZoom from 'react-inner-image-zoom';
import { makeStyles } from 'tss-react/mui';

import { ProductByIdFragment } from '../../generated/graphql';
import { AdditionalImage } from '../store/components/productList/productDef';
import Ribbon from '../store/components/productList/ribbon';
import { OptionModel } from './optionModel';

interface Props {
	style?: React.CSSProperties;
	product: ProductByIdFragment;
	options: OptionModel[];
}

const ProductImages = ({ style, product, options }: Props): ReactElement => {
	const { classes } = useStyles();
	const [images, setImages] = useState<string[]>([]);
	const [selectedImage, setSelectedImage] = useState<string>();

	useEffect(() => {
		if (product && product.display_photo) {
			const additionalImages: AdditionalImage[] = product.additional_image_list || [];

			const imagesFromProduct = [...additionalImages]
				.sort((a, b) => {
					return a.index - b.index;
				})
				.map((item) => item.url);

			setImages([product.display_photo, ...imagesFromProduct]);
			setSelectedImage(product.display_photo);
		}
	}, [product, setImages, setSelectedImage]);

	useEffect(() => {
		options.forEach((opt) => {
			if (opt.image_url && opt.image_url.length > 0) {
				const optImages = [opt.image_url];
				if (opt.secondary_image_url && opt.secondary_image_url.length > 0) {
					optImages.push(opt.secondary_image_url);
				}
				setImages(optImages);
				setSelectedImage(optImages[0]);
			}
		});
	}, [options, setImages, setSelectedImage]);
	const isDisabled = product.enforce_limit_qty && product.limit_order_qty - product.qty_purchased_calc <= 0;
	return (
		<div className={classes.images} style={style}>
			{selectedImage ? (
				<div className={classes.imageBox}>
					{/*<img src={selectedImage} alt={"Product"} /> */}
					{product.enforce_limit_qty && product.limit_order_qty - product.qty_purchased_calc <= 4 && (
						<Ribbon
							color={isDisabled ? 'red' : 'green'}
							label={isDisabled ? 'Sold Out' : `${product.limit_order_qty - product.qty_purchased_calc} Left!`}
						/>
					)}
					{isDisabled && <div className={classes.disabledOverlay} />}
					<InnerImageZoom src={selectedImage} zoomSrc={selectedImage} />
				</div>
			) : null}

			{images.length > 1 ? (
				<div className={classes.imageStrip}>
					{images.map((i, index) => (
						<div
							key={index}
							className={clsx(classes.imageSelector, i === selectedImage ? classes.selected : null)}
							onClick={() => setSelectedImage(i)}
						>
							<img alt={'Product'} src={i} />
						</div>
					))}
				</div>
			) : null}
		</div>
	);
};

const useStyles = makeStyles()((theme: Theme) => {
	return {
		images: {
			maxWidth: 450,
			position: 'relative',
			margin: 15
		},
		imageBox: {
			img: {
				maxWidth: '100%'
			}
		},
		imageStrip: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			padding: 10
		},
		imageSelector: {
			width: 130,
			borderWidth: 1,
			borderStyle: 'solid',
			margin: 5,
			borderColor: theme.palette.primary.light,
			cursor: 'pointer',
			img: {
				maxWidth: '100%'
			}
		},
		selected: {
			borderWidth: 2,
			borderColor: theme.palette.primary.dark
		},
		disabledOverlay: {
			position: 'absolute',
			top: 0,
			left: 0,
			bottom: 0,
			right: 0,
			backgroundColor: 'rgba(0,0,0,.50)',
			zIndex: 1200
		}
	};
});

export default ProductImages;
