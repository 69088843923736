import React, { ReactElement, ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Dialog, Theme, useMediaQuery, useTheme } from '@mui/material';
import clsx from 'clsx';
// import { TransitionProps } from "@mui/material/transitions";

interface Props {
	fullScreen?: boolean;
	open: boolean;
	children: ReactNode;
	closeDialog?(): void;
	containerClass?: string;
	style?: React.CSSProperties;
}

// const Transition = React.forwardRef(function Transition(
// 	props: TransitionProps & { children?: React.ReactElement<any, any> },
// 	ref: React.Ref<unknown>
// ) {
// 	return <Slide direction="up" ref={ref} {...props} children={} />;
// });

const DialogBase = (props: Props): ReactElement => {
	const { classes } = useStyles();
	const theme = useTheme();
	const isFullscreen = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<Dialog
			style={props.style}
			onClose={props.closeDialog}
			fullScreen={props.fullScreen ? props.fullScreen : isFullscreen}
			open={props.open}
			classes={{ paper: clsx(classes.paperContainer, props.containerClass) }}
		>
			{props.children}
		</Dialog>
	);
};

const useStyles = makeStyles()((theme: Theme) => {
	return {
		paperContainer: {
			[theme.breakpoints.up('md')]: {
				minWidth: 780,
				maxWidth: 780,
				minHeight: 550
			}
		}
	};
});

export default DialogBase;
