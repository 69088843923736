import { CardActionArea, Theme } from '@mui/material';
import clsx from 'clsx';
import React, { ReactElement, useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import P5 from '../../../../../../components/typography/p5';
import { SYSTEM_COLORS, getRGBAFromHex } from '../../../../../../core/config/colors';
import { getProductOptionLabel } from '../../../../../../core/utilites/currency';
import { getTestDataAttr } from '../../../../../../utilities/testHelpers';
import { ProductOption, ProductOptionList } from '../../../productList/productDef';

interface Props {
	selectedOptionId?: string;
	productOptionList: ProductOptionList;
	handleSelectedOption(option: ProductOption): void;
	ignoreNegativeValue?: boolean;
	discountType?: 'percent' | 'dollar';
	discountRate?: number;
	selectedOptions?: any;
	removedValues?: string[];
	testId?: string;
}

const ImageOption = (props: Props): ReactElement => {
	const { classes } = useStyles();
	const [selectedOption, setSelectedOption] = useState<ProductOption | undefined>(undefined);
	const { productOptionList } = props;

	useEffect(() => {
		if (props.selectedOptionId) {
			const foundOption = productOptionList.options.find((productOption) => productOption.id === props.selectedOptionId);
			if (foundOption) {
				setSelectedOption(foundOption);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (selectedOption) {
			props.handleSelectedOption(selectedOption);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedOption]);

	return (
		<div {...getTestDataAttr(props.testId)}>
			<P5>
				{productOptionList.label}:{' '}
				<strong>
					{selectedOption
						? `${getProductOptionLabel({
								name: selectedOption.label,
								discountRate: props.discountRate,
								discountType: props.discountType,
								ignorePriceModifier: props.ignoreNegativeValue,
								price: `${selectedOption.priceModifier || 0}`
						  })} `
						: 'Select an option'}
				</strong>
			</P5>
			<div className={classes.imageSelector}>
				{productOptionList.options.map((option: ProductOption, i: number) => {
					return (
						<div
							key={option.id}
							className={clsx(
								classes.itemContainer,
								option.id === selectedOption?.id ? classes.itemSelected : null
							)}
						>
							<CardActionArea
								classes={{
									root: classes.imageHover
								}}
								onClick={(): void => {
									setSelectedOption(option);
								}}
							>
								{option.imageUrl && (
									<img alt={option.label} className={classes.imageThumbnails} src={option.imageUrl} />
								)}
								{!option.imageUrl && <div className={classes.noImage}>{option.label}</div>}
							</CardActionArea>
						</div>
					);
				})}
			</div>
		</div>
	);
};

const useStyles = makeStyles()((theme: Theme) => {
	return {
		imageSelector: {
			marginTop: 8,
			display: 'flex',
			flexWrap: 'wrap'
		},
		imageThumbnails: {
			width: 75,
			height: 75
		},
		noImage: {
			fontSize: 12,
			width: 75,
			height: 75,
			textAlign: 'center',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center'
		},
		itemContainer: {
			border: '2px solid transparent',
			'&:hover': {
				borderColor: getRGBAFromHex(SYSTEM_COLORS.PRIMARY, 0.5)
			}
		},
		itemSelected: {
			borderColor: SYSTEM_COLORS.PRIMARY
		},
		imageHover: {
			border: '2px solid transparent',
			'& .MuiCardMedia-root': {
				backgroundSize: 'contain'
			}
		}
	};
});

export default ImageOption;
