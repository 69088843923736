import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';

import { findEnvAttr } from '../utilites/process';

export function createApolloClient() {
	const link = new HttpLink({
		uri: findEnvAttr('REACT_APP_APOLLO_ENDPOINT')
	});

	return new ApolloClient({
		link,
		cache: new InMemoryCache()
	});
}
