import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { ReactElement } from 'react';

import { TeamStoreOrderFragment } from '../../../../generated/graphql';
import OrderDetails from '../orderDetails';

interface Props {
	filterOptions: string[];
	orderList: TeamStoreOrderFragment[];
}

const OrderTable = (props: Props): ReactElement => {
	const { classes } = useStyles();
	return (
		<div className={classes.container}>
			{props.orderList.map((order) => {
				return <OrderDetails key={order.id} order={order} filterOptions={props.filterOptions} />;
			})}
		</div>
	);
};

const useStyles = makeStyles()((theme: Theme) => {
	return {
		container: {},
		orderContainer: {
			display: 'flex',
			flexWrap: 'wrap'
		}
	};
});

export default OrderTable;
