import React, { ReactElement, useState } from "react";
import {
  Button, SvgIcon,
  TextField, Dialog, DialogTitle,
  Theme, DialogContent, DialogActions
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useSnackbar } from "notistack";
import { mdiSend } from "@mdi/js";
import { useMutation } from "@apollo/client";
import { AuthSendDocument } from "../../../generated/graphql";

interface Props {
  open: boolean;
  onClose: () => void;
}

const EmailDialog = ({ open, onClose }: Props): ReactElement => {
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [email, setEmail] = useState<string | null>(localStorage.getItem("email"));
  const [authSend] = useMutation(AuthSendDocument);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    localStorage.setItem("email", event.target.value);
  };

  const handleSend = async () => {
    try {
      const { data, errors } = await authSend({ variables: { email: email } });
      if (!data || errors) {
        console.error(errors);
        enqueueSnackbar("Login Email Send Failed", { variant: "error" });
      }
      if (!data?.authSend?.status) {
        console.error("server said", data?.authSend);
        enqueueSnackbar("Login Email Send Failed", { variant: "error" });
      } else {
        enqueueSnackbar("Login Email Sent!", { variant: "success" });
      }
      onClose();
    } catch (e) {
      console.error(e);
      enqueueSnackbar("Login Email Send Failed", { variant: "error" });
    }
  };

  return (
    <Dialog onClose={onClose} open={open} maxWidth={"sm"}>
      <DialogTitle>Send the Sign-In Link</DialogTitle>
      <DialogContent>
        <div className={classes.emailButtonBox}>
          <TextField
            size={"small"}
            label={"Email Address"}
            variant={"outlined"}
            fullWidth={true}
            value={email}
            onChange={handleEmailChange}
            className={classes.emailField}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          endIcon={<SvgIcon>
            <path d={mdiSend} />
          </SvgIcon>}
          onClick={handleSend}
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );

};

const useStyles = makeStyles()((theme: Theme) => {
  return {
    container: {
      marginRight: 20
    },
    emailButtonBox: {
      display: "flex",
      flexDirection: "column"
    },
    emailField: {
      marginTop: 15
    }
  };
});

export default EmailDialog;
