export const SYSTEM_COLORS = {
	BLACK_SHADE: '#252733',
	PRIMARY: '#7F47DD',
	SECONDARY: '#DDE2FF',
	GRAY: '#9FA2B4',
	GRAY_SHADE: '#363740',
	GRAY_LIGHT: '#9FA2B4',
	GRASCALE_BLACK: '#252733',
	GRASCALE_GRAY_DARK: '#4B506D',
	GRAYSCALE_GRAY_LIGHTEST: '#F0F1F7',
	DIVIDER: 'rgb(242,242,245)',
	RED_DEFAULT: '#F12B2C',
	GREEN_DEFAULT: '#1fa77a'
};

export interface Hex {
	r: number;
	g: number;
	b: number;
}

export const hexToRgb = (hex: string): Hex => {
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	if (!result) {
		return {
			r: 25,
			g: 144,
			b: 84
		};
	}
	return {
		r: parseInt(result[1], 16),
		g: parseInt(result[2], 16),
		b: parseInt(result[3], 16)
	};
};

export const hexToRgbString = (hex: string): string => {
	const rgb = hexToRgb(hex);
	return `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`;
};

export const rgbToHex = (rgb: string): string => {
	const rgbArray = rgb.replace(/[^\d,]/g, '').split(',');
	return '#' + ((1 << 24) + (+rgbArray[0] << 16) + (+rgbArray[1] << 8) + +rgbArray[2]).toString(16).slice(1);
};

export const getRGBAFromHex = (hex: string, alpha: number): string => {
	const { r, g, b } = hexToRgb(hex);
	return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

// https://stackoverflow.com/a/3943023/123389
export const getContrastColorTextFromHex = (hex: string): string => {
	const { r, g, b } = hexToRgb(hex);
	if (r * 0.299 + g * 0.587 + b * 0.114 > 186) {
		return '#000000';
	}
	return '#ffffff';
};
