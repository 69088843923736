import React, { ReactElement, ReactNode, useCallback } from 'react';
import { useLocalStorage } from 'react-use';

import { CartAction } from '../../stores/cart/action';
import { CartModel, CartReducer, cartReducerDefaultState } from '../../stores/cart/reducer';
import { Action } from '../../stores/common/action';

const LOCAL_STORAGE_KEY = 'B_CART_STORE';

const StoreContext = React.createContext<CartModel>({
	storeData: {}
});
// eslint-disable-next-line @typescript-eslint/no-empty-function
const DispatchContext = React.createContext<React.Dispatch<Action<CartAction, any>>>(() => {});

interface Options {
	children: ReactNode;
}

export const CartStoreProvider = (options: Options): ReactElement => {
	// const [store, dispatch] = React.useReducer(CartReducer, {
	// 	...initState
	// });

	const [savedState, saveState] = useLocalStorage(LOCAL_STORAGE_KEY, cartReducerDefaultState);
	// wrap `reducer` with a memoized function that
	// syncs the `newState` to `localStorage` before
	// returning `newState`. memoizing is important!
	const reducerLocalStorage = useCallback(
		(state: CartModel, action: Action<CartAction, any>) => {
			const newState = CartReducer(state, action);

			saveState(newState);

			return newState;
		},
		[saveState]
	);

	const [store, dispatch] = React.useReducer(reducerLocalStorage, savedState || cartReducerDefaultState);

	return (
		<DispatchContext.Provider value={dispatch}>
			<StoreContext.Provider value={store}>{options.children}</StoreContext.Provider>
		</DispatchContext.Provider>
	);
};

export const useCartStore = (): CartModel => {
	return React.useContext(StoreContext);
};
export const useCartStoreDispatch = (): React.Dispatch<Action<CartAction, any>> => {
	return React.useContext(DispatchContext);
};

export default CartStoreProvider;
