import PropTypes from 'prop-types';
import React from 'react';
import { NumericFormat } from 'react-number-format';

const NumberFormatCustom = (props: any) => {
	const { inputRef, onChange, ...other } = props;
	return (
		<NumericFormat
			getInputRef={inputRef}
			onValueChange={(values: any) => {
				onChange({
					target: {
						value: values.value
					}
				});
			}}
			thousandSeparator
			decimalScale={2}
			isAllowed={(values: any) => {
				const { formattedValue, floatValue } = values;
				return formattedValue === '' || floatValue >= 0;
			}}
			{...other}
		/>
	);
};

NumberFormatCustom.propTypes = {
	inputRef: PropTypes.func,
	onChange: PropTypes.func,
	isAllowed: PropTypes.func
};

export default NumberFormatCustom;
