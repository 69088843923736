import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';
import React, { ReactElement } from 'react';

interface Props {
	label: string;
	color: 'red' | 'green';
}

const Ribbon = ({ label, color }: Props): ReactElement => {
	const { classes } = useStyles();

	return (
		<div
			className={clsx(classes.ribbon, {
				[classes.red]: color === 'red'
			})}
		>
			<span className={classes.inner}>{label}</span>
		</div>
	);
};

const useStyles = makeStyles()((theme: Theme) => {
	return {
		ribbon: {
			position: 'absolute',
			right: -5,
			top: -5,
			zIndex: 1201,
			overflow: 'hidden',
			width: 75,
			height: 75,
			textAlign: 'right'
		},
		inner: {
			fontSize: 10,
			color: '#fff',
			textTransform: 'uppercase',
			textAlign: 'center',
			fontWeight: 'bold',
			lineHeight: '20px',
			transform: 'rotate(45deg)',
			width: 100,
			display: 'block',
			background: 'linear-gradient(#9BC90D 0%, #79A70A 100%)',
			boxShadow: '0 3px 10px -5px rgba(0, 0, 0, 1)',
			position: 'absolute',
			top: 19,
			right: -21,
			'&:before': {
				content: "''",
				position: 'absolute',
				left: 0,
				top: '100%',
				zIndex: -1,
				borderLeft: '3px solid #79A70A',
				borderRight: '3px solid transparent',
				borderBottom: '3px solid transparent',
				borderTop: ' 3px solid #79A70A'
			},
			'&:after': {
				content: "''",
				position: 'absolute',
				right: 0,
				top: '100%',
				zIndex: -1,
				borderRight: '3px solid #79A70A',
				borderLeft: '3px solid transparent',
				borderBottom: '3px solid transparent',
				borderTop: ' 3px solid #79A70A'
			}
		},
		red: {
			'& span': {
				background: 'linear-gradient(#F70505 0%, #8F0808 100%)',
				'&:after': {
					borderRightColor: '#8F0808',
					borderTopColor: '#8F0808'
				},
				'&:before': {
					borderLeftColor: '#8F0808',
					borderTopColor: '#8F0808'
				}
			}
		}
	};
});

export default Ribbon;
