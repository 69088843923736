import React, { Dispatch, ReactNode, SetStateAction, useEffect } from 'react';
import { decodeToken, isExpired } from 'react-jwt';

export interface ContextProps {
	token: string | null;
	setToken: Dispatch<SetStateAction<string | null>>;
	user?: AuthUser;
}

const AuthContext = React.createContext<ContextProps>({ user: undefined, token: null, setToken: () => {} });

export interface AuthUser {
	email?: string;
	name?: string;
	sub?: string;
	picture?: string;
	user_id?: string;
}

interface Props {
	children: ReactNode;
	authToken: string | null;
}

export const AuthProvider = ({ authToken, children }: Props) => {
	let [token, setToken] = React.useState<string | null>(authToken);
	let [user, setUser] = React.useState<AuthUser | undefined>();

	useEffect(() => {
		if (token) {
			localStorage.setItem('authToken', token);
			if (!isExpired(token)) {
				const decoded = decodeToken<AuthUser>(token);
				if (decoded) {
					setUser(decoded);
				}
			}
		} else {
			localStorage.removeItem('authToken');
			setUser(undefined);
		}
	}, [token]);

	return <AuthContext.Provider value={{ user, token, setToken }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => React.useContext(AuthContext);
