import { Paper, Theme } from '@mui/material';
import React, { ReactElement, useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { useCartStoreDispatch } from '../../core/providers/storeProvider/storeProvider';
import { Product as CartProduct, setAddNewProduct } from '../../core/stores/cart/action';
import AddProductDialog from '../product/addProductDialog';
import ProductPanel from '../product/productPanel';

interface Props {
	cartId: string;
}

const Product = (props: Props): ReactElement => {
	const { classes } = useStyles();
	const { storeId } = useParams();
	const dispatch = useCartStoreDispatch();
	const [cartProduct, setCartProduct] = useState<CartProduct>();

	const handleProductAction = (product: CartProduct) => {
		dispatch(setAddNewProduct(props.cartId, product));
		setCartProduct(product);
	};

	if (storeId) {
		return (
			<div className={classes.content}>
				<Paper className={classes.main}>
					<ProductPanel action={handleProductAction} />
				</Paper>
				<AddProductDialog onClose={() => setCartProduct(undefined)} product={cartProduct} />
			</div>
		);
	} else {
		return <div></div>;
	}
};

const useStyles = makeStyles()((theme: Theme) => {
	return {
		container: {},
		content: {
			flex: 1
		},
		main: {
			padding: 15,
			overflow: 'hidden',
			flex: 1
		},
		navBar: {
			width: 240,
			marginRight: 15
		},
		title: {
			width: '100%',
			padding: 10,
			textAlign: 'center'
		},
		banner: {
			margin: -15,
			marginBottom: 5
		}
	};
});

export default Product;
