import { SvgIcon, Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { mdiLabel, mdiPencil, mdiTrashCan } from '@mdi/js';
import React, { ReactElement } from 'react';

import PrimaryButton from '../../../../../../components/buttons/primaryButton';
import SecondaryButton from '../../../../../../components/buttons/secondaryButton';
import P3 from '../../../../../../components/typography/p3';
import { SYSTEM_COLORS, getRGBAFromHex } from '../../../../../../core/config/colors';
import { CartItems } from '../../../../../../core/hooks/useCartItemsForStore';
import { useCartStoreDispatch } from '../../../../../../core/providers/storeProvider/storeProvider';
import { Package, Product, removeProductFromList } from '../../../../../../core/stores/cart/action';
import { formatCurrencyValueFromServer } from '../../../../../../core/utilites/currency';
import Detail from '../../../../../orders/components/detail';

interface Props {
	storeId: string;
	cartContents: CartItems;
	handleEdit?(item: Product | Package, index: number): void;
	handleLabel(item: Product | Package, index: number): void;
}

const CartItemList = (props: Props): ReactElement => {
	const { cartContents } = props;
	const { classes } = useStyles();
	const dispatch = useCartStoreDispatch();

	return (
		<div className={classes.orders}>
			{cartContents.products &&
				cartContents.products.map((cartItem, i): ReactElement | null => {
					if (!cartItem) {
						return null;
					}

					const quantity = cartItem.type === 'product' ? parseInt(`${cartItem.quantity}`, 10) : 1;

					return (
						<div className={classes.cartItem} key={`${cartItem.id}-${i}`}>
							{cartItem.image && (
								<div className={classes.imageContainer}>
									<img src={cartItem.image} alt={cartItem.label} />
								</div>
							)}
							<div className={classes.productDetails}>
								<div className={classes.product}>
									<Typography variant="h3">{cartItem.label}</Typography>
									<div className={classes.priceAnnotation}>
										<Typography variant="h3" className={classes.price}>
											{formatCurrencyValueFromServer((quantity > 0 ? quantity : 1) * cartItem.price)}
										</Typography>
										{cartItem.order_for && <P3>Ordered For: {cartItem.order_for}</P3>}
									</div>
								</div>
								<div className={classes.options}>
									<P3 component={'div'}>
										<Detail detailClassName={classes.detailClassName} orderItem={cartItem} />
									</P3>
									<div className={classes.buttonContainer}>
										{props.handleEdit !== undefined ?
											<PrimaryButton
												onClick={(): void => {
													if(props.handleEdit) props.handleEdit(cartItem, i);
												}}
												size="small"
												variant="outlined"
												startIcon={
													<SvgIcon>
														<path d={mdiPencil} />
													</SvgIcon>
												}
											>
												Edit
											</PrimaryButton>
											:null}
										<PrimaryButton
											onClick={(): void => {
												dispatch(removeProductFromList(props.storeId, i));
											}}
											variant="outlined"
											startIcon={
												<SvgIcon>
													<path d={mdiTrashCan} />
												</SvgIcon>
											}
										>
											Remove
										</PrimaryButton>
										<SecondaryButton
											onClick={(): void => {
												props.handleLabel(cartItem, i);
											}}
											variant="contained"
											startIcon={
												<SvgIcon>
													<path d={mdiLabel} />
												</SvgIcon>
											}
										>
											Label Order
										</SecondaryButton>
									</div>
								</div>
							</div>
						</div>
					);
				})}
			{(!cartContents.products || (cartContents.products && cartContents.products.length === 0)) && (
				<div className={classes.noItems}>Add a few items to checkout</div>
			)}
		</div>
	);
};

const useStyles = makeStyles()((theme: Theme) => {
	return {
		orders: {
			flex: 1
		},
		imageContainer: {
			height: 200,
			width: 172,
			padding: 10,
			border: '1px solid rgb(242,242,245)',
			borderRadius: 8,
			'& img': {
				width: 150,
				borderRadius: 8
			},
			[theme.breakpoints.down('sm')]: {
				marginLeft: 'auto',
				marginRight: 'auto'
			}
		},
		product: {
			display: 'flex',
			justifyContent: 'space-between',
			[theme.breakpoints.down('xs')]: {
				display: 'block'
			}
		},

		cartItem: {
			alignItems: 'flex-start',
			display: 'flex',
			padding: '15px 0',
			borderBottom: `1px solid ${getRGBAFromHex(SYSTEM_COLORS.PRIMARY, 0.2)}`,
			'&:last-of-type': {
				borderBottom: 'none'
			},
			[theme.breakpoints.down('sm')]: {
				display: 'block'
			}
		},
		price: {
			fontSize: 16
		},
		noItems: {
			textAlign: 'center',
			width: '100%',
			marginTop: 50
		},

		options: {
			flex: 1
		},
		detailClassName: {
			display: 'grid',
			gridTemplateColumns: 'repeat(1, 1fr)',
			gap: 5
		},
		productDetails: {
			flex: 1,
			paddingLeft: 15,
			paddingRight: 15,
			display: 'grid',
			gridTemplateColumns: 'repeat(1, 1fr)',
			gap: '10px',
			[theme.breakpoints.down('md')]: {
				marginTop: 15
			}
		},
		buttonContainer: {
			'& :not(:first-child)': {
				marginLeft: 8,
				[theme.breakpoints.down('sm')]: {
					marginLeft: 0
				}
			},
			marginTop: 25,
			[theme.breakpoints.down('sm')]: {
				display: 'grid',
				gridTemplateColumns: 'repeat(1, 1fr)',
				gap: 5
			}
		},
		priceAnnotation: {
			textAlign: 'right'
		}
	};
});

export default CartItemList;
