import { Theme } from '@mui/material';
import React, { ReactElement } from 'react';
import { makeStyles } from 'tss-react/mui';

import P3 from '../../../../components/typography/p3';
import { SYSTEM_COLORS } from '../../../../core/config/colors';
import { Package, Product } from '../../../../core/stores/cart/action';

interface Props {
	detailClassName?: string;
	orderItem: Package | Product;
}

interface LineItemProps {
	className: string;
	label: string;
	value: string;
	withLabel?: boolean;
}

const LineItem = (props: LineItemProps) => {
	// return (
	// 	<span className={props.className}>
	// 		<strong>{props.label}</strong>: {props.value}
	// 	</span>
	// );

	// if (props.withLabel) {
	return (
		<div className={props.className}>
			{props.label}: {props.value}
		</div>
	);
	// }

	// return <div className={props.className}>{props.value}</div>;
};

const Detail = (props: Props): ReactElement | null => {
	const { classes } = useStyles();
	const { orderItem } = props;

	if (!orderItem.type) {
		return null;
	}
	if (orderItem.type === 'package') {
		const optDesc = orderItem.products.map((product, i) => {
			const { custom_name, custom_number, quantity, ...rest } = product.options;
			const desc = Object.values(rest).map((item) => {
				return (
					<LineItem
						value={item.value ? item.value : ' '}
						label={item.label}
						className={classes.itemDetail}
						key={item.availableOptionId}
					/>
				);
			});
			if (custom_name !== undefined) {
				desc.push(
					<LineItem
						withLabel
						value={custom_name ? `${custom_name}` : ' '}
						label={'Custom Name'}
						className={classes.itemDetail}
						key={`custom-name-${i}`}
					/>
				);
			}
			if (custom_number !== undefined) {
				desc.push(
					<LineItem
						withLabel
						value={custom_number ? `${custom_number}` : ' '}
						label={'Custom Number'}
						className={classes.itemDetail}
						key={`custom-number-${i}`}
					/>
				);
			}
			if (product.custom_name !== undefined) {
				desc.push(
					<LineItem
						withLabel
						value={product.custom_name ? product.custom_name : ' '}
						label={'Custom Name'}
						className={classes.itemDetail}
						key={`custom-name-${i}`}
					/>
				);
			}
			if (product.custom_number !== undefined) {
				desc.push(
					<LineItem
						withLabel
						value={product.custom_number ? product.custom_number : ' '}
						label={'Custom Number'}
						className={classes.itemDetail}
						key={`custom-number-${i}`}
					/>
				);
			}
			return (
				<div className={classes.packageProduct} key={`pack_${product.id}`}>
					<P3 className={classes.label}>
						<strong>{product.label}</strong>
					</P3>
					<div className={props.detailClassName}>{<>{desc.filter((a) => a)}</>}</div>
				</div>
			);
		});
		return <>{optDesc.filter((a) => a)}</>;
	} else if (orderItem.type === 'product') {
		const { custom_name, custom_number, ...rest } = orderItem.options;
		const desc = Object.values(rest).map((item) => {
			return (
				<LineItem
					value={item.value ? item.value : ' '}
					label={item.label}
					className={classes.itemDetail}
					key={item.availableOptionId}
				/>
			);
		});
		if (custom_name !== undefined) {
			desc.push(
				<LineItem
					withLabel
					value={custom_name ? `${custom_name}` : ' '}
					label={'Custom Name'}
					className={classes.itemDetail}
					key={`custom-name-${orderItem.id}`}
				/>
			);
		}
		if (custom_number !== undefined) {
			desc.push(
				<LineItem
					withLabel
					value={custom_number ? `${custom_number}` : ' '}
					label={'Custom Number'}
					className={classes.itemDetail}
					key={`custom-number-${orderItem.id}`}
				/>
			);
		}
		if (orderItem.custom_name !== undefined) {
			desc.push(
				<LineItem
					withLabel
					value={orderItem.custom_name ? orderItem.custom_name : ' '}
					label={'Custom Name'}
					className={classes.itemDetail}
					key={`custom-name-${orderItem.id}`}
				/>
			);
		}
		if (orderItem.custom_number !== undefined) {
			desc.push(
				<LineItem
					withLabel
					value={orderItem.custom_number ? orderItem.custom_number : ' '}
					label={'Custom Number'}
					className={classes.itemDetail}
					key={`custom-number-${orderItem.id}`}
				/>
			);
		}
		if (orderItem.quantity !== undefined) {
			desc.push(
				<LineItem
					withLabel
					value={orderItem.quantity ? `${orderItem.quantity}` : '1'}
					label={'Quantity'}
					className={classes.itemDetail}
					key={`quantity-${orderItem.id}`}
				/>
			);
		}

		return <div className={props.detailClassName}>{desc.filter((a) => a)}</div>;
	}

	return null;
};

const useStyles = makeStyles()((theme: Theme) => {
	return {
		label: {
			borderBottom: `1px solid ${SYSTEM_COLORS.DIVIDER}`,
			marginBottom: 10
		},
		packageProduct: {
			padding: '10px 0'
		},
		itemDetail: {
			// marginRight: 5
		}
	};
});

export default Detail;
