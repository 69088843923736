import { Action } from '../common/action';

export enum UIAction {
	SET_CHECK_OPTION,
	SET_CREDIT_CARD_OPTION
}

export const setCheckOption = (enabled: boolean): Action<UIAction, boolean> => {
	return {
		data: enabled,
		type: UIAction.SET_CHECK_OPTION
	};
};
export const setCreditCardOption = (enabled: boolean): Action<UIAction, boolean> => {
	return {
		data: enabled,
		type: UIAction.SET_CREDIT_CARD_OPTION
	};
};
