import { useQuery } from '@apollo/client';
import { Card, CardActionArea, CardContent, CardMedia, Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { ReactElement, useMemo } from 'react';

import P5 from '../../../../components/typography/p5';
import { formatCurrencyValueFromServer } from '../../../../core/utilites/currency';
import { GetPackageProductsByIdDocument, StorePackageFragment } from '../../../../generated/graphql';
import { getTestDataAttr } from '../../../../utilities/testHelpers';

interface Props {
	setCardState(pkg: StorePackageFragment): void;
	pkg: StorePackageFragment;
	index: number;
}

const PackageItem = (props: Props): ReactElement => {
	const { classes } = useStyles();
	const { pkg, index } = props;
	const { data } = useQuery(GetPackageProductsByIdDocument, {
		variables: {
			_in: pkg.product_ids
		}
	});

	const basePrice = useMemo(() => {
		if (data) {
			return data.store_product.reduce((current, nextVal) => {
				return current + (nextVal.product.base_price ? nextVal.product.base_price : 0);
			}, 0);
		}
		return undefined;
	}, [data]);

	return (
		<Card>
			<CardActionArea
				classes={{
					root: classes.rootCardAction
				}}
				{...getTestDataAttr(`pkg-card-${index}`)}
				onClick={(): void => {
					props.setCardState(pkg);
				}}
			>
				{pkg.photo_url && <CardMedia image={pkg.photo_url} className={classes.media} />}
				<CardContent>
					<Typography gutterBottom variant="h5" component="h2">
						{pkg.label}
					</Typography>
					<P5>{formatCurrencyValueFromServer(pkg.custom_price)}</P5>
					{basePrice && basePrice > pkg.custom_price && (
						<P5 className={classes.strikeOut}>
							Originally <span>{formatCurrencyValueFromServer(basePrice)}</span>
						</P5>
					)}
				</CardContent>
			</CardActionArea>
		</Card>
	);
};

const useStyles = makeStyles()((theme: Theme) => {
	return {
		media: {
			height: 260
		},
		rootCardAction: {
			'& .MuiCardMedia-root': {
				backgroundSize: 'contain'
			}
		},
		strikeOut: {
			'& span': {
				textDecoration: 'line-through'
			}
		}
	};
});

export default PackageItem;
