import { Theme, CssBaseline } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { ReactElement, ReactNode } from 'react';
import CartStoreProvider from './providers/storeProvider/storeProvider';
import UIProvider from './providers/uiProvider';

interface Props {
	children: ReactNode;
}

const AppContainer = (props: Props): ReactElement => {
	const { classes } = useStyles();

	return (
		<UIProvider>
			<CartStoreProvider>
				<div className={classes.root}>
					<CssBaseline />
					{props.children}
				</div>
			</CartStoreProvider>
		</UIProvider>
	);
};

const useStyles = makeStyles()((theme: Theme) => {
	return {
		root: {
			display: 'flex',
			flex: 1
		},
	};
});

export default AppContainer;
