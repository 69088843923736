import { useQuery } from '@apollo/client';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';

import ErrorContainer from '../../components/block/errorContainer';
import { useFriendlyKeyHelper } from '../../core/hooks/useFriendlyKeyHelper';
import MainContainer from '../../core/MainContainer';
import { useCartStoreDispatch } from '../../core/providers/storeProvider/storeProvider';
import { useUIStoreDispatch } from '../../core/providers/uiProvider/uiProvider';
import { setStoreId } from '../../core/stores/cart/action';
import { setCheckOption, setCreditCardOption } from '../../core/stores/ui/action';
import { GetStoreContentsDocument } from '../../generated/graphql';
import Orders from '../orders';
import OrderSuccess from '../orderSuccess';
import Cart from './pages/cart';
import StoreProducts from './pages/storeProducts';
import Product from './product';

interface Props {}

const Store = (props: Props): ReactElement => {
	const { storeId } = useParams();
	const navigate = useNavigate();
	const uiDispatch = useUIStoreDispatch();
	const dispatch = useCartStoreDispatch();
	const variables = useFriendlyKeyHelper(storeId!);
	const { data, error, called, loading } = useQuery(GetStoreContentsDocument, {
		variables
	});

	useEffect(() => {
		if (data?.team_store?.[0]) {
			uiDispatch(setCreditCardOption(data?.team_store?.[0].enable_credit_card_payment));
			uiDispatch(setCheckOption(data?.team_store?.[0].enable_check_payment));
		}
	}, [data, uiDispatch]);

	useEffect(() => {
		if (called && !error && data?.team_store?.[0]) {
			dispatch(setStoreId(data.team_store[0].id));
		}
	}, [called, error, data, dispatch]);

	const storeData = data?.team_store?.[0];

	const [discountRate, discountType] = useMemo((): [number | undefined, 'dollar' | 'percent' | undefined] => {
		const rate = storeData?.store_discount_rate ? storeData?.store_discount_rate : undefined;
		const type =
			storeData?.store_discount_type &&
			(storeData?.store_discount_type === 'dollar' || storeData?.store_discount_type === 'percent')
				? storeData?.store_discount_type
				: undefined;

		return [rate, type];
	}, [storeData?.store_discount_rate, storeData?.store_discount_type]);

	if (error || (!loading && !storeData)) {
		return (
			<ErrorContainer
				title="Incorrect Store ID"
				description="It looks like the store ID you are using is incorrect. Please check the link again or contact us at
					support@breakmark.com"
			/>
		);
	}

	// console.log('data.team_store', data.team_store_by_pk.use_as_catalog);
	if (storeData) {
		if (storeData.use_as_catalog) {
			navigate(`/group/${storeData.friendly_url_key || storeData.id}`);
		}
		return (
			<MainContainer>
				<Routes>
					<Route path={`orders`} element={<Orders storeId={storeData.id} />} />
					<Route path={'success'} element={<OrderSuccess storeId={storeData.id} />} />
					<Route path={'success/*'} element={<OrderSuccess storeId={storeData.id} />} />
					<Route path={'product/:productId'} element={<Product cartId={storeData.id} />} />
					<Route
						path={'cart'}
						element={<Cart storeData={storeData} discountRate={discountRate} discountType={discountType} />}
					/>
					<Route
						path={'/'}
						element={<StoreProducts storeData={storeData} discountRate={discountRate} discountType={discountType} />}
					/>
				</Routes>
			</MainContainer>
		);
	} else {
		return <></>;
	}
};

export default Store;
