import { useQuery } from '@apollo/client';
import { mdiMagnify } from '@mdi/js';
import { Collapse, Paper, SvgIcon, Theme } from '@mui/material';
import clsx from 'clsx';
import React, { ReactElement, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import ButtonBase from '../../components/buttons/buttonBase';
import PrimaryButton from '../../components/buttons/primaryButton';
import SecondaryButton from '../../components/buttons/secondaryButton';
import InputField from '../../components/inputs/inputField';
import { SYSTEM_COLORS } from '../../core/config/colors';
// import { PUBLIC_ROUTES } from '../../core/routes/publicRoutes';
import { GetTeamStoreOrdersDocument, TeamStoreOrderFragment } from '../../generated/graphql';
import ExportDialog from './components/exportDialog';
import Filter from './components/filter';
import OrderTable from './components/orderTable';
import Products from './components/products';

interface Props {
	storeId: string;
}
enum ButtonSelection {
	ORDER,
	PRODUCT
}

const Orders = (props: Props): ReactElement | null => {
	const { classes } = useStyles();
	const [exportData, setExportData] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');
	const [filterOptions, setFilterOptions] = useState<string[]>([]);
	const [selectedButton, setSelectedButton] = useState<ButtonSelection>(ButtonSelection.ORDER);
	const { data } = useQuery(GetTeamStoreOrdersDocument, {
		variables: {
			_eq: props.storeId
		}
	});

	const memoizedOrders = useMemo((): TeamStoreOrderFragment[] | undefined => {
		if (!data?.order) {
			return [];
		}
		if (searchTerm === '') {
			return data.order;
		}

		return data.order.filter((order) => {
			if (order.customer_name && order.customer_name.toLowerCase().includes(searchTerm.toLowerCase())) {
				return true;
			}
			return false;
		});
	}, [data?.order, searchTerm]);

	if (!memoizedOrders) {
		return null;
	}

	return (
		<div>
			<div className={classes.sectionSelector}>
				<div className={classes.buttonContainer}>
					<ButtonBase
						buttonSizeOverride="small"
						variant={selectedButton === ButtonSelection.ORDER ? 'contained' : 'outlined'}
						color={selectedButton === ButtonSelection.ORDER ? 'primary' : 'inherit'}
						className={classes.selectionOption}
						onClick={(): void => setSelectedButton(ButtonSelection.ORDER)}
					>
						By Order
					</ButtonBase>
					<ButtonBase
						buttonSizeOverride="small"
						variant={selectedButton === ButtonSelection.PRODUCT ? 'contained' : 'outlined'}
						color={selectedButton === ButtonSelection.PRODUCT ? 'primary' : 'inherit'}
						className={classes.selectionOption}
						onClick={(): void => setSelectedButton(ButtonSelection.PRODUCT)}
					>
						By Product
					</ButtonBase>
				</div>
				<div className={classes.buttonContainer}>
					<Filter storeId={props.storeId} filterOptions={filterOptions} setFilterOptions={setFilterOptions} />
					<InputField
						value={searchTerm}
						variant="outlined"
						InputProps={{
							startAdornment: (
								<SvgIcon className={clsx(classes.dialogIcons, classes.magnify)}>
									<path d={mdiMagnify} />
								</SvgIcon>
							)
						}}
						onChange={(e): void => {
							setSearchTerm(e.target.value || '');
						}}
						placeholder={`Search`}
						inputProps={{ 'aria-label': 'search' }}
					/>
				</div>
			</div>
			<Paper className={classes.paperContainer}>
				<Collapse in={selectedButton === ButtonSelection.ORDER} unmountOnExit>
					<OrderTable orderList={memoizedOrders} filterOptions={filterOptions} />
				</Collapse>
				<Collapse in={selectedButton === ButtonSelection.PRODUCT} unmountOnExit>
					<Products orderList={memoizedOrders} filterOptions={filterOptions} />
				</Collapse>
			</Paper>
			<div className={classes.backToOrders}>
				<PrimaryButton component={Link} to={`/store/${props.storeId}`}>
					Back to Store!
				</PrimaryButton>
				<SecondaryButton onClick={() => setExportData(true)}>Export Data</SecondaryButton>
			</div>
			{exportData && props.storeId && (
				<ExportDialog closeDialog={(): void => setExportData(false)} storeId={props.storeId} />
			)}
		</div>
	);
};

const useStyles = makeStyles()((theme: Theme) => {
	return {
		sectionSelector: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			paddingBottom: 20,
			[theme.breakpoints.down('sm')]: {
				display: 'block'
			}
		},
		buttonContainer: {
			display: 'flex',
			'& > :not(:first-child)': {
				marginLeft: 16
			},
			[theme.breakpoints.down('sm')]: {
				marginBottom: 10,
				justifyContent: 'flex-start'
			}
		},
		selectionOption: {
			width: 120
		},
		paperContainer: {
			padding: 16
		},
		dialogIcons: {
			color: SYSTEM_COLORS.GRAY
		},
		magnify: {
			marginTop: 2
		},
		backToOrders: {
			marginTop: 20,
			textAlign: 'center',
			width: '100%',
			'& > :not(:first-child)': {
				marginLeft: 16
			}
		}
	};
});

export default Orders;
