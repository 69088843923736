import { Theme, Typography } from '@mui/material';
import { formatDistance } from 'date-fns';
import React, { ReactElement, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import P3 from '../../../../components/typography/p3';
import P5 from '../../../../components/typography/p5';
import { SYSTEM_COLORS } from '../../../../core/config/colors';
import { OptionValue, Product } from '../../../../core/stores/cart/action';
import { TeamStoreOrderFragment } from '../../../../generated/graphql';
import Detail from '../detail';

interface Props {
	filterOptions: string[];
	orderList: TeamStoreOrderFragment[];
}

const Products = (props: Props): ReactElement => {
	const { classes } = useStyles();
	const products = useMemo(() => {
		return props.orderList
			.map((order) => {
				return order.order_items.map((item) => {
					return {
						_customerName: order.customer_name || '',
						_updatedAt: order.updated_at,
						id: item.store_product.id,
						image: item.image_url || '',
						label: item.store_product.label || '',
						price: item.total_price,
						custom_name: item.custom_name || undefined,
						quantity: 1,
						custom_number: item.custom_number || undefined,
						options: item.order_item_options.reduce((previous, opts) => {
							const optionValue: OptionValue = {
								availableOptionId: opts.available_option_id,
								availableOptionValueId: opts.available_option_value_id,
								label:
									opts.available_option.display_name && opts.available_option.display_name !== 'null'
										? opts.available_option.display_name
										: opts.available_option.name,
								priceModifiedAs: opts.price_modifier || 0,
								value: opts.value,
								imageUrl: opts.image_url || undefined
							};
							return {
								...previous,
								[opts.id]: optionValue
							};
						}, {})
					};
				});
			})
			.flat(2);
	}, [props.orderList]);

	return (
		<div>
			{products.map((product, index) => {
				const { _customerName, _updatedAt, ...rest } = product;
				const prod: Product = { ...rest, type: 'product' };

				if (props.filterOptions.length > 0 && !props.filterOptions.includes(product.id)) {
					return null;
				}

				return (
					<div key={index} className={classes.order}>
						<div>
							<Typography variant="body2">{_customerName}</Typography>
							<Typography variant="h6" className={classes.time}>
								Updated {formatDistance(new Date(_updatedAt), new Date())}
							</Typography>
						</div>
						<div className={classes.orderDetails}>
							<P5>{rest.label}</P5>
							<P3>
								<Detail orderItem={prod} />
							</P3>
						</div>
					</div>
				);
			})}
		</div>
	);
};

const useStyles = makeStyles()((theme: Theme) => {
	return {
		order: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			marginBottom: 15,
			paddingBottom: 10,
			borderBottom: `1px solid ${SYSTEM_COLORS.DIVIDER}`,
			'&:last-of-type': {
				borderBottom: 'none'
			}
		},
		time: {
			marginTop: 5,
			color: SYSTEM_COLORS.GRAY_LIGHT
		},
		orderDetails: {
			textAlign: 'right'
		}
	};
});

export default Products;
