import { mdiCart } from '@mdi/js';
import { AppBar, Button, SvgIcon, Theme, Toolbar } from '@mui/material';
import React, { useMemo } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import logo from '../../../images/BMLogo_Long_White_SM.png';
import { getTestDataAttr } from '../../../utilities/testHelpers';
import { SYSTEM_COLORS } from '../../config/colors';
import { useCartItemsForStore } from '../../hooks/useCartItemsForStore';
import { formatCurrencyValueFromServer } from '../../utilites/currency';
import ProfileMenu from '../auth/profileMenu';

interface Props {
	storeId?: string;
	groupId?: string;
}

export default function ButtonAppBar({ storeId, groupId }: Props) {
	const { classes } = useStyles();
	const navigate = useNavigate();
	const cartItems = useCartItemsForStore();

	const cartTotal = useMemo(() => {
		if (!cartItems.products || cartItems.products.length === 0) {
			return 0;
		}

		return cartItems.products.reduce((prev, cur) => {
			const quantity = cur.type === 'product' ? cur.quantity : 1;

			return prev + (quantity * cur?.price || 0);
		}, 0);
	}, [cartItems]);

	return (
		<div className={classes.root}>
			<AppBar position="static">
				<Toolbar className={classes.toolbar}>
					<div className={classes.headerContainer}>
						{groupId ? (
							<div onClick={() => navigate(`/group/${groupId}`)}>
								<img className={classes.img} src={logo} alt="breakmark logo"></img>
							</div>
						) : null}
						{storeId ? (
							<div onClick={() => navigate(`/store/${storeId}`)}>
								<img className={classes.img} src={logo} alt="breakmark logo"></img>
							</div>
						) : null}
						{!storeId && !groupId ? <img className={classes.img} src={logo} alt="breakmark logo"></img> : null}
						<div style={{ flex: 1 }} />
						<ProfileMenu />
						{storeId ? (
							<Button
								{...getTestDataAttr('cart-header-total')}
								component={NavLink}
								to={`/store/${storeId}/cart`}
								color="inherit"
								startIcon={
									<SvgIcon>
										<path d={mdiCart} />
									</SvgIcon>
								}
							>
								Cart: {formatCurrencyValueFromServer(cartTotal)}
							</Button>
						) : null}
						{groupId ? (
							<NavLink to={`/group/${groupId}/cart`} className={classes.cartButton}>
								<SvgIcon style={{ marginRight: 8 }}>
									<path d={mdiCart} />
								</SvgIcon>
								Cart: {formatCurrencyValueFromServer(cartTotal)}
							</NavLink>
						) : null}
					</div>
				</Toolbar>
			</AppBar>
		</div>
	);
}

const useStyles = makeStyles()((theme: Theme) => {
	return {
		root: {
			flexGrow: 1,
			backgroundColor: SYSTEM_COLORS.PRIMARY
		},
		title: {
			flexGrow: 1
		},
		headerContainer: {
			display: 'flex',
			width: '100%',
			marginLeft: 'auto',
			marginRight: 'auto',
			justifyContent: 'space-between',
			alignItems: 'center'
		},
		img: {
			height: '50px',
			width: '150px',
			cursor: 'pointer'
		},
		toolbar: {
			backgroundColor: SYSTEM_COLORS.PRIMARY,
			flex: 1
		},
		cartButton: {
			textDecoration: 'none',
			color: theme.palette.background.default,
			display: 'flex',
			alignItems: 'center',
			cursor: 'pointer',
			'&:hover': {
				color: theme.palette.action.active,
				opacity: 0.8
			}
		},
		cartItem: {
			alignItems: 'center',
			display: 'flex'
		},
		productDetails: {
			flex: 1,
			paddingLeft: 15,
			paddingRight: 15,
			display: 'grid'
		}
	};
});
