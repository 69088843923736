import { useQuery } from '@apollo/client';
import { DialogContent, Theme } from '@mui/material';
import React, { ReactElement, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';

import DialogBase from '../../../../components/block/dialogBase';
import DialogTitleHeader from '../../../../components/block/dialogTitleHeader';
import P3 from '../../../../components/typography/p3';
import { SYSTEM_COLORS } from '../../../../core/config/colors';
import { GetDataExportUrlDocument } from '../../../../generated/graphql';

interface Props {
	storeId: string;
	closeDialog(): void;
}

const ExportDialog = (props: Props): ReactElement => {
	const { classes } = useStyles();
	const { closeDialog } = props;

	const { data: urlData } = useQuery(GetDataExportUrlDocument, {
		fetchPolicy: 'network-only',
		variables: {
			storeId: props.storeId
		}
	});

	useEffect(() => {
		if (urlData && urlData.getDataExportUrlPublic?.url) {
			const link = document.createElement('a');
			link.target = '_self';
			link.href = urlData.getDataExportUrlPublic.url;
			link.click();
			closeDialog();
		}
	}, [urlData, closeDialog]);

	return (
		<DialogBase open={true}>
			<DialogTitleHeader closeDialog={closeDialog} title={'Export Store Data'} />
			<DialogContent>
				<div className={classes.loadingContainer}>
					<P3 className={classes.p3}>Generating that report, hold on a moment.</P3>
				</div>
			</DialogContent>
		</DialogBase>
	);
};

const useStyles = makeStyles()((theme: Theme) => {
	return {
		p3: {
			paddingTop: 5,
			color: SYSTEM_COLORS.GRAY
		},
		loadingContainer: {
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
			textAlign: 'center'
		}
	};
});

export default ExportDialog;
