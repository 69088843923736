import { useQuery } from '@apollo/client';
import { Theme, Typography } from '@mui/material';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import PrimaryButton from '../../components/buttons/primaryButton';
import SecondaryButton from '../../components/buttons/secondaryButton';
import P3 from '../../components/typography/p3';
import P4 from '../../components/typography/p4/p4';
import P5 from '../../components/typography/p5';
import { SYSTEM_COLORS } from '../../core/config/colors';
import useFormatServerOrders from '../../core/hooks/useFormatServerOrders';
import { useCartStoreDispatch } from '../../core/providers/storeProvider/storeProvider';
import { Package, Product, clearStoreCart } from '../../core/stores/cart/action';
import { GetTeamStoreOrderDocument, TeamStoreOrderFragment } from '../../generated/graphql';
import Detail from '../orders/components/detail';

// import useSubRoutes, { STORE_SUB_ROUTES } from '../store/pages/useSubRoutes';

interface Props {
	storeId: string;
}

const OrderSuccess = (props: Props): ReactElement => {
	const { groupId, '*': orderIds } = useParams();
	const orderIdList = orderIds ? orderIds.split('/') : [];
	const { classes } = useStyles();
	const dispatch = useCartStoreDispatch();
	const teamStoreOrder = useQuery(GetTeamStoreOrderDocument, {
		variables: {
			_eq: props.storeId,
			orderIds: orderIdList
		}
	});

	const orders: TeamStoreOrderFragment | undefined = useMemo(() => {
		if (!teamStoreOrder.data?.order || teamStoreOrder.data.order.length === 0) {
			return undefined;
		}

		return teamStoreOrder.data.order.reduce((acc: TeamStoreOrderFragment, cur: TeamStoreOrderFragment, index: number) => {
			// Skip the first item because it's used as the initial value.
			if (index === 0) return acc;

			// If order_items is undefined on either object, default to an empty array.
			const accOrderItems = acc.order_items || [];
			const curOrderItems = cur.order_items || [];

			return { ...acc, order_items: [...accOrderItems, ...curOrderItems] };
		}, teamStoreOrder.data.order[0]);
	}, [teamStoreOrder?.data?.order]);
	const formattedOrders = useFormatServerOrders(orders);

	// const url = useSubRoutes();

	useEffect(() => {
		dispatch(clearStoreCart(props.storeId));
	}, [props.storeId, dispatch]);

	return (
		<div className={classes.container}>
			<Typography className={classes.title} variant="h2">
				Thank you for your order!
			</Typography>
			<P4 className={classes.title}>
				Thank you for your order, you will be receiving a receipt from us shortly. Your order will start production as
				soon as your store captain, has approved the entire order!
			</P4>
			{teamStoreOrder.data && teamStoreOrder.data.order && formattedOrders && (
				<div className={classes.expandedContainer}>
					{formattedOrders.map((orderDetail: Product | Package, i: number): ReactElement => {
						return (
							<div key={i} className={classes.itemContainer}>
								{orderDetail.image && (
									<img src={orderDetail.image} className={classes.detailImage} alt={orderDetail.label} />
								)}
								<div>
									<P5 className={classes.productName}>
										<strong>{orderDetail.label}</strong>
									</P5>
									{orderDetail.order_for && orderDetail.order_for !== '' && (
										<P5>
											Ordered For: <strong>{orderDetail.order_for}</strong>
										</P5>
									)}
									<P3 component="div">
										<Detail orderItem={orderDetail} />
									</P3>
								</div>
							</div>
						);
					})}
				</div>
			)}
			{groupId ? (
				<div className={classes.backToOrders}>
					<SecondaryButton component={Link} to={`/group/${groupId}`}>
						Back to Catalog!
					</SecondaryButton>
				</div>
			) : (
				<div className={classes.backToOrders}>
					<SecondaryButton component={Link} to={`/store/${props.storeId}`}>
						Back to Store!
					</SecondaryButton>

					<PrimaryButton component={Link} to={`/store/${props.storeId}/orders`}>
						View Completed Orders
					</PrimaryButton>
				</div>
			)}
		</div>
	);
};

const useStyles = makeStyles()((theme: Theme) => {
	return {
		container: {
			maxWidth: 1100,
			marginLeft: 'auto',
			marginRight: 'auto',
			textAlign: 'center',
			marginBottom: '40px'
		},
		title: {
			marginTop: '30px',
			marginBottom: '30px'
		},
		expandedContainer: {
			display: 'grid',
			gridTemplateColumns: 'repeat(2, 1fr)',
			gap: '15px',
			marginTop: 15,
			marginBottom: 15,

			[theme.breakpoints.down('sm')]: {
				gridTemplateColumns: 'repeat(2, 1fr)'
			},
			[theme.breakpoints.down('xs')]: {
				gridTemplateColumns: 'repeat(1, 1fr)'
			}
		},
		detailImage: {
			width: 100,
			height: 100,
			marginRight: 40
		},
		itemContainer: {
			backgroundColor: '#FFF',
			display: 'flex',
			border: `1px solid ${SYSTEM_COLORS.DIVIDER}`,
			padding: 15,
			textAlign: 'left',
			borderRadius: 8
		},
		productName: {
			paddingBottom: 10
		},
		backToOrders: {
			marginTop: 20,
			textAlign: 'center',
			width: '100%',
			'& > :not(:first-child)': {
				marginLeft: 16
			}
		}
	};
});

export default OrderSuccess;
