import { DialogContent, Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { ReactElement, useState } from 'react';

import DialogActionBar from '../../../../components/block/dialogActionBar';
import DialogBase from '../../../../components/block/dialogBase';
import DialogTitleHeader from '../../../../components/block/dialogTitleHeader';
import InputField from '../../../../components/inputs/inputField';
import P3 from '../../../../components/typography/p3';
import { useCartStoreDispatch } from '../../../../core/providers/storeProvider/storeProvider';
import { labelCartItem } from '../../../../core/stores/cart/action';

interface Props {
	storeId: string;
	open: boolean;
	cartIndexId: number;
	handleClose(): void;
}

const LabelDialog = (props: Props): ReactElement => {
	const { classes } = useStyles();
	const [label, setLabel] = useState('');
	const dispatch = useCartStoreDispatch();

	return (
		<DialogBase open={props.open} closeDialog={props.handleClose}>
			<DialogTitleHeader closeDialog={props.handleClose} title="Label Order Item" />
			<DialogContent>
				<div className={classes.contentContainer}>
					<div>
						<Typography variant="body1">Who is this order for?</Typography>
						<P3>It can help to label orders when ordering multiple things so you know who ordered what.</P3>
					</div>
					<InputField
						variant="outlined"
						fullWidth
						autoFocus
						label="Add Order Label"
						onChange={(e) => setLabel(e.target.value)}
						onKeyDown={(e): void => {
							if (e.keyCode === 13 || e.key === 'Enter') {
								dispatch(labelCartItem(props.storeId, props.cartIndexId, label));
								props.handleClose();
								e.preventDefault();
							}
						}}
					/>
				</div>
			</DialogContent>
			<DialogActionBar
				cancelAction={props.handleClose}
				saveAction={(): void => {
					dispatch(labelCartItem(props.storeId, props.cartIndexId, label));
					props.handleClose();
				}}
			/>
		</DialogBase>
	);
};

const useStyles = makeStyles()((theme: Theme) => {
	return {
		contentContainer: { display: 'grid', gridTemplateColumns: 'repeat(1, 1fr)', gap: '30px' }
	};
});

export default LabelDialog;
