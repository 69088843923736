import { Paper, Theme } from '@mui/material';
import React, { ReactElement } from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import remarkGfm from 'remark-gfm';
import { makeStyles } from 'tss-react/mui';

import { TeamStoreFragment } from '../../generated/graphql';
import ShowcaseLogos from './components/showcaseLogos';
import ShowcasesSidebar from './components/showcasesSidebar';

interface Props {
	storeData: TeamStoreFragment;
}

const GroupCatalog = (props: Props): ReactElement => {
	const { classes } = useStyles();
	const { storeData } = props;

	return (
		<div className={classes.content}>
			<Paper className={classes.navBar}>
				<ShowcasesSidebar groupId={storeData.id} logo={storeData.image_url} />
			</Paper>
			<Paper className={classes.main}>
				{storeData.banner_url ? (
					<div className={classes.banner}>
						<img src={storeData.banner_url} alt={'Showcase Banner'} width={'100%'} />
					</div>
				) : null}
				<h1 className={classes.title}>{storeData.name}</h1>
				<ShowcaseLogos groupId={storeData.id} showcases={storeData.store_is_showcasing_stores} />
				{storeData.welcome_message ? (
					<ReactMarkdown remarkPlugins={[remarkGfm]} className={classes.welcome}>
						{storeData.welcome_message}
					</ReactMarkdown>
				) : null}
			</Paper>
		</div>
	);
};

const useStyles = makeStyles()((theme: Theme) => {
	return {
		container: {},
		content: {
			flex: 1,
			display: 'flex'
		},
		main: {
			padding: 15,
			flex: 1,
			overflow: 'hidden',
			display: 'flex',
			flexDirection: 'column',
			[theme.breakpoints.down('sm')]: {
				padding: 10
			}
		},
		banner: {
			margin: -15,
			marginBottom: 5,
			[theme.breakpoints.down('sm')]: {
				display: 'none'
			}
		},
		navBar: {
			width: 240,
			marginRight: 15,
			[theme.breakpoints.down('sm')]: {
				display: 'none'
			}
		},
		title: {
			width: '100%',
			padding: 10,
			textAlign: 'center',
			[theme.breakpoints.down('sm')]: {
				fontSize: 24,
				padding: 5
			}
		},
		welcome: {}
	};
});

export default GroupCatalog;
