import { useMemo } from 'react';

import { Package } from '../../../../core/stores/cart/action';
import { PriceModifier } from '../product/product';

/**
 * Get the price modifiers for package headers
 * @param editOption
 * @returns
 */
export const getDefaultPriceModifiers = (editOption?: Package) => {
	if (!editOption) {
		return {};
	}

	return editOption.products.reduce((previous, current) => {
		const modifications = Object.values(current.options).reduce((prev, cur) => {
			if (cur.priceModifiedAs && cur.priceModifiedAs !== 0) {
				return {
					...prev,
					[`${current.id}:${cur.availableOptionId}`]: cur.priceModifiedAs
				};
			}
			return prev;
		}, {});
		return {
			...previous,
			...modifications
		};
	}, {});
};

export const getDefaultPackages = (editPackage?: Package) => {
	if (!editPackage) {
		return {};
	}

	return editPackage.products.reduce((cur, prev) => {
		return {
			...cur,
			[prev.id]: prev
		};
	}, {});
};

export const useCalculateTotalPrice = (packagePrice: number, priceModifiers: PriceModifier) => {
	return useMemo(() => {
		const baselinePrice = packagePrice || 0;
		return Object.values(priceModifiers).reduce((cur, next): number => {
			return cur + next;
		}, baselinePrice);
	}, [packagePrice, priceModifiers]);
};
