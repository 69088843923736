import { useQuery } from '@apollo/client';
import { mdiChevronLeftCircleOutline } from '@mdi/js';
import { List, ListItem, SvgIcon, Theme } from '@mui/material';
import React, { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { GetShowcasesByGroupIdDocument } from '../../../generated/graphql';
import { SearchSet } from './searchBox';

interface Props {
	groupId: string;
	showcase?: any;
	logo?: string | null;
	onChange?: (set: SearchSet) => void;
}

const ShowcasesSidebar = ({ groupId, showcase, logo, onChange }: Props): ReactElement => {
	const { classes } = useStyles();

	const { data } = useQuery(GetShowcasesByGroupIdDocument, {
		variables: { storeId: groupId }
	});

	const getMonogram = (showcase: any) => {
		const title = showcase.label || showcase.teamStoreByStoreShowcasingId.name;
		const words = title.split(' ');
		if (words.length > 1) {
			return (words[0].substring(0, 1) + words[1].substring(0, 1)).toUpperCase();
		} else {
			return title.substring(0, 2).toUpperCase();
		}
	};

	return (
		<div className={classes.container}>
			{logo ? (
				<div className={classes.logo}>
					{logo ? (
						<div className={classes.logo}>
							<img src={logo} width={'100%'} alt={'GroupCatalog Logo'} />
						</div>
					) : null}
				</div>
			) : null}
			{showcase ? (
				<>
					{window.history.state ? (
						<div className={classes.back} onClick={() => window.history.back()}>
							<SvgIcon>
								<path d={mdiChevronLeftCircleOutline} />
							</SvgIcon>
						</div>
					) : null}
					<div className={classes.logo}>
						{showcase.image_url ? (
							<img
								width={'100%'}
								src={showcase.image_url}
								alt={showcase.label || showcase.teamStoreByStoreShowcasingId.name}
							/>
						) : (
							<div className={classes.monogram}>{getMonogram(showcase)}</div>
						)}
					</div>
				</>
			) : null}

			<List>
				{data?.showcase?.map((si) => {
					return (
						<ListItem key={si.id}>
							<NavLink
								className={classes.link}
								to={`/group/${si.team_store.friendly_url_key || si.team_store.id}/showcase/${
									si.friendly_url_key || si.id
								}`}
							>
								{si.label || si.teamStoreByStoreShowcasingId.name}
							</NavLink>
						</ListItem>
					);
				})}
			</List>
		</div>
	);
};

const useStyles = makeStyles()((theme: Theme) => {
	return {
		container: {
			display: 'flex',
			flexDirection: 'column',
			minWidth: 240
		},
		logo: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			width: 100,
			height: 100,
			alignSelf: 'center',
			margin: 20,
			overflow: 'hidden',
			borderRadius: 50,
			backgroundColor: theme.palette.primary.dark
		},
		monogram: {
			color: theme.palette.primary.contrastText,
			textDecoration: 'none',
			fontWeight: 'bold'
		},
		featured: {
			fontWeight: 'bold'
		},
		link: {
			textDecoration: 'none',
			cursor: 'pointer',
			color: theme.palette.primary.dark,
			'&:visited': {
				color: theme.palette.primary.light
			}
		},
		category: {
			textDecoration: 'none',
			color: theme.palette.secondary.dark,
			cursor: 'pointer'
		},
		back: {
			color: theme.palette.primary.dark,
			cursor: 'pointer',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			margin: 5
		}
	};
});

export default ShowcasesSidebar;
