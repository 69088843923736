import { MenuItem, Select, SelectChangeEvent, Theme } from '@mui/material';
import React, { ReactElement, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import InputField from '../../../../components/inputs/inputField';
import { formatCurrencyValueFromServer } from '../../../../core/utilites/currency';
import { ProductByIdFragment } from '../../../../generated/graphql';
import { CustomizationModel } from '../../customizationModel';

interface Props {
	product: ProductByIdFragment;
	setCustomization(options: CustomizationModel): void;
	customization: CustomizationModel;
}

const CustomNameNumber = ({ product, setCustomization, customization }: Props): ReactElement | null => {
	const { classes } = useStyles();
	const [custom, setCustom] = useState(false);

	if (!product.custom_name_enabled && !product.custom_number_enabled) {
		return null;
	}

	const handleSelectUpdate = (evt: SelectChangeEvent<string>) => {
		if (evt.target.value === 'CUSTOM') {
			setCustomization({
				name: '',
				number: ''
			});
			setCustom(true);
			return;
		}
		setCustom(false);
		const selectedIndex = parseInt(evt.target.value, 10);
		const selectedItem = product.custom_name_number_dropdown[selectedIndex];

		setCustomization({
			name: selectedItem.name,
			number: `${selectedItem.number}`
		});
	};

	return (
		<>
			<h6>Custom Name / Number</h6>
			{product.custom_name_number_dropdown && product.custom_name_number_dropdown.length > 0 && (
				<div className={classes.row}>
					<Select onChange={handleSelectUpdate} className={classes.textBox} size={'small'}>
						{product.custom_name_number_dropdown.map((specific: any, index: number) => {
							return (
								<MenuItem key={`qty-${index}`} value={`${index}`}>
									#{specific.number} - {specific.name}
								</MenuItem>
							);
						})}
						<MenuItem value="CUSTOM">Custom Number</MenuItem>
					</Select>
				</div>
			)}
			<div className={classes.row}>
				{(!product.custom_name_number_dropdown?.length || custom) && (
					<>
						{product.custom_number_enabled ? (
							<InputField
								label={'Number'}
								style={{ width: 120, marginRight: 10 }}
								helperText={
									product.custom_number_price === 0
										? null
										: `+${formatCurrencyValueFromServer(product.custom_number_price || 0)}`
								}
								value={customization.number}
								onChange={(evt) => setCustomization({ ...customization, number: evt.target.value })}
							/>
						) : null}
						{product.custom_name_enabled ? (
							<InputField
								label={'Name'}
								fullWidth={true}
								helperText={
									product.custom_name_price === 0
										? null
										: `+${formatCurrencyValueFromServer(product.custom_name_price || 0)}`
								}
								value={customization.name}
								onChange={(evt) => setCustomization({ ...customization, name: evt.target.value })}
							/>
						) : null}
					</>
				)}
			</div>
		</>
	);
};

const useStyles = makeStyles()((theme: Theme) => {
	return {
		container: {},
		row: {
			display: 'flex',
			marginBottom: 10
		},
		textBox: {
			width: '100%',
			backgroundColor: theme.palette.background.paper,
			borderColor: theme.palette.primary.dark
		}
	};
});

export default CustomNameNumber;
