import { DialogActions, FormControl, InputLabel, NativeSelect, Theme } from '@mui/material';
import clsx from 'clsx';
import { Formik } from 'formik';
import React, { Fragment, ReactElement, useMemo, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import * as Yup from 'yup';

import PrimaryButton from '../../../../components/buttons/primaryButton';
import InputField from '../../../../components/inputs/inputField';
import P5 from '../../../../components/typography/p5';
import { SYSTEM_COLORS } from '../../../../core/config/colors';
import { OptionValue, Product } from '../../../../core/stores/cart/action';
import { calculatePriceModified, formatCurrencyValueFromServer } from '../../../../core/utilites/currency';
import { getShirtSizeDisabled, getShortSizeDisabled } from '../../../../utilities/sizeHelpers';
import { getTestDataAttr } from '../../../../utilities/testHelpers';
import { FormattedProduct, ProductOption } from '../productList/productDef';
import ImageOption from './components/imageOption';
import SelectOption from './components/selectOption';

interface Props {
	hide?: boolean;

	handleCancel(): void;

	handlePrevious?(): void;

	largeImage?: boolean;
	previousDisabled?: boolean;
	product: FormattedProduct;

	setProduct(product: Product): void;

	setPriceModifiers(modifier: PriceModifier): void;

	successLabel: string;
	// On packages, we do not want folks to be able to discount below the package price.
	ignoreNegativeValue?: boolean;
	discountType?: 'percent' | 'dollar';
	discountRate?: number;
	editProduct?: Product;
	totalPrice?: string;
	productLabel: string;
	disableQuantityField?: boolean;
}

export interface PriceModifier {
	[key: string]: number;
}

const ProductForm = (props: Props): ReactElement => {
	const { classes } = useStyles();
	const { product, editProduct, setPriceModifiers } = props;
	const [image, setImage] = useState(editProduct?.image ? [editProduct.image] : [product.display_photo] || []);

	const handleSelection = (item: ProductOption, productOptionId: string): number => {
		if (item.imageUrl || item.secondaryImageUrl) {
			const imageDefs = [];
			if (item.imageUrl) {
				imageDefs.push(item.imageUrl);
			}
			if (item.secondaryImageUrl) {
				imageDefs.push(item.secondaryImageUrl);
			}
			setImage(imageDefs);
		}

		const priceModifiedValue = calculatePriceModified({
			ignoreNegativeValue: props.ignoreNegativeValue,
			priceModifier: item.priceModifier,
			discountRate: props.discountRate,
			discountType: props.discountType
		});

		setPriceModifiers({
			[`${product.id}:${productOptionId}`]: priceModifiedValue
		});
		return priceModifiedValue;
	};

	const [initialValues, schema] = useMemo(() => {
		const initialValueKeys: any = {};
		const validationShape: any = {};
		product.productOptions.forEach((option) => {
			if (option.options.length === 0) {
				return;
			}

			initialValueKeys[option.id] =
				editProduct && editProduct.options[option.id] ? editProduct.options[option.id] : undefined;

			// If there is only one option, set it and we will not display it below.
			if (option.options.length === 1 && !editProduct) {
				const o = option.options[0];
				const priceModifiedAs = handleSelection(o, option.id);
				initialValueKeys[option.id] = {
					availableOptionId: option.id,
					availableOptionValueId: o.id,
					priceModifiedAs,
					value: o.label,
					label: option.label,
					imageUrl: o.imageUrl
				};
			}

			validationShape[option.id] = Yup.object()
				.shape({
					availableOptionId: Yup.string().required(),
					availableOptionValueId: Yup.string().required(),
					priceModifiedAs: Yup.number().required(),
					value: Yup.string().required(),
					label: Yup.string().required(),
					imageUrl: Yup.string()
				})
				.required();
		});

		if (product.custom_name_enabled) {
			initialValueKeys.custom_name = editProduct?.options?.custom_name ? editProduct.options.custom_name : '';
			validationShape.custom_name = Yup.string();
		}
		if (product.custom_number_enabled) {
			initialValueKeys.custom_number = editProduct?.options?.custom_number ? editProduct.options.custom_number : '';
			validationShape.custom_number = Yup.string();
		}

		initialValueKeys.quantity = editProduct?.quantity ? editProduct.quantity : 1;

		return [initialValueKeys, validationShape];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [product, editProduct]);

	const fitKeyForTopRestriction = useMemo(() => {
		if (!product.product.top_size_restriction && !product.product.short_size_restriction) {
			return undefined;
		}
		if (product.product.top_size_restriction) {
			const hasFit = Object.values(product.productOptions).find((a) => a.label.toLowerCase() === 'fit');
			if (!hasFit) {
				return undefined;
			}
			return { id: hasFit.id, method: getShirtSizeDisabled };
		}
		const hasFit = Object.values(product.productOptions).find((a) => a.label.toLowerCase() === 'inseam');
		if (!hasFit) {
			return undefined;
		}
		return { id: hasFit.id, method: getShortSizeDisabled };
	}, [product]);

	return (
		<Formik
			validateOnMount
			validationSchema={Yup.object().shape(schema)}
			initialValues={initialValues}
			onSubmit={async ({ quantity, ...values }, { setSubmitting }) => {
				props.setProduct({
					type: 'product',
					id: product.id,
					image: image[0] || '',
					label: product.label || '',
					price: product.product.base_price || 0,
					options: {
						...values
					},
					quantity: quantity || 1
				});
			}}
		>
			{({
				isSubmitting,
				values,
				handleChange,
				handleSubmit,
				submitForm,
				setFieldValue,
				errors,
				isValid,
				dirty,
				...rest
			}) => {
				return (
					<form
						className={clsx(classes.form, props.hide ? classes.hiddenDisplay : null)}
						{...getTestDataAttr(props.hide ? 'hidden' : 'shown')}
					>
						<div className={classes.productContainer}>
							<div className={clsx(classes.imageContainer, props.largeImage ? classes.largeImage : null)}>
								{image.map((img, i) => {
									return (
										<img key={i} src={img} className={classes.image} alt={`${product.label} product order`} />
									);
								})}
							</div>
							<div style={{ flex: 1 }}>
								<div className={classes.headerInfo}>
									<div style={{ fontSize: 24, fontWeight: 600 }}>{props.productLabel}</div>
									{props.totalPrice && <div style={{ fontSize: 24, fontWeight: 600 }}>{props.totalPrice}</div>}
								</div>

								{product.description && (
									<div style={{ fontSize: 16 }}>
										{product.description.split('\n').map((item, key) => {
											return (
												<Fragment key={key}>
													{item}
													<br />
												</Fragment>
											);
										})}
									</div>
								)}

								<div className={classes.optionContainer}>
									<div className={classes.options}>
										{product.productOptions.map((productOption, i): ReactElement | null => {
											if (productOption.options.length === 0 || productOption.options.length === 1) {
												return null;
											}

											const removedValues =
												productOption.label.toLowerCase() === 'size' && fitKeyForTopRestriction
													? fitKeyForTopRestriction.method(
															values[fitKeyForTopRestriction.id]?.value || ''
													  )
													: undefined;

											if (productOption.options[0].imageUrl) {
												return (
													<ImageOption
														testId={productOption.uiTestId}
														removedValues={removedValues}
														selectedOptions={values}
														discountRate={props.discountRate}
														discountType={props.discountType}
														ignoreNegativeValue={props.ignoreNegativeValue}
														selectedOptionId={values[productOption.id]?.availableOptionValueId}
														key={productOption.id}
														handleSelectedOption={(item: ProductOption) => {
															const priceModifiedValue = handleSelection(item, productOption.id);

															const field: OptionValue = {
																availableOptionId: productOption.id,
																availableOptionValueId: item.id,
																label: productOption.label,
																priceModifiedAs: priceModifiedValue,
																value: item.label,
																imageUrl: item.imageUrl
															};

															setFieldValue(productOption.id, field);
														}}
														productOptionList={productOption}
													/>
												);
											}
											return (
												<SelectOption
													testId={productOption.uiTestId}
													removedValues={removedValues}
													discountRate={props.discountRate}
													discountType={props.discountType}
													ignoreNegativeValue={props.ignoreNegativeValue}
													selectedOptionId={values[productOption.id]?.availableOptionValueId}
													key={productOption.id}
													handleSelectedOption={(item: ProductOption) => {
														const priceModifiedValue = handleSelection(item, productOption.id);

														const field: OptionValue = {
															availableOptionId: productOption.id,
															availableOptionValueId: item.id,
															label: productOption.label,
															priceModifiedAs: priceModifiedValue,
															value: item.label,
															imageUrl: item.imageUrl
														};

														setFieldValue(productOption.id, field);
													}}
													productOptionList={productOption}
												/>
											);
										})}
									</div>
									{(product.custom_name_enabled || product.custom_number_enabled) && (
										<>
											<P5>Add Customization</P5>
											<div className={classes.optionGrid}>
												{product.custom_name_enabled && (
													<InputField
														value={values.custom_name}
														onChange={(e): void => {
															setFieldValue('custom_name', e.target.value);
															if (e.target.value.trim().length === 1) {
																setPriceModifiers({
																	// ...priceModifiers,
																	custom_name: product.custom_name_price || 0
																});
															} else if (e.target.value.trim().length === 0) {
																setPriceModifiers({
																	// ...priceModifiers,
																	custom_name: 0
																});
															}
														}}
														inputProps={{ ...getTestDataAttr('custom-name-input') }}
														fullWidth
														variant="outlined"
														label={'Custom Name'}
														error={Boolean(errors.custom_name)}
														helperText={
															errors.custom_name ? (
																<span>{errors.custom_name.toString()}</span>
															) : (
																<span>
																	Additional price of{' '}
																	{formatCurrencyValueFromServer(
																		product.custom_name_price || 0
																	)}
																</span>
															)
														}
													/>
												)}
												{product.custom_number_enabled && (
													<InputField
														inputProps={{ ...getTestDataAttr('custom-number-input') }}
														value={values.custom_number}
														onChange={(e): void => {
															setFieldValue('custom_number', e.target.value);
															if (e.target.value.trim().length === 1) {
																setPriceModifiers({
																	// ...priceModifiers,
																	custom_number: product.custom_number_price || 0
																});
															} else if (e.target.value.trim().length === 0) {
																setPriceModifiers({
																	// ...priceModifiers,
																	custom_number: 0
																});
															}
														}}
														fullWidth
														variant="outlined"
														label={'Custom Number'}
														error={Boolean(errors.custom_number)}
														helperText={
															errors.custom_number ? (
																<span>{errors.custom_number.toString()}</span>
															) : (
																<span>
																	Additional price of{' '}
																	{formatCurrencyValueFromServer(
																		product.custom_number_price || 0
																	)}
																</span>
															)
														}
													/>
												)}
											</div>
										</>
									)}
									{props.disableQuantityField !== true && (
										<FormControl className={classes.formControl}>
											<InputLabel htmlFor="order-quantity">Quantity</InputLabel>
											<NativeSelect
												name={'quantity'}
												id="order-quantity"
												value={values.quantity}
												onChange={(e) => {
													setFieldValue('quantity', parseInt(e.target.value, 10));
												}}
											>
												<option value={1}>1</option>
												<option value={2}>2</option>
												<option value={3}>3</option>
												<option value={4}>4</option>
												<option value={5}>5</option>
												<option value={6}>6</option>
												<option value={7}>7</option>
												<option value={8}>8</option>
												<option value={9}>9</option>
												<option value={10}>10</option>
												<option value={11}>11</option>
												<option value={12}>12</option>
												<option value={13}>13</option>
												<option value={14}>14</option>
												<option value={15}>15</option>
												<option value={16}>16</option>
												<option value={17}>17</option>
												<option value={18}>18</option>
												<option value={19}>19</option>
												<option value={20}>20</option>
												<option value={21}>21</option>
												<option value={22}>22</option>
												<option value={23}>23</option>
												<option value={24}>24</option>
												<option value={25}>25</option>
												<option value={26}>26</option>
												<option value={27}>27</option>
												<option value={28}>28</option>
												<option value={29}>29</option>
												<option value={30}>30</option>
												<option value={31}>31</option>
												<option value={32}>32</option>
												<option value={33}>33</option>
												<option value={34}>34</option>
												<option value={35}>35</option>
												<option value={36}>36</option>
												<option value={37}>37</option>
												<option value={38}>38</option>
												<option value={39}>39</option>
												<option value={40}>40</option>
											</NativeSelect>
										</FormControl>
									)}
								</div>
							</div>
						</div>

						<DialogActions className={classes.actions}>
							<PrimaryButton variant="outlined" onClick={props.handleCancel} buttonSizeOverride="small">
								Cancel
							</PrimaryButton>
							<div className={classes.navActions}>
								{props.handlePrevious && (
									<PrimaryButton
										buttonSizeOverride="small"
										variant="outlined"
										onClick={props.handlePrevious}
										disabled={props.previousDisabled}
									>
										Previous
									</PrimaryButton>
								)}

								<PrimaryButton
									buttonSizeOverride="small"
									variant="contained"
									disabled={Boolean(isValid === false)}
									onClick={submitForm}
									{...getTestDataAttr(props.successLabel === 'Next' ? 'next-button' : 'add-to-cart')}
								>
									{props.successLabel}
								</PrimaryButton>
							</div>
						</DialogActions>
					</form>
				);
			}}
		</Formik>
	);
};

const useStyles = makeStyles()((theme: Theme) => {
	return {
		form: {
			display: 'flex',
			flexDirection: 'column',
			flex: 1
		},
		productContainer: {
			flex: 1,
			display: 'flex',
			[theme.breakpoints.down('sm')]: {
				display: 'block'
			}
		},
		imageContainer: {
			width: 125,
			marginRight: 30,
			marginBottom: 30,
			[theme.breakpoints.down('sm')]: {
				width: '50%',
				marginRight: 'auto',
				marginLeft: 'auto',
				marginBottom: 0
			}
		},
		image: {
			width: 125,
			height: 125,
			[theme.breakpoints.down('sm')]: {
				width: '100%',
				height: 'auto'
			}
		},
		largeImage: {
			width: 250,
			'& $image': {
				width: 250,
				height: 250
			}
		},
		optionContainer: {
			display: 'grid', // grid, flex, block, inline-block, inline-flex
			gridTemplateColumns: 'repeat(1, 1fr)',
			gap: '10px',
			marginTop: 15
		},
		options: {
			display: 'grid', // grid, flex, block, inline-block, inline-flex
			gridTemplateColumns: 'repeat(1, 1fr)',
			gap: '10px'
		},
		optionGrid: {
			display: 'grid', // grid, flex, block, inline-block, inline-flex
			gridTemplateColumns: 'repeat(2, 2fr)',
			gap: '15px',
			[theme.breakpoints.down('xs')]: {
				gridTemplateColumns: 'repeat(1, 1fr)'
			}
		},
		optionLabel: {
			fontSize: '14px',
			padding: '10px 0',
			height: 20
		},
		actions: {
			justifyContent: 'space-between',
			[theme.breakpoints.down('sm')]: {
				marginTop: 20,
				marginLeft: 0,
				padding: 10,
				borderTop: `1px solid ${SYSTEM_COLORS.DIVIDER}`
			}
		},
		navActions: {
			'& :not(:first-child)': {
				marginLeft: 8
			}
		},
		hiddenDisplay: {
			display: 'none'
		},
		photoContainer: {
			display: 'grid',
			gridTemplateColumns: 'repeat(4, 4fr)',
			gap: '10px'
		},
		smallImage: {
			width: 50,
			height: 50
		},
		headerInfo: {
			marginBottom: 10,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between'
		},
		formControl: {
			margin: theme.spacing(1),
			minWidth: 120
		}
	};
});

export default ProductForm;
