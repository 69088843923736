import React, { ReactElement } from "react";
import ButtonAppBar from "../../core/components/header";

interface Props {
  c?: any;
}

const Home = (props: Props): ReactElement => {
  return (
    <div style={{flex: 1}}>
      <ButtonAppBar />
      <div style={{ padding: 10 }}>
        <div>You're Home</div>
				<br />
				<br />
			</div>
    </div>
  );
};

export default Home;
