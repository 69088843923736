import { Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';
import React, { ReactElement, ReactNode } from 'react';

import { SYSTEM_COLORS } from '../../../core/config/colors';
import { getRemFromPx } from '../../../core/config/theme';

interface Props {
	className?: string;
	children: ReactNode;
}

const P4 = (props: Props): ReactElement => {
	const { classes } = useStyles();

	return (
		<Typography component="p" className={clsx(classes.p4, props.className ? props.className : null)}>
			{props.children}
		</Typography>
	);
};

const useStyles = makeStyles()((theme: Theme) => {
	return {
		p4: {
			color: SYSTEM_COLORS.BLACK_SHADE,
			textShadow: 'none',
			fontSize: getRemFromPx(16),
			lineHeight: getRemFromPx(20),
			[theme.breakpoints.up('md')]: {
				fontSize: getRemFromPx(16),
				lineHeight: getRemFromPx(20)
			}
		}
	};
});

export default P4;
