import { Theme, Tooltip } from '@mui/material';
import * as Sentry from '@sentry/react';
import clsx from 'clsx';
import React, { ReactElement, useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { formatCurrencyValueFromServer, handleStoreDiscount } from '../../core/utilites/currency';
import { ProductByIdFragment } from '../../generated/graphql';
import { getShortSizeDescriptor } from '../../utilities/sizeHelpers';
import { OptionModel } from './optionModel';

interface Props {
	style?: React.CSSProperties;
	product: ProductByIdFragment;
	options: OptionModel[];
	onChange: (options: OptionModel[]) => void;
	discountRate: number;
	discountType?: 'dollar' | 'percent';
}

const ProductOptions = ({ style, product, options, onChange, discountRate, discountType }: Props): ReactElement => {
	const { classes } = useStyles();
	const [selectedOptions, setSelectedOptions] = useState<OptionModel[]>(options);
	const [optionValues, setOptionValues] = useState<OptionModel[]>([]);

	useEffect(() => {
		if (product && product.option_values) {
			const opts = product.option_values.map((ov) => {
				const foundOption = product.options.find((opt) => {
					return opt.available_option.id === ov.available_option_id;
				});

				if (!foundOption) {
					// LOG SENTRY ERROR
					Sentry.withScope((scope) => {
						scope.setExtra('option_values', product.option_values);
						scope.setExtra('options', product.options);
						Sentry.captureException(new Error(`Error with not finding option - ${product.id}`));
					});
				}

				const o: OptionModel = {
					availableOptionId: ov.available_option_id,
					availableOptionValueId: ov!.available_option_value!.id,
					label: foundOption?.available_option.name || ov!.available_option_value!.label,
					priceModifiedAs:
						ov.price_modifier_override !== null && ov.price_modifier_override !== undefined
							? ov.price_modifier_override
							: ov!.available_option_value!.price_modifier,
					value: ov.label_override || ov!.available_option_value!.label,
					image_url: ov?.image_url || '',
					secondary_image_url: ov?.secondary_image_url || ''
				};
				return o;
			});
			setOptionValues(opts);
		} else {
			setOptionValues([]);
		}
	}, [product, setOptionValues]);

	const isSelected = (option: OptionModel) => {
		return selectedOptions.indexOf(option) >= 0;
	};

	const handleOptionClick = (option: OptionModel) => {
		const selected = selectedOptions.filter((s) => s.availableOptionId !== option.availableOptionId);
		selected.push(option);
		setSelectedOptions(selected);
		onChange(selected);
	};

	const getPriceModifier = (availableOptionId: string) => {
		const selected = selectedOptions.filter((s) => s.availableOptionId === availableOptionId);
		const modifiedPrice = selected?.[0]?.priceModifiedAs;
		if (modifiedPrice && modifiedPrice !== 0) {
			const modifiedPriceWithDiscount = handleStoreDiscount({
				allowNegativeValues: true,
				discountRate: discountRate,
				type: discountType,
				incomingValue: modifiedPrice
			});

			if (modifiedPriceWithDiscount > 0) {
				return `(+${formatCurrencyValueFromServer(modifiedPriceWithDiscount)})`;
			} else {
				return `(${formatCurrencyValueFromServer(modifiedPriceWithDiscount)})`;
			}
		} else {
			return '';
		}
	};

	return (
		<div className={classes.optionsBox} style={style}>
			{product.product.size_chart_url ? (
				<div className={classes.sizeChartLink}>
					<a href={product.product.size_chart_url} target={'_blank'} rel={'noreferrer'}>
						Size Guide
					</a>
				</div>
			) : null}
			{product.options.map((opt: any) => (
				<div key={opt.available_option.id}>
					<h6>
						{opt.available_option.display_name || opt.available_option.name}
						&nbsp;{getPriceModifier(opt.available_option.id)}
					</h6>
					<div className={classes.row} style={{ flexWrap: 'wrap' }}>
						{optionValues
							.filter((ov) => ov.availableOptionId === opt.available_option.id)
							.map((ov) => (
								<div
									key={ov.availableOptionValueId}
									className={clsx(classes.button, isSelected(ov) ? classes.selected : null)}
									onClick={() => handleOptionClick(ov)}
								>
									{ov.image_url ? (
										<Tooltip title={ov.value}>
											<img src={ov.image_url} alt={ov.value} />
										</Tooltip>
									) : (
										<span>{getShortSizeDescriptor(opt.available_option.name, ov.value)}</span>
									)}
								</div>
							))}
					</div>
				</div>
			))}
		</div>
	);
};

const useStyles = makeStyles()((theme: Theme) => {
	return {
		optionsBox: {
			backgroundColor: theme.palette.grey['100'],
			h6: {
				marginTop: 0,
				marginBottom: 2
			}
		},
		row: {
			display: 'flex',
			marginBottom: 10
		},
		button: {
			fontSize: 12,
			backgroundColor: theme.palette.background.paper,
			borderColor: theme.palette.primary.light,
			borderStyle: 'solid',
			borderWidth: 1,
			borderRadius: 4,
			margin: 5,
			marginTop: 2,
			padding: 5,
			paddingRight: 8,
			paddingLeft: 8,
			minWidth: 40,
			textAlign: 'center',
			alignItems: 'center',
			cursor: 'pointer',
			userSelect: 'none',
			img: {
				maxWidth: 80
			},
			':hover': {
				backgroundColor: theme.palette.primary.light
			}
		},
		selected: {
			backgroundColor: theme.palette.primary.dark,
			color: theme.palette.background.paper,
			borderColor: theme.palette.primary.light
		},
		disabled: {
			backgroundColor: theme.palette.background.default,
			color: theme.palette.grey.A400,
			borderColor: theme.palette.grey.A400,
			borderWidth: 1,
			cursor: 'not-allowed'
		},
		sizeChartLink: {
			display: 'flex',
			justifyContent: 'flex-end',
			textTransform: 'uppercase',
			fontSize: 14
		}
	};
});

export default ProductOptions;
