import { IconButton, SvgIcon, Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { mdiClose } from '@mdi/js';
import React, { ReactElement } from 'react';

import { SYSTEM_COLORS } from '../../../core/config/colors';
import P5 from '../../typography/p5';

interface Props {
	title: string;
	subLabel?: string;
	closeDialog?(): void;
}

const DialogTitleHeader = (props: Props): ReactElement => {
	const { classes } = useStyles();

	return (
		<div className={classes.dialogTitle}>
			{props.closeDialog && <div className={classes.hidden}></div>}
			<div className={classes.textContainer}>
				<Typography variant="h4" component="div" className={classes.h4}>
					{props.title}
				</Typography>
				{props.subLabel && <P5 className={classes.p5}>{props.subLabel}</P5>}
			</div>
			{props.closeDialog && (
				<IconButton aria-label="close" className={classes.closeButton} onClick={props.closeDialog}>
					<SvgIcon>
						<path d={mdiClose} />
					</SvgIcon>
				</IconButton>
			)}
		</div>
	);
};

const useStyles = makeStyles()((theme: Theme) => {
	return {
		hidden: {
			width: '48px',
			height: '30px',
			[theme.breakpoints.down('sm')]: {
				display: 'none'
			}
		},

		dialogTitle: {
			borderBottom: `1px solid #DFE0EB`,
			padding: '16px 24px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.03)',
			[theme.breakpoints.up('md')]: {
				boxShadow: 'none'
			},
			marginBottom: 12
		},
		closeButton: {
			color: SYSTEM_COLORS.GRAY_SHADE
		},
		textContainer: {
			[theme.breakpoints.up('md')]: {
				flex: 1,
				textAlign: 'center'
			}
		},
		h4: {
			color: SYSTEM_COLORS.GRAY_SHADE
		},
		p5: {
			paddingTop: 12,
			color: SYSTEM_COLORS.GRAY
		},
		priceContainer: {},
		price: {
			color: SYSTEM_COLORS.GREEN_DEFAULT
		}
	};
});

export default DialogTitleHeader;
