import { useQuery } from '@apollo/client';
import { Paper, Theme } from '@mui/material';
import React, { ReactElement, useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import ErrorContainer from '../../components/block/errorContainer';
import { useFriendlyKeyHelper } from '../../core/hooks/useFriendlyKeyHelper';
import { useCartStoreDispatch } from '../../core/providers/storeProvider/storeProvider';
import { Product as CartProduct, setAddNewProduct } from '../../core/stores/cart/action';
import { GetShowcaseByProductDocument } from '../../generated/graphql';
import AddProductDialog from '../product/addProductDialog';
import ProductPanel from '../product/productPanel';

interface Props {
	cartId: string;
}

const Product = (props: Props): ReactElement => {
	const { classes } = useStyles();
	const { productId } = useParams();
	const [cartProduct, setCartProduct] = useState<CartProduct>();
	const dispatch = useCartStoreDispatch();
	const variables = useFriendlyKeyHelper(productId!);
	const { data, error, loading } = useQuery(GetShowcaseByProductDocument, { variables });

	const handleProductAction = (product: CartProduct) => {
		dispatch(setAddNewProduct(props.cartId, product));
		setCartProduct(product);
	};

	if (error || (!data && !loading)) {
		console.error(error);
		return (
			<ErrorContainer
				title="Problem loading product."
				description="Please check the link again or contact us at	support@breakmark.com"
			/>
		);
	}

	const teamStore = data?.store_product?.[0]?.team_store;

	if (teamStore) {
		return (
			<div className={classes.content}>
				<Paper className={classes.main}>
					{teamStore.storeIsShowcasingStoresByStoreShowcasingId &&
					teamStore.storeIsShowcasingStoresByStoreShowcasingId.length > 0 &&
					teamStore.storeIsShowcasingStoresByStoreShowcasingId[0].banner_url &&
					teamStore.storeIsShowcasingStoresByStoreShowcasingId[0].banner_url?.length > 0 ? (
						<div className={classes.banner}>
							<img
								src={teamStore.storeIsShowcasingStoresByStoreShowcasingId[0].banner_url}
								alt="Banner for team"
								width={'100%'}
							/>
						</div>
					) : null}
					<ProductPanel action={handleProductAction} />
				</Paper>
				<AddProductDialog onClose={() => setCartProduct(undefined)} product={cartProduct} />
			</div>
		);
	} else {
		return <div></div>;
	}
};

const useStyles = makeStyles()((theme: Theme) => {
	return {
		container: {},
		content: {
			flex: 1
		},
		main: {
			padding: 15,
			overflow: 'hidden',
			flex: 1
		},
		navBar: {
			width: 240,
			marginRight: 15
		},
		title: {
			width: '100%',
			padding: 10,
			textAlign: 'center'
		},
		banner: {
			margin: -15,
			marginBottom: 5
		}
	};
});

export default Product;
