import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { ReactElement } from 'react';

import { SYSTEM_COLORS } from '../../../core/config/colors';
import ButtonBase, { ExtendedBaseProps } from '../buttonBase/buttonBase';

const PopoverMenuButton = (props: ExtendedBaseProps): ReactElement => {
	const { classes } = useStyles();
	const { children, ...rest } = props;

	return (
		<ButtonBase
			{...rest}
			buttonSizeOverride="small"
			disableRipple
			classes={{
				root: classes.root,
				contained: classes.contained
			}}
		>
			{children}
		</ButtonBase>
	);
};

const useStyles = makeStyles()((theme: Theme) => {
	return {
		root: {
			borderRadius: 8,
			justifyContent: 'flex-start',
			'&:disabled': {
				color: SYSTEM_COLORS.GRAYSCALE_GRAY_LIGHTEST
			},
			boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.07)'
		},
		contained: {
			color: SYSTEM_COLORS.GRAY_SHADE,
			backgroundColor: '#FFF',
			'&:hover': {
				boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.12);',
				backgroundColor: '#FFF'
			},
			'&:focusVisible': {
				boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.12);',
				backgroundColor: '#FFF'
			},
			'&:active': {
				boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.12);',
				backgroundColor: '#FFF'
			}
		}
	};
});

export default PopoverMenuButton;
