import { Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';
import React, { ReactElement, ReactNode } from 'react';

import { getRemFromPx } from '../../../core/config/theme';

interface Props {
	className?: string;
	children: ReactNode;
}

const P5 = (props: Props): ReactElement => {
	const { classes } = useStyles();

	return (
		<Typography component="p" className={clsx(classes.P5, props.className ? props.className : null)}>
			{props.children}
		</Typography>
	);
};

const useStyles = makeStyles()((theme: Theme) => {
	return {
		P5: {
			textShadow: 'none',
			fontSize: getRemFromPx(14),
			lineHeight: getRemFromPx(20),
			[theme.breakpoints.up('md')]: {
				fontSize: getRemFromPx(14),
				lineHeight: getRemFromPx(20)
			}
		}
	};
});

export default P5;
