import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { ReactElement, useState } from 'react';

import DialogBase from '../../../../components/block/dialogBase';
import { useCartStoreDispatch } from '../../../../core/providers/storeProvider/storeProvider';
import { Product, editCartProduct, setAddNewProduct } from '../../../../core/stores/cart/action';
import { formatCurrencyValueFromServer } from '../../../../core/utilites/currency';
import ProductForm from '../product';
import { PriceModifier } from '../product/product';
import { FormattedProduct } from '../productList/productDef';
import { getDefaultPriceModifiers, useCalculateTotalPrice } from './productDialogUtils';

interface Props {
	storeId: string;
	product: FormattedProduct;
	editProduct?: Product;
	editProductIndex?: number;
	open: boolean;
	handleClose(): void;
	discountType?: 'percent' | 'dollar';
	discountRate?: number;
}

const ProductDialog = (props: Props): ReactElement => {
	const { classes } = useStyles();
	const { product, discountRate, discountType } = props;
	const dispatch = useCartStoreDispatch();

	const defaultPriceModifiers = getDefaultPriceModifiers(props.editProduct);

	const [priceModifiers, setPriceModifiers] = useState<PriceModifier>(defaultPriceModifiers);

	const totalPrice = useCalculateTotalPrice({
		discountRate,
		discountType,
		priceModifiers,
		basePrice: product.product.base_price
	});

	return (
		<DialogBase open={props.open} containerClass={classes.dialogContainer}>
			{/* <DialogTitleHeader title={product.label || ''} subLabel={`Price: ${formatCurrencyValueFromServer(totalPrice)}`} /> */}
			<div className={classes.productForm}>
				<ProductForm
					totalPrice={`${formatCurrencyValueFromServer(totalPrice)}`}
					productLabel={product.label || ''}
					editProduct={props.editProduct}
					discountType={discountType}
					discountRate={discountRate}
					setPriceModifiers={(modifiers: PriceModifier) => {
						setPriceModifiers({
							...priceModifiers,
							...modifiers
						});
					}}
					largeImage
					handleCancel={props.handleClose}
					product={product}
					setProduct={(addItem: Product) => {
						if (props.editProduct && props.editProductIndex !== undefined) {
							// edit mode
							dispatch(editCartProduct(props.storeId, { ...addItem, price: totalPrice }, props.editProductIndex));
						} else {
							dispatch(setAddNewProduct(props.storeId, { ...addItem, price: totalPrice }));
						}
						props.handleClose();
					}}
					successLabel={props.editProduct && props.editProductIndex ? 'Update Cart' : 'Add to Cart'}
					disableQuantityField={product.enforce_limit_qty}
				/>
			</div>
		</DialogBase>
	);
};

const useStyles = makeStyles()((theme: Theme) => {
	return {
		dialogContainer: {
			[theme.breakpoints.up('md')]: {
				minWidth: 800,
				maxWidth: 800
			}
		},
		productForm: {
			display: 'flex',
			flex: 1,
			padding: 15
		}
	};
});

export default ProductDialog;
