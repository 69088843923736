import { Theme, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import { makeStyles } from 'tss-react/mui';

import P4 from '../../typography/p4/p4';

interface Props {
	title: string;
	description: string;
}

const ErrorContainer = ({ title, description }: Props): ReactElement => {
	const { classes } = useStyles();

	return (
		<div className={classes.container}>
			<Typography className={classes.title} variant="h2">
				{title}
			</Typography>
			<P4 className={classes.title}>{description}</P4>
		</div>
	);
};

const useStyles = makeStyles()((theme: Theme) => {
	return {
		container: {
			maxWidth: 1100,
			marginLeft: 'auto',
			marginRight: 'auto',
			textAlign: 'center',
			marginBottom: '40px'
		},
		title: {
			marginTop: '30px',
			marginBottom: '30px'
		}
	};
});

export default ErrorContainer;
