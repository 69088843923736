import { useMutation } from '@apollo/client';
import { Theme, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import PrimaryButton from '../../../../components/buttons/primaryButton';
import { SYSTEM_COLORS } from '../../../../core/config/colors';
import { useCartItemsForStore } from '../../../../core/hooks/useCartItemsForStore';
import { useFormattedStoreProduct } from '../../../../core/hooks/useFormattedStoreProduct';
// import { PUBLIC_ROUTES } from '../../../../core/routes/publicRoutes';
import { Package, Product } from '../../../../core/stores/cart/action';
import { CreateCheckoutDocument, StorePackageFragment, TeamStoreFragment } from '../../../../generated/graphql';
import ClosedStore from '../../components/closedStore';
import LabelDialog from '../../components/labelDialog';
import PackageDialog from '../../components/packageDialog';
import ProductDialog from '../../components/productDialog';
import { FormattedProduct } from '../../components/productList/productDef';
import StagingStore from '../../components/stagingStore';
import CartItemList from './components/cartItemsList';
import Checkout from './components/checkout';

interface Props {
	storeData: TeamStoreFragment;
	discountRate: number | undefined;
	discountType: 'dollar' | 'percent' | undefined;
}

export interface CheckoutOptions {
	paymentType: 'card' | 'check';
	name: string;
	email: string;
	attributionSelected?: string;
}

const formatProductForServer = (product: Product) => {
	const { options, ...rest } = product;

	const builtItem: any = {
		...rest
	};

	if (options.hasOwnProperty('custom_name')) {
		builtItem.custom_name = options.custom_name ? options.custom_name : ' ';
	}
	if (options.hasOwnProperty('custom_number')) {
		builtItem.custom_number = options.custom_number ? options.custom_number : ' ';
	}
	const { custom_name, custom_number, ...restOfOptions } = options;
	builtItem.options = Object.values(restOfOptions);
	return builtItem;
};

interface EditItemView {
	cartKey: number;
	item: Product | Package;
}

type EditProductItem = {
	type: 'product';
	item?: FormattedProduct;
};
type EditPackageItem = {
	type: 'package';
	item?: StorePackageFragment;
};

const Cart = (props: Props): ReactElement => {
	const { classes } = useStyles();
	const cartItems = useCartItemsForStore();
	const [editItem, setEditItem] = useState<undefined | EditItemView>(undefined);
	const [labelItem, setLabelItem] = useState<undefined | EditItemView>(undefined);
	const formattedProducts = useFormattedStoreProduct(props.storeData.store_products);
	const [createCheckout, { loading, data }] = useMutation(CreateCheckoutDocument);
	const { enqueueSnackbar } = useSnackbar();

	const editProductItem = useMemo((): undefined | EditPackageItem | EditProductItem => {
		if (!editItem?.item) {
			return undefined;
		}

		if (editItem.item.type === 'product') {
			return { type: 'product', item: formattedProducts.find((product) => product.id === editItem.item.id) };
		} else if (editItem.item.type === 'package') {
			return { type: 'package', item: props.storeData.store_packages.find((p) => p.id === editItem.item.id) };
		}
	}, [editItem?.item, formattedProducts, props.storeData.store_packages]);

	const checkout = (checkoutOptions: CheckoutOptions) => {
		if (!cartItems.products) {
			return;
		}

		const variables = {
			products: JSON.stringify(
				cartItems.products.map((cartItem) => {
					if (cartItem.type === 'package') {
						const { price, image, id, label, type, order_for, ...rest } = cartItem;
						return {
							id,
							label,
							price,
							image,
							type,
							order_for,
							products: rest.products.map(formatProductForServer)
						};
					}
					return formatProductForServer(cartItem);
				})
			),
			name: checkoutOptions.name,
			email: checkoutOptions.email,
			checkoutType: checkoutOptions.paymentType,
			storeId: cartItems.storeId,
			...(checkoutOptions.attributionSelected && { attributeTo: checkoutOptions.attributionSelected })
		};

		createCheckout({
			variables
		});
	};

	const cartTotal = useMemo(() => {
		if (!cartItems.products || cartItems.products.length === 0) {
			return 0;
		}

		return cartItems.products.reduce((prev, cur) => {
			const quantity = cur.type === 'product' ? cur.quantity : 1;
			return prev + (quantity ? quantity : 1) * (cur?.price || 0);
		}, 0);
	}, [cartItems]);

	useEffect(() => {
		if (data?.createCheckout?.error) {
			enqueueSnackbar(data?.createCheckout?.error, {
				variant: 'error'
			});
		} else if (data?.createCheckout?.urlRedirect) {
			window.location.href = data.createCheckout.urlRedirect;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	if (props.storeData.status === 'closed') {
		return <ClosedStore storeName={props.storeData.name} />;
	}
	if (props.storeData.status === 'staging') {
		return <StagingStore storeName={props.storeData.name} />;
	}

	return (
		<div className={classes.container}>
			<div className={classes.header}>
				<div>
					<Typography variant="h2">Your Cart</Typography>
					<Typography className={classes.link} component={Link} variant="body2" to={`/store/${props.storeData.id}`}>
						{props.storeData.name}
					</Typography>
				</div>
				<PrimaryButton buttonSizeOverride="small" component={Link} to={`/store/${props.storeData.id}`}>
					Keep Shopping
				</PrimaryButton>
			</div>

			<div className={classes.orderContainer}>
				<CartItemList
					cartContents={cartItems}
					storeId={props.storeData.id}
					handleLabel={(item, cartKey): void => {
						setLabelItem({
							cartKey,
							item
						});
					}}
					handleEdit={(item, cartKey): void => {
						setEditItem({
							cartKey,
							item
						});
					}}
				/>
				<Checkout
					itemCount={cartItems?.products?.length || 0}
					cartTotal={cartTotal}
					loading={loading}
					handleCheckout={checkout}
					disabled={!cartItems.products || (cartItems.products && cartItems.products.length === 0)}
					attributionList={props.storeData.attribution_list}
				/>
			</div>

			{labelItem?.item && labelItem && (
				<LabelDialog
					storeId={props.storeData.id}
					cartIndexId={labelItem.cartKey}
					open={Boolean(labelItem)}
					handleClose={(): void => {
						setLabelItem(undefined);
					}}
				/>
			)}

			{editProductItem?.item &&
				editItem?.item &&
				editItem.item.type === 'product' &&
				editProductItem.type === 'product' && (
					<ProductDialog
						editProductIndex={editItem.cartKey}
						editProduct={editItem.item}
						storeId={props.storeData.id}
						product={editProductItem.item}
						open={Boolean(editItem)}
						handleClose={(): void => {
							setEditItem(undefined);
						}}
						discountRate={props.discountRate}
						discountType={props.discountType}
					/>
				)}
			{editProductItem?.item && editItem && editItem.item.type === 'package' && editProductItem.type === 'package' && (
				<PackageDialog
					editProductIndex={editItem.cartKey}
					editProduct={editItem.item}
					storeId={props.storeData.id}
					pkg={editProductItem.item}
					open={Boolean(editItem)}
					handleClose={(): void => {
						setEditItem(undefined);
					}}
					discountRate={props.discountRate}
					discountType={props.discountType}
				/>
			)}
		</div>
	);
};

const useStyles = makeStyles()((theme: Theme) => {
	return {
		container: {
			border: `1px solid ${SYSTEM_COLORS.DIVIDER}`,
			borderRadius: 16,
			padding: 25,
			backgroundColor: '#FFF'
		},

		bodyContent: {
			maxHeight: 450,
			overflowY: 'scroll'
		},
		link: {
			color: SYSTEM_COLORS.PRIMARY,
			textDecoration: 'none'
		},
		orderContainer: {
			alignItems: 'flex-start',
			display: 'flex',
			[theme.breakpoints.down('sm')]: {
				display: 'grid',
				gridTemplateColumns: 'repeat(1, 1fr)',
				gap: '10px'
			}
		},

		header: {
			alignItems: 'center',
			paddingBottom: 15,
			marginBottom: 15,
			display: 'flex',
			justifyContent: 'space-between',
			borderBottom: `1px solid ${SYSTEM_COLORS.DIVIDER}`,
			[theme.breakpoints.down('sm')]: {
				display: 'grid',
				gridTemplateColumns: 'repeat(1, 1fr)',
				gap: '10px'
			}
		}
	};
});

export default Cart;
