import { Action } from '../common/action';
import { CartAction, Package, Product } from './action';

export const cartReducerDefaultState: CartModel = {
	storeData: {}
};

export interface CartModel {
	storeData: {
		[storeId: string]: (Product | Package)[];
	};
	currentStoreId?: string;
}

export const CartReducer = (state: CartModel, action: Action<CartAction, any>): CartModel => {
	switch (action.type) {
		case CartAction.ADD_NEW_PRODUCT: {
			return {
				...state,
				storeData: {
					...state.storeData,
					[action.data.storeId]: [...(state.storeData[action.data.storeId] || []), action.data.item]
				}
			};
		}
		case CartAction.REMOVE_PRODUCT_FROM_CART: {
			return {
				...state,
				storeData: {
					...state.storeData,
					[action.data.storeId]: state.storeData[action.data.storeId].filter(
						(product, index) => index !== action.data.productIndex
					)
				}
			};
		}
		case CartAction.EDIT_PRODUCT: {
			return {
				...state,
				storeData: {
					...state.storeData,
					[action.data.storeId]: state.storeData[action.data.storeId].map((product, index) => {
						if (index === action.data.removeIndex) {
							return action.data.item;
						}
						return product;
					})
				}
			};
		}
		case CartAction.ADD_LABEL: {
			return {
				...state,
				storeData: {
					...state.storeData,
					[action.data.storeId]: state.storeData[action.data.storeId].map((product, index) => {
						if (index === action.data.labelIndex) {
							return {
								...product,
								order_for: action.data.orderFor
							};
						}
						return product;
					})
				}
			};
		}
		case CartAction.SET_STORE: {
			return {
				...state,
				currentStoreId: action.data
			};
		}
		case CartAction.CLEAR_STORE_CART: {
			return {
				...state,
				storeData: {
					...state.storeData,
					[action.data]: []
				}
			};
		}
		default:
			return state;
	}
};
