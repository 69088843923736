import './index.css';

import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import { GoogleOAuthProvider } from '@react-oauth/google';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { render } from 'react-dom';
import ReactGA from 'react-ga4';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import App from './app';
import ApolloWrapper from './core/apollo/apolloWrapper';
import theme from './core/config/theme';
import { findEnvAttr } from './core/utilites/process';
import reportWebVitals from './reportWebVitals';

ReactGA.initialize(findEnvAttr('REACT_APP_GA4'));

Sentry.init({
	dsn: 'https://d6c8c3a6a24f4e51a638bdb2f6e14c99@o1038180.ingest.sentry.io/6006393',
	environment: findEnvAttr('REACT_APP_ENVIRONMENT'),
	release: findEnvAttr('REACT_APP_SENTRY_RELEASE'),
	integrations: [new Integrations.BrowserTracing()],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0
});

export const muiCache = createCache({
	key: 'mui',
	prepend: true
});

try {
	render(
		<CacheProvider value={muiCache}>
			<ThemeProvider theme={theme}>
				<GoogleOAuthProvider clientId="908624977861-3js5930deod4mh34cpl0vvpngibop4qj.apps.googleusercontent.com">
					<SnackbarProvider
						maxSnack={3}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'center'
						}}
					>
						<ApolloWrapper>
							<BrowserRouter>
								<Routes>
									<Route path={'/*'} element={<App />} />
								</Routes>
							</BrowserRouter>
						</ApolloWrapper>
					</SnackbarProvider>
				</GoogleOAuthProvider>
			</ThemeProvider>
		</CacheProvider>,
		document.getElementById('root')
	);
} catch (err) {
	console.error(err);
	Sentry.captureException(err);
	throw err;
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
