import { useMemo } from 'react';

import { Product } from '../../../../core/stores/cart/action';
import { handleStoreDiscount } from '../../../../core/utilites/currency';
import { PriceModifier } from '../product/product';

export const getDefaultPriceModifiers = (editProduct?: Product) => {
	if (!editProduct) {
		return {};
	}

	return Object.values(editProduct.options).reduce((prev, cur) => {
		if (cur.priceModifiedAs && cur.priceModifiedAs !== 0) {
			return {
				...prev,
				[`${editProduct!.id}:${cur.availableOptionId}`]: cur.priceModifiedAs
			};
		}
		return prev;
	}, {});
};

interface Options {
	priceModifiers: PriceModifier;
	discountType?: 'percent' | 'dollar';
	discountRate?: number;
	basePrice: number;
}

export const useCalculateTotalPrice = (options: Options) => {
	return useMemo(() => {
		const baselinePrice = handleStoreDiscount({
			discountRate: options.discountRate,
			type: options.discountType,
			incomingValue: options.basePrice
		});

		return Object.values(options.priceModifiers).reduce((cur, next): number => {
			return cur + next;
		}, baselinePrice);
	}, [options]);
};
