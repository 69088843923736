import { CardActionArea, SvgIcon, Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { mdiCreditCardCheckOutline, mdiPlusBox } from '@mdi/js';
import React, { ReactElement, useState } from 'react';

import P3 from '../../components/typography/p3';
import { SYSTEM_COLORS } from '../../core/config/colors';
import DialogCreateLink from './components/dialogCreateLink';
import DialogRecentPurchases from './components/dialogRecentPurchases';
import { useParams } from "react-router-dom";

interface Props {
}

const CustomPaymentLink = (props: Props): ReactElement => {
	const {id} = useParams();
	const { classes } = useStyles();
	const [dialogCreateLink, setDialogCreateLink] = useState(false);
	const [drp, setDialogRecentPurchases] = useState(false);

	if(id) {
		return (
			<>
				<div className={classes.container}>
					<CardActionArea
						className={classes.clickableAction}
						onClick={(): void => {
							setDialogCreateLink(true);
						}}
					>
						<SvgIcon className={classes.icon}>
							<path d={mdiPlusBox} />
						</SvgIcon>
						<Typography variant="h5" className={classes.h5}>
							Create new checkout
						</Typography>
						<P3>Generate a QR Code and Unique Link for making a purchase</P3>
					</CardActionArea>
					<CardActionArea
						className={classes.clickableAction}
						onClick={(): void => {
							setDialogRecentPurchases(true);
						}}
					>
						<SvgIcon className={classes.icon}>
							<path d={mdiCreditCardCheckOutline} />
						</SvgIcon>
						<Typography variant="h5" className={classes.h5}>
							View orders
						</Typography>
						<P3>View all purchases made in a streaming list specific to this account.</P3>
					</CardActionArea>
				</div>
				{dialogCreateLink && (
					<DialogCreateLink
						handleClose={(): void => {
							setDialogCreateLink(false);
						}}
						open={dialogCreateLink}
						productId={id}
					/>
				)}
				{drp && (
					<DialogRecentPurchases
						productId={id}
						handleClose={(): void => {
							setDialogRecentPurchases(false);
						}}
						open={drp}
					/>
				)}
			</>
		);
	}else{
		return <></>
	}
};

const useStyles = makeStyles()((theme: Theme) => {
	return {
		container: {
			marginTop: 50,
			maxWidth: 600,
			marginLeft: 'auto',
			marginRight: 'auto',
			display: 'grid',
			gridTemplateColumns: 'repeat(2, 2fr)',
			gap: '25px',
			[theme.breakpoints.down('sm')]: {
				gap: '15px',
				gridTemplateColumns: 'repeat(1, 1fr)'
			}
		},
		clickableAction: {
			backgroundColor: '#FFF',
			zIndex: 1,
			borderRadius: 8,
			display: 'block',
			textAlign: 'left',
			border: `1px solid ${SYSTEM_COLORS.DIVIDER}`,
			padding: '24px 15px'
		},
		icon: {
			width: 46,
			height: 46,
			marginTop: 10,
			marginBottom: 20,
			color: SYSTEM_COLORS.SECONDARY
		},
		h5: {
			marginBottom: 5
		}
	};
});

export default CustomPaymentLink;
