import { Action } from '../common/action';
import { UIAction } from './action';

export interface UIModel {
	hasCreditCardOption: boolean;
	hasCheckOption: boolean;
}

export const UIDefaultState: UIModel = {
	hasCheckOption: false,
	hasCreditCardOption: true
};

export const UIModelReducer = (state: UIModel, action: Action<UIAction, any>): UIModel => {
	switch (action.type) {
		case UIAction.SET_CHECK_OPTION: {
			return {
				...state,
				hasCheckOption: action.data
			};
		}
		case UIAction.SET_CREDIT_CARD_OPTION: {
			return {
				...state,
				hasCreditCardOption: action.data
			};
		}
		default:
			return state;
	}
};
